import React, { useEffect } from "react";
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { useContext, useDispatch } from "../../context";

export const InfoModal = () => {
  const { infoModal } = useContext();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const closeModal = () => {
    if (infoModal.openInfoModal) {
      dispatch({
        type: "setInfoModal",
        infoModal: {
          openInfoModal: false,
          title: "",
          message: "",
          icon: null,
          hasIcon: true,
        },
      });
    }
  };

  return (
    <MDBModal
      tabIndex="-1"
      show={infoModal.openInfoModal}
      staticBackdrop={true}
    >
      <div className="box-shadow" style={{ transform: "translateY(50%)" }}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="font-retro">
                {infoModal.title}
              </MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={closeModal} />
            </MDBModalHeader>
            <MDBModalBody className="px-5">
              <div className="d-flex align-items-center flex-column py-4">
                <div style={{ fontSize: "30px" }}>
                  {infoModal.hasIcon ? (
                    <MDBIcon
                      className="scale-animate"
                      style={{ color: "pink", cursor: "pointer" }}
                      icon={infoModal.icon}
                      size="lg"
                    />
                  ) : (
                    infoModal.icon
                  )}
                </div>
                <span className="text-center">{infoModal.message}</span>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <div className="button-shadow-secondary">
                <MDBBtn color="secondary" onClick={closeModal}>
                  Close
                </MDBBtn>
              </div>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </div>
    </MDBModal>
  );
};
