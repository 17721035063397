import React, { useState } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import thePray from "../../../assets/storyCrossRoads/thePray.png";

export const ThePlanI = () => {
  const isMobile = useDisplaySize();
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <div>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Plan
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            The next day, Hotshot laid out the plan for this crew: The Night
            Riders were going to be the wheel-men for Ratface’s crew while they
            robbed the Mice City Cheddar National Bank.
          </b>
          <br />
          <br />
          Their target wasn’t the cash, it was two priceless paintings worth
          hundreds of millions of cheddars, held in the Bank Vault.
          <br />
          This was going to be the heist of the century!
          <br />
          <br />
          On paper it all seemed simple and absolutely insane at the same time.
          At night the art was stored inside the vault – practically
          unreachable. During the day it was displayed in the lobby for all to
          see, but under massive surveillance.
          {showMore && (
            <span>
              <div className="faction-shadow">
                <div
                  className={`m-0 ${isMobile ? "py-3" : "py-3"}`}
                  style={{
                    maxWidth: isMobile ? "100%" : "100%",
                    float: isMobile ? "none" : "right",
                  }}
                >
                  <img className="image-full-size" src={thePray} alt="" />
                </div>
              </div>
              Therefore, the “safest” play was to do the heist in broad daylight
              and in front of everyone, since at night the security was
              impenetrable.
              <br />
              <br />
              This would be an 8 mouse job: 2 cars, 2 drivers – Hotshot & Johnny
              – and 6 ruthless rats from Ratface’s crew doing the dirty work in
              the Bank.
              <br />
              <br />
              That’s where the racing skills come in. As soon as they got out
              with the prize, the heat would be unthinkable, so they needed a
              stellar crew of drivers to lose the cops and get away scot-free.
              <br />
              <br />
              <b>
                So now it was time for Johnny’s “graduation” day and he felt the
                pressure. Should he inform Axel about the plan and Ratface’s
                direct involvement? Or should he try to go with the flow and see
                if he can buy some good faith with the Syndicate’s leadership?
                <br />
                <br />
                All he knew was that resistance would be higher than usual, and
                his participation in this insane endeavor would be hard to
                explain to his handlers from The Agency, especially Mousley.
              </b>
              <br />
              <br />
            </span>
          )}
          <div
            className="vote-button m-auto my-4"
            style={{ width: "max-content" }}
            onClick={() => setShowMore(!showMore)}
          >
            <span>{showMore ? "See less" : "Show more"}</span>
          </div>
        </span>
      </div>
    </div>
  );
};
