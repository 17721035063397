import React from "react";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";
import clubbing1 from "../../../../assets/images/factions/Clubbing and Fashion/ch1.webp";

export const ClubbingChapter1 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.1 - Mice City Nightlife
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          What’s the first thing that comes to mind when thinking about Mice
          City?
          <br />
          <br />
          Depending on who you ask you would get different answers: insane
          nightlife and parties, lights, glamor, drugs, crazy discos, sprawling
          resorts, the hottest pop music, exotic cars, fast bikes, fashion shows
          and a bit of danger.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3 d-inline"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={clubbing1} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          But there are a few places in downtown Mice City where all these
          things come together, and that’s the Clubbing and Fashion district – a
          true melting pot of mice from all walks of life.
          <br />
          <br />
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={clubbing1} alt="" />
                <br />
                <br />
              </div>
            </div>
          )}
          From the filthy rich Corpos wanting to feel like the coolest cats in
          town to the wannabe racers looking to score some street cred with the
          Night Riders, they all show up here at night and mingle in the raddest
          discos to hit 80s.
          <br />
          <br />
          As soon as you enter Neon Drive, the most popular boulevard in Mice
          City where you find most of the clubs and discos, you’re hit by the
          pure insanity of the whole scene. There are impromptu races, huge
          outdoor parties blasting the most far out 80s tunes, and huge crowds,
          all drenched in the colorful neon lights burning brighter than
          daylight.
          <br />
          <br />
          Go even further along the Drive and you’ll find hundreds of exotic
          cars owned by the filthy rich Corpos and Crime Syndicate members
          showing off their cheddar or even some of the famed Night Riders’ cars
          taking a break from tearing up the asphalt - a full on auto-show.
          <br />
          <br />
        </span>
      </div>
      <span></span>
    </div>
  );
};
