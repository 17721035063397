import { useState } from "react";

export function useMintHook(tokens: number, amountLeft: number) {
  const [buyAmount, setBuyAmount] = useState(1);
  const [token, setToken] = useState(0);

  const incrementAmount = () => {
    if (buyAmount < amountLeft) {
      setBuyAmount(buyAmount + 1);
    }
  };

  const decrementAmount = () => {
    if (buyAmount > 1) {
      setBuyAmount(buyAmount - 1);
    }
  };

  const incrementToken = () => {
    if (token + 1 < tokens) {
      setToken(token + 1);
    } else {
      setToken(0);
    }
  };

  const decrementToken = () => {
    if (token - 1 >= 0) {
      setToken(token - 1);
    } else {
      setToken(tokens - 1);
    }
  };

  return {
    token,
    buyAmount,
    incrementAmount,
    decrementAmount,
    incrementToken,
    decrementToken,
  };
}
