import React, { FC, useEffect, useRef, useState } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import newsPaper from "../../../assets/storyCrossRoads/newspaper.png";
import "../../../pages/crossStories/CrossStories.scss";
import { useDispatch } from "../../../context";
import axios from "axios";
import { referer } from "../../../config.devnet";
import { useGetAccountInfo, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { getNetworkConfig } from "@multiversx/sdk-dapp/utils";

const END_DATE = 1650747600000;

type VoteType = {
  voteOption: string;
  didVote: boolean;
  hasMice: boolean;
  loadingVoteInfo: boolean;
  setVoted: any;
};

enum Options {
  "A" = "A",
  "B" = "B",
  "C" = "C",
}

export const TheVoteI: FC<VoteType> = ({
  hasMice,
  didVote,
  setVoted,
  loadingVoteInfo,
  voteOption,
}) => {
  const isMobile = useDisplaySize();
  const [selected, setSelected] = useState(Options.A);
  const [isVoting, setIsVoting] = useState(false);
  const [animate, setAnimate] = useState(false);
  const dispatch = useDispatch();
  const { address } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const { apiAddress } = getNetworkConfig();
  const newspaperRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (newspaperRef.current) {
        if (
          newspaperRef.current.getBoundingClientRect().top <
          window.innerHeight - 100
        ) {
          setAnimate(true);
        } else {
          setAnimate(false);
        }
      }
    });
  }, []);

  console.log(voteOption);

  useEffect(() => {
    setSelected(voteOption as Options);
  }, [voteOption]);

  const vote = async () => {
    let voteResponse;
    try {
      setIsVoting(true);
      const local_token = localStorage.getItem(`login.${address}`);
      if (local_token) {
        const jsonToken = JSON.parse(local_token);
        voteResponse = await axios.post(
          `${apiAddress}/micecity/voteStory/`,
          {
            title: "The Great Art Heist",
            voteOption: selected,
            timestamp: Date.now(),
          },
          {
            headers: {
              Authorization: `Bearer ${jsonToken?.access_token}`,
              Referer: referer,
            },
          }
        );
      } else {
        openErrorModal();
      }
    } catch (e: any) {
      openErrorModal();
    }
    if (voteResponse && voteResponse.status === 201) {
      dispatch({
        type: "setInfoModal",
        infoModal: {
          openInfoModal: true,
          title: "Success",
          message: "Thank you for contributing to our story!",
          icon: "🐭🐭🐭",
          hasIcon: false,
        },
      });
      setVoted(true);
    } else {
      openErrorModal();
    }
    setIsVoting(false);
  };

  const openErrorModal = () => {
    dispatch({
      type: "setInfoModal",
      infoModal: {
        openInfoModal: true,
        title: "Error",
        message: "Something went wrong. Please try again!",
        icon: "⚠️",
        hasIcon: false,
      },
    });
  };

  const onVote = async () => {
    if (!isLoggedIn) {
      dispatch({
        type: "setGlobalModal",
        globalModal: { openGlobalModal: true },
      });
      return;
    }
    if (!hasMice) {
      dispatch({
        type: "setInfoModal",
        infoModal: {
          openInfoModal: true,
          title: "Mint required",
          message: (
            <span>
              You don&#39;t have mice in your wallet. Mint some from{" "}
              <a href="/">our home page</a>
            </span>
          ),
          icon: "🐭🐭🐭",
          hasIcon: false,
        },
      });
      return;
    }
    if (!didVote) {
      await vote();
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Vote
      </span>
      <div
        ref={newspaperRef}
        className={`${isMobile ? "w-100" : "w-75"} ${
          animate ? "animate-news" : ""
        } ${isMobile ? "d-flex justify-content-center" : ""}`}
        style={{
          marginBottom: "4rem",
          transform: animate ? "rotate(10deg) scale(1)" : "scale(0.2)",
        }}
      >
        <img className="image-full-size" src={newsPaper} alt="" />
      </div>
      {showMore && (
        <div className="d-flex align-items-center flex-column pb-4">
          <div
            className={`d-flex align-items-center pb-4 ${
              isMobile ? "flex-column" : ""
            }`}
            style={{ height: !isMobile ? "300px" : "" }}
          >
            <div
              className={`vote-box h-100 ${
                selected === Options.A ? "pressed" : ""
              } ${isMobile ? "mb-4" : "me-4"}`}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.A)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                A.
              </span>
              <span>
                Our on-site reporters confirm that all 8 robbers have escaped
                following a heated gunfire exchange. They are now engaged in a
                high speed pursuit with Mice City law enforcement. (Johnny &
                Hotshot escape the scene and try to reach Ratface’s safehouse,
                putting Johnny’s loyalty to The Agency into question)
              </span>
            </div>
            <div
              className={`vote-box h-100 ${
                selected === Options.B ? "pressed" : ""
              } `}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.B)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                B.
              </span>
              <span>
                Following a brutal gun battle, The Agency’s operatives have
                killed the 6 gunmen. In an unexpected turn of events, one of the
                drivers appears to have switched sides and is trying to stop his
                colleague, now fleeing the scene. (Johnny H informs Axel Mousley
                about the heist plan. The Syndicate’s mice are gunned down by
                the agents. Hotshot tries to escape the scene with Johnny H hot
                on his tail)
              </span>
            </div>
          </div>
          <div
            className={`vote-box w-100 ${
              selected === Options.C ? "pressed" : ""
            } `}
            style={{ pointerEvents: didVote ? "none" : "auto" }}
            onClick={() => setSelected(Options.C)}
          >
            <span
              className="pb-3 d-block"
              style={{ fontSize: "20px", fontWeight: 600 }}
            >
              C.
            </span>
            <span>
              Authorities have confirmed that the gunmen were subdued, with the
              help of a brave undercover agent. Casualties reported on both
              sides. Among those captured are 4 members of the Crime Syndicate.
              (Johnny H compromised, Syndicate members captured by Axel Mousley)
            </span>
          </div>
        </div>
      )}
      <div
        className="vote-button m-auto my-4"
        style={{ width: "max-content" }}
        onClick={() => setShowMore(!showMore)}
      >
        <span>{showMore ? "Hide options" : "Show options"}</span>
      </div>
      {!didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          <span>
            THE “MICE GUARDIAN” NEWSPAPER WILL CONTINUE TO REPORT ON THIS STORY
            AS MORE UPDATES COME IN!
          </span>
        </div>
      )}
      {END_DATE > Date.now() && (
        <div
          className={`vote-button mb-4 ${didVote ? "disabled" : ""}`}
          style={{ pointerEvents: loadingVoteInfo ? "none" : "auto" }}
          onClick={onVote}
        >
          {isVoting || loadingVoteInfo ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <span>
              {isLoggedIn ? (didVote ? "Voted" : "Vote") : "Login to vote"}
            </span>
          )}
        </div>
      )}
      {didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          <span>
            THE “MICE GUARDIAN” NEWSPAPER WILL CONTINUE TO REPORT ON THIS STORY
            AS MORE UPDATES COME IN!
          </span>
        </div>
      )}
      <span
        className="d-block h4 text-center"
        style={{ fontWeight: "bolder", color: "#ED9AC8" }}
      >
        You have decided!
        <br /> And the winner is 🥁🥁🥁: Option A!
      </span>
    </div>
  );
};
