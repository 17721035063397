import { SmartContractAbis } from "./SmartContractAbis";
import {
  getSmartContract,
  getSmartContractResult,
} from "./SamrtContractService";
import { Address } from "@elrondnetwork/erdjs/out";
import { network, referer } from "../config.devnet";
import axios from "axios";

const minterAbiName = "Minter";

// export const getCreatorByTag = async (tag: string) => {
//   const managerAbi = await SmartContractAbis.getManager();
//   const manager = await getSmartContract(
//     managerAbi,
//     new Address(PreSaleManager),
//     managerAbiName
//   );
//   const interactionGetUser = manager.methods.getCreatorByTag([
//     BytesValue.fromUTF8(tag),
//   ]);
//   return (
//     await getSmartContractResult(interactionGetUser)
//   ).firstValue.valueOf();
// };

export const getCreatorByTag = async (tag: string) => {
  if (tag !== undefined) {
    const { data } = await axios.get(
      `${network.apiAddress}/getCreatorByTag/${tag}`,
      {
        headers: {
          Referer: referer,
        },
      }
    );
    return data;
  }
  return undefined;
};

export const getListingsFromSC = async (address: Address) => {
  const minterAbi = await SmartContractAbis.getMinter();
  console.log(address);
  const manager = await getSmartContract(minterAbi, address, minterAbiName);
  const interaction = manager.methods.getListings();
  const queryResult = await getSmartContractResult(interaction);
  if (queryResult) {
    return queryResult.firstValue.valueOf();
  }
  return undefined;
};

// export const buysPerWallet = async (
//   tag: string,
//   address: Address,
//   userSC: Address
// ) => {
//   const minterAbi = await SmartContractAbis.getMinter();
//   const manager = await getSmartContract(minterAbi, address, minterAbiName);
//   const interaction = manager.methods.buysPerWallet([
//     new AddressValue(userSC),
//     BytesValue.fromUTF8(tag),
//   ]);
//   const queryResult = await getSmartContractResult(interaction);
//   if (queryResult) {
//     return queryResult.firstValue.valueOf().toNumber();
//   }
//
//   return undefined;
// };

export const buysPerWallet = async (
  tag: string,
  address: string,
  userSC: string
) => {
  const { data } = await axios.get(
    `${network.apiAddress}/buysPerWallet/${tag}/${address}/${userSC}`,
    {
      headers: {
        Referer: referer,
      },
    }
  );
  return data;
};
