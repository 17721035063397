import React, { FC } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import crime1 from "../../../assets/images/factions/Crime/CH1.webp";
import corpos1 from "../../../assets/images/factions/Agency/ch2 _.webp";
import agency from "../../../assets/images/factions/Agency/CH1.webp";

type TheOutcomeType = {
  ref: any;
};

export const TheOutcomeIV: FC<TheOutcomeType> = ({ ref }) => {
  const isMobile = useDisplaySize();

  return (
    <div ref={ref}>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Outcome
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            The plan was already underway, even though Ratface’s men all tried
            to talk him out of it. Exposing himself so thoroughly in the very
            epicenter of Corpo power which was the Maustrap Tower felt like a
            death sentence.
          </b>
          <br />
          <br />
          But Ratface had it all planned out. No one would see him or his guards
          until they entered Rekko’s office. In his mind the risk was worth it.
          It was the only way to have a face to face with the Corpo leader at
          this stage. Tensions were too high, and the only feasible actions left
          involved all-out-war, something he fought all his life to avoid.
          <br />
          Peace between the leading families of Mice City was, after all, the
          very reason for creating the Crime Syndicate.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline ps-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={crime1} alt="" />
            </div>
          </div>
          But getting to Rekko didn’t come easy. While Johnny H and George
          Younger left to have their very own meeting with Senator Mooney, he
          would rely on one of his oldest supporters in the early days of the
          Syndicate – none other than the former Yakuza leader, Rakagashira.
          <br />
          <br />
          The Yakuza may have been swallowed up by the Syndicate, but their
          business connections stayed strong. It was after all one of the
          organization’s traditions to work and control white collar businesses
          from the inside.
          <br />
          And with Maustrap Inc. being the biggest business conglomerate in Mice
          City, it was obvious that Rakagashira (now acting as a consultant to
          the Syndicate) had mice inside the Corpo world. They would be the key
          to Ratface and Rekko’s impromptu sit-down.
          <br />
          <br />
          Their infiltration would be fairly straight forward: Rakagashira’s
          double agents inside the building would provide Ratface with a
          security clearance, so he and a few of Rakagashira’s mice could reach
          the top floors as surveillance tech maintenance staff.
          <br />
          The Yakuza crew fried the smart-tracking systems on the managerial
          floors, so the Corpos had no choice but to call in a maintenance crew.
          Most of the tech staff was, of course, comprised of Rakagashira’s
          mice. So, it was mostly business as usual, with the exception of one
          new team member: the rookie Manny Montana.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline pe-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={corpos1} alt="" />
            </div>
          </div>
          And just like that, one week after the attack carried out by Rekko
          against the Syndicate’s main safehouses, Ratface went ahead with the
          Maustrap HQ infiltration. All of his counselors advised against this
          move, warning him of the dangers of waking straight in the office of
          the mouse gunning for him. “Why don’t you just put a gun to your own
          head and make Rekko’s job easier” they all said, but Ratface trusted
          one thing about Rekko – his greed & ego fueling his fear to lose his
          grip over Mice City. And with an open conflict between their 2
          factions that’s exactly what was on the table… for both of them.
          <br />
          So, he was confident they could at least agree to speak.
          <br />
          <br />
          The plan went like clockwork: they entered the building and the
          concierge immediately sent them to the server room on the secure top
          floors.
          <br />
          No Corpo even looked twice at the ordinary tech team doing their jobs.
          If they had, they would notice the leader of the most powerful
          criminal organization wandering around their very headquarters.
          <br />
          <br />
          Just like that they were on the top floor, where Rekko’s office was
          located. The Yakuza mice spread out around the floor and let Ratface
          know when the coast was clear for him to head to the office undetected
          by anyone.
          <br />
          <br />
          He entered the room, and as expected Rekko didn’t even look up from
          his desk, consumed by his own obsessions:
          <br />
          <p className="dialog-font m-0 pt-1">
            Rekko: I told you to handle the maintenance team yourselves, I don’t
            have time for your moronic avoidable problems.
          </p>
          <p className="dialog-font m-0 pt-1">
            Ratface: I’m afraid we’ve got bigger things to discuss than your
            lackluster tech maintenance, old friend…
          </p>
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline ps-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={agency} alt="" />
            </div>
          </div>
          Rekko looked up, stupefied by what he was seeing, and after a few
          moments of silence, he let out a chuckle:
          <p className="dialog-font m-0 pt-1">
            &nbsp;&nbsp;&nbsp;&nbsp;- Hah! I knew you would have some insane way
            of retaliating against me. I have to hand it to you, this is bold,
            even for the infamous leader of the Syndicate.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Let me guess what the plan is: take me out
            in my own office, make an example out of me so no one ever gets out
            of line again.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- But I should warn you, old friend, I’m not
            that simple executive you met all those years ago… Half this city
            bends to the Corpo will. Without me, it all comes crumbling down.
          </p>
          <p className="dialog-font m-0 pt-1 pb-1">
            Ratface: Sorry to disappoint you, but my plan doesn’t involve
            anything so ruthless. I guess I’m not the same ruthless mouse YOU
            met all those years ago either.
            <br />
            I came here so we can reach an agreement. I agree, this city would
            plunge into chaos without one of us steering the “ships”. As much as
            The Agency likes to shout, we’re a necessary evil. An evil keeping
            this town prosperous.
            <br />
            And I fear your latest actions were started because of a
            misunderstanding.
          </p>
          <br />
          <p className="dialog-font m-0 pt-1">
            Rekko: Misunderstanding??? You hit my bank, stole my property and
            killed my mice!
          </p>
          <p className="dialog-font m-0 pt-1">
            Ratface: Yes… I did, and yes, my rats were a bit overzealous in
            their escape which resulted in casualties on all sides, but my
            intention wasn’t to weaken the Corpos or to send a message. I needed
            those art pieces so I can secure my very own foothold at the
            senate’s table.
            <br />
            The Syndicate needs direct political backing. We can’t rely only on
            your connections. I think this incident shows us how unstable that
            can be.
            <br />
            I’m afraid this term isn’t negotiable… we keep your services in the
            mayoral house, but we’ll also have our own direct contacts.
          </p>
          <p className="dialog-font m-0 pt-1 pb-1">
            As soon as Rekko hung up the call, Ratface’s enforcers started
            shouting on their radios: - Sir, there’s movement around every
            house! They’re spec ops sir!
          </p>
          <br />
          He may have appeared peaceful at first, but finally Rekko started to
          see the real Ratface between these statements. He could smell a threat
          from a mile away. And the most frustrating thing was that Ratface was
          right, he couldn’t escalate the conflict further and he couldn’t
          refuse either. His attack on the Syndicate gave Ratface all he needed
          to impose this condition. Ruthless and cunning, just as he remembered
          him.
          <br />
          <br />
          <p className="dialog-font m-0">
            Rekko: I’d like to tell you to go to hell, but we both know I’m a
            pragmatic mouse. And you’re right, we need each other (at least for
            now, he thought). But you’ll find it more difficult than you expect
            to get senate backing.
          </p>
          <p className="dialog-font m-0">
            Ratface: Oh, I know, but don’t worry about me old friend, that deal
            is already in motion. In fact we should be getting a call any moment
            now from my star Syndicate member – Johnny.
          </p>
          <br />
          As soon as he heard Johnny’s name, Rekko smirked, knowing he still had
          the upper-hand with knowledge about this Agency double agent. He only
          had to make sure to use it at the opportune time.
        </span>
      </div>
      <div
        className="d-block h4 pt-4"
        style={{ fontWeight: 500, color: "#F6B0E1" }}
      >
        TO BE CONTINUED in future Story Crossroads events
      </div>
    </div>
  );
};
