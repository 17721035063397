import React from "react";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";
import riders1 from "../../../../assets/images/factions/Night Riders/CH4.webp";
import riders2 from "../../../../assets/images/factions/Night Riders/ch2 & ch4.webp";

export const NightRidersChapter4 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.4 - Undercover in the Underground
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          The Agency’s new undercover division has already started sending
          agents to take part in some of the races. Their new captain chose some
          of the best gearheads the Agency has to offer. And they can really
          hold their own, even going so far as to win a few entry level races.
          <br />
          <br />
          That may not be much, but it was enough to attract attention from some
          of the small-time crews racing with the Night Rider society. Wannabe
          racers, looking for all the new talent they can get so they can
          impress the Night Rider OGs and the legend - “Hotshot” Locatelli.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3 d-inline"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={riders2} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          So far no one seemed to notice these new undercover agents, treating
          them as one of their own each time the NRS meetings were held,
          bringing them even deeper into the organization.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={riders2} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          But there was one who saw right through their bullshit - Hotshot
          himself. Locatelli spent his entire life around engines, cars and most
          importantly racing drivers. So he had a knack for spotting the true
          racing drivers who only care about hitting the perfect apex and 8000
          RPM, the wannabe racers who just want to race their latest supercar to
          show off their cheddar and, of course, the competent mice holding
          their own on the asphalt but who clearly don’t have that fire in their
          eyes of a true racing driver.
          <br />
          <br />
          And that’s how he saw all these obvious agents, good drivers, but no
          passion. All except one… the mouse everyone called Johnny H.
          <br />
          <br />
          Hotshot started noticing that Johnny would start taking on more and
          more races, pushing the car to its limit every time. He didn’t need to
          do that, after all, he was already in.
          <br />
          <br />
          He saw a small flame of determination in Johnny’s eyes each time he
          would line up his matte black muscle car to start each race. Something
          only a pure driver would notice.
          <br />
          <br />
          Mickey knew he should be letting his family know about these agents,
          but something was keeping Hotshot from informing the Crime Syndicate.
          <br />
          <br />
          It was the same feeling which kept him away from his relatives in the
          Locatelli crime family for all these years: he had no love for the
          criminal lifestyle, the “old ways” as his family put it. Least of all
          for this new influx of “followers” from the shady mice who found
          themselves swimming in illegal cheddar overnight, with none of the
          ethics of the Syndicate’s 4 founding families.
          <br />
          <br />
          The way he saw it, these mice were tainting the pure freedom of racing
          through the hills of Mice City..
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3 pe-3 d-inline"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <img className="image-full-size" src={riders1} alt="" />
              </div>
            </div>
          )}
          So he decided to approach this Johnny guy. He wanted to see if the
          small flame of racing passion he saw was genuine, if they were cut
          from the same cloth.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3 pe-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={riders1} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          f they were, maybe this agent would be the way to cut out some of
          these disgraceful mice calling themselves racers and members of the
          Night Riders Society. Or it could be his foolish way of bringing down
          the Night Rider Society he had built in Mice City, along with the
          Crime Syndicate.
          <br />
          <br />
          But even with all these risks, he decided to go with his gut. Mickey
          suspected that Johnny started seeing more to the Night Riders’s
          lifestyle than just illegal racers in bed with the Crime Syndicate.
          <br />
          <br />
          Johnny started seeing that the Night Riders were in fact a community,
          and that under Mickey Hotshot Locatelli’s leadership they were slowly
          turning young and ambitious mice away from the Crime Syndicate and
          giving them a new purpose, a new freedom where they were in control –
          the open road.
          <br />
          <br />
          Sure they were still breaking the law, but they were not like the
          ruthless followers of Ratface Montana.
          <br />
          <br />
          So Mickey wanted to see how far Johnny H could climb the ranks on the
          NRS, letting him fall more and more in love with the Night Riders
          community before approaching. This would increase the chances of
          Hotshot convincing Johnny to go for his deal.
          <br />
          <br />
          But it would also mean The Agency getting deeper behind the curtains
          of the Night Riders society, but it was a risk Mickey was willing to
          take.
          <br />
          <br />
          Will Hotshot Locatelli’s gamble be how the Crime Syndicate finds its
          end? And will it in turn spell the end for the unchallenged freedom of
          the Night Riders?
          <br />
          <br />
          Only time will tell. First things first though... Hotshot and Johnny
          had some races to attend.
          <br />
          <br />
        </span>
      </div>
      <span></span>
    </div>
  );
};
