import React, { FC } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import corpos1 from "../../../assets/images/factions/Corpo/ch3.webp";
import clubbing1 from "../../../assets/images/factions/Clubbing and Fashion/ch3....webp";
import clubbing2 from "../../../assets/images/factions/Clubbing and Fashion/ch3...webp";
import clubbing3 from "../../../assets/images/factions/Clubbing and Fashion/ch3..webp";

type TheOutcomeType = {
  ref: any;
};

export const TheOutcomeII: FC<TheOutcomeType> = ({ ref }) => {
  const isMobile = useDisplaySize();
  return (
    <div ref={ref}>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Outcome
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            Winston barely contained his anger. He just kept staring at Rekko
            while he calmly lit his cigar. His purple trench coat with that fur
            collar, all surrounded by the cigar smoke made him look like a comic
            book villain. But that was probably exactly what he wanted, Winston
            thought. Winnie, being the more composed of the two twins, tried to
            appear unphased:
          </b>
          <br />
          <br />
          <p className="dialog-font m-0">
            W: - Mr. Rekko, while we appreciate your interest in our venue, you
            have to understand we can’t accept your offer… giving you 50%
            control of the Neon Drive venues would basically mean handing you
            the reins of our operation.
          </p>
          <br />
          <p className="dialog-font m-0">
            R: - My dear, you’re looking at this the wrong way. I give funding
            for every event, real estate project or expansion you’ll ever want.
            I’ll also give you 70% of the profits.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Now you tell me how that’s not in your
            best interest? And let’s not forget The Burning Mouse is costing you
            more than you anticipated, and this is only the first day of the
            festival.
          </p>
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline ps-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={corpos1} alt="" />
            </div>
          </div>
          Winston couldn’t hold his tongue anymore:
          <br />
          <p className="dialog-font m-0">
            W: - You low life scum! I’m tired of listening to your fake
            pleasantries. We’re not exactly the airheads you take us for… We
            know exactly what you’re doing.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- The reason our costs are up is because
            you’re buying out our suppliers, forcing us to pay more. The reason
            you’re offering 70% of the profits to us is because your 50%
            ownership of The Fashionistas would let you decide what we build,
            who we hire and what we buy. I know very well you’ll be using your
            own shadow companies to fill your own pockets and bleed us dry.
          </p>
          <br />
          <p className="dialog-font m-0">
            R: - Smart little mice you are, Rekko said with a grin on his face.
            - This still doesn’t change your current situation, so accept it or
            lose it all with this festival. <br />
            W: - We’ll take our chances, now get the fuck out of our festival!
          </p>
          <br />
          Rekko was actually impressed with the Twins, they saw right through
          him. But he still had an ace up his sleeve. So, he puffed his cigar
          and looked at them with his signature grin.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline pe-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={clubbing1} alt="" />
            </div>
          </div>
          <p className="dialog-font m-0">
            R: - Before I go, tell me, what will the other factions think about
            your new “brothers”, those wild Flyboys?
            <br />
            W: - What the hell are you talking about, Rekko?
            <br />
            R: - My little eyes inside the Agency told me about their very
            interesting past and about their agreement with Axel Mousley’s unit.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- So, let’s see how long you can keep your
            reputation once the word is out that the outcasts of the Neon Drive
            aren’t as impartial as everyone thinks. But don’t worry, I’ll be
            there to buy you out once you’ll both be cast aside by your very own
            family of party outcasts.
            <br />
            W: - You’re delusional if you think a simple rumor will be enough to
            destroy us. But don’t expect us to forget your threats.
            <br />
            R: - We’ll see… Until then, I could use a fresh pair of eyes inside
            the Agency, and I’m sure your Flyboys won’t be as hostile to my
            help. Now, if you’ll excuse me, I have an empire to take over.
          </p>
          <br />
          Rekko calmly got up and called his Corpos to wait for the Flyboys
          after they finished their show for today. He was confident he could
          still get the upper hand but his anger at the Twins’ rejection was
          visible on his face.
          <br />
          <br />
          The jets were already putting on a show above the festival, which
          meant the show was almost done for the day. The lights & the music
          from the other stages took over the festival and the party went on as
          usual. Once the Flyboys were getting off the stage, a limo and a crew
          of Corpos was waiting for them, just as Rekko arranged.
          <br />
          <br />
          On the other side of the festival, Winnie & Winston were frantically
          trying to call Diego to get ahead of this mess. Their best play was to
          get the Flyboys out of the spotlight and try to use their connections
          within the Agency to get Rekko to back off.
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline ps-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={clubbing2} alt="" />
            </div>
          </div>
          <br />
          After hours of searching in vain, The Twins & Diego returned to their
          main office on the Neon Drive and to their surprise, The Flyboys were
          all there waiting for them. They were still dressed in their flight
          gear covered in their trademark stickers and fluorescent paint, with a
          grim look on their face.
          <br />
          <br />
          They told the Twins that Rekko had approached them. He knew all about
          their past in the military, their deal with the Agency to keep them
          out of prison.
          <br />
          Rekko offered to get rid of any charges against them using his corrupt
          Corpo affiliates in the government’s inner circle. The only condition
          was that they told him exactly what Mousley’s Task Force was planning
          at every step.
          <br />
          If they refused, he would use the same corrupt mice to get them court
          martialed, regardless of the deal they had in place with The Agency.
          <br />
          But The Flyboys weren’t about to sell out their friends in the
          Clubbing & Fashion scene so easily. So, they reached a compromise with
          Rekko: They agreed to work for him inside the Agency as long as he
          agreed to not go after The Fashionistas’ reputation.
          <br />
          <br />
          Rekko reluctantly agreed, as he could still bleed their funds dry if
          he was patient enough and buy them out when the time was right.
          Furthermore, he would finally get direct access inside Mousley’s
          operation.
          <br />
          <br />
          After hearing them out, Diego was somewhat relieved: - I know this
          isn’t the raddest situation, but at least we’re won’t have to deal
          with those gnarly Corpos anymore
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline pe-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={clubbing3} alt="" />
            </div>
          </div>
          But Winston wasn’t as content:
          <p className="dialog-font m-o">
            {" "}
            &nbsp;- NO, he doesn’t get to do this! And make no mistake, that
            wannabe kingpin will still choke our supplies in time. All he needs
            is enough money and patience, which he has… big time!
          </p>
          Winnie, as usual, was the calmest of all of them. She turned to
          Winston with a cheeky smile and said:
          <br />
          <p className="dialog-font m-o">
            - Fine, he wants to have it all, then let’s give it to him. We’ll go
            on with our business as usual, while the Flyboys feed him
            information, but let’s make sure he hears something that helps us
            too - she said while sipping from a glass of gin
          </p>
          One of the Flyboys was confused:
          <br />
          <p className="dialog-font m-o">
            - What do you mean, what information?
          </p>
          Winnie looked at him while taking another sip from her glass:
          <br />
          <p className="dialog-font m-o">
            - Tell Mr. Rekko who it was that arranged last month’s Great Art
            Heist. Let’s see if he’ll still be interested in us once he hears
            that his so-called ally, Ratface, was behind the robbery at his own
            bank.
          </p>
          The Flyboys couldn’t believe it:
          <br />
          <p className="dialog-font m-o">
            - How the hell do you know that?? No one outside the Task-Force has
            any idea about this.
          </p>
          Winston was finally starting to relax and poured himself a glass of
          whiskey:
          <br />
          <p className="dialog-font m-0">
            - Please boys, you don’t think anything happens in the art & fashion
            scene in this town without us knowing about it…
            <br />- Good thinking sis. Now let’s motivate our lovely Mr. Rekko
            to focus his attention on his own backyard.
          </p>
        </span>
      </div>
      <div
        className="d-block h4 pt-4"
        style={{ fontWeight: 500, color: "#F6B0E1" }}
      >
        TO BE CONTINUED in future Story Crossroads events
      </div>
    </div>
  );
};
