import React, { useEffect, useRef } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import "./TeamBanner.scss";
import kosy from "../../../assets/images/team/kosy.webp";
import tyrion from "../../../assets/images/team/SquishyTyrion.webp";
import mla from "../../../assets/images/team/MLA.webp";
import dalv from "../../../assets/images/team/Dalv.webp";

export const TeamBanner = (props: any) => {
  const isMobile = useDisplaySize();
  const teamRef = useRef<HTMLDivElement>(null);
  const team = [
    {
      name: "kosy",
      title: "The Artist",
      icon: kosy,
      shadowClass: "kosy-shadow",
      containerClass: "kosy-container",
    },
    {
      name: "MLA",
      title: "Hacker Mouse",
      icon: mla,
      shadowClass: "mla-shadow",
      containerClass: "mla-container",
    },
    {
      name: "Tyrion",
      title: "The Director",
      icon: tyrion,
      shadowClass: "tyrion-shadow",
      containerClass: "tyrion-container",
    },
    {
      name: "Dalv",
      title: "Hacker Mouse",
      icon: dalv,
      shadowClass: "dalv-shadow",
      containerClass: "dalv-container",
    },
  ];

  useEffect(() => {
    if (teamRef.current && props.scroll) {
      teamRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [props.scroll]);

  return (
    <div ref={teamRef}>
      <div className="box-shadow w-100 mx-0" style={{ marginTop: "2.5rem" }}>
        <div className="team-container mt-2 mt-0 flex-column px-2">
          <div
            className={`title-box-shadow mx-0 ${
              isMobile ? "text-center" : "ps-5"
            }`}
          >
            <div className="title-container d-inline">
              <span>The Mice Team</span>
            </div>
          </div>
          <div
            className={`d-flex justify-content-around align-items-center pt-5 ${
              isMobile ? "flex-column" : "px-5"
            }`}
          >
            {team.map((member, index) => (
              <div key={index}>
                <div
                  className={`m-3 ${isMobile ? "" : "grow-animation"} ${
                    member.shadowClass
                  }`}
                >
                  <div
                    className={member.containerClass}
                    style={{
                      width: isMobile ? "15rem" : "10rem",
                      height: isMobile ? "15rem" : "10rem",
                    }}
                  >
                    <img className="image-full-size" src={member.icon} alt="" />
                  </div>
                </div>
                <span
                  className="d-block text-center"
                  style={{ fontWeight: 600, fontSize: isMobile ? "22px" : "" }}
                >
                  {member.name}
                </span>
                <span
                  className="d-block text-center"
                  style={{ fontSize: isMobile ? "22px" : "14px" }}
                >
                  {member.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
