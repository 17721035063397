import React from "react";
import corpos2 from "../../../../assets/images/factions/Corpo/ch2..webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const CorposChapter2 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">Chapter.2 - Corporate rat-race</h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          RRekko’s climb to power as CEO was anything but ordinary, but then
          again, nothing is in Mice City. Mr. Rekko was in direct competition
          for the leadership of Masutrap with another hard working mouse who had
          been loyal to the company for two decades - Maury Ethelstein.
          <br />
          <br />
          Maury , or Pops as he was affectionately called, was probably the most
          famous mouse to work at Maustrap. He had climbed through the ranks
          slowly,
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${
                  isMobile ? "" : "ps-3 pe-3 d-inline"
                }`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <img className="image-full-size" src={corpos2} alt="" />
              </div>
            </div>
          )}
          caring for his employees, going above and beyond for them and making
          sure they never lacked for anything, be it at work or at home. Maury
          even donated 30% of his yearly earnings to The Agency and the College
          Institute of Mice City. He always wanted to make sure he left behind a
          city where education was a priority and where the crime families were
          driven back to their rightful place in the gutters.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3 pe-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={corpos2} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          So naturally most favored Pops over his rival, Rodent Rekko. They knew
          Rekko had no concerns with the future, the city or least of all
          playing “goody two shoes” with his employees. But make no mistake,
          this also brought him a lot of high profile backers within the
          company, those who wanted to see their wallets grow bigger, no
          questions asked.
          <br />
          <br />
          These greed infested mice knew exactly what he stood for: exactly what
          they themselves desired. Rekko had only one mantra, one belief, one
          god - the God of Growth. Financial growth at all costs. He lived by
          the capitalist creed in all the wrong ways - Improve profits or slowly
          dissolve into obscurity.
          <br />
          <br />
          So his ambition was too great and his greed too insatiable to leave
          anything up to chance. He made sure he got to vote for the company’s
          leadership.
          <br />
          <br />
          At this point, Rekko was also working with an up-and-coming rat in the
          drug business, helping him move around some of that illegal cheddar.
          Some of you may already know who we’re talking about – Manny “Ratface”
          Montana.
          <br />
          <br />
        </span>
      </div>
    </div>
  );
};
