import React from "react";
import riders3 from "../../../../assets/images/factions/Night Riders/ch 3.webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const NightRidersChapter3 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.3 - The Road to Freedom
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          Soon the news had spread all over the city, and every mouse with an
          itch for speed or a point to prove started coming to the famous
          meet-up on top of the hill.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${
                  isMobile ? "" : "ps-3 pe-3 d-inline"
                }`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={riders3} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          Here it didn’t matter who you were – street level dealers, 9-to-5 mice
          caught up in the everyday rat-race, rich Corpos, or eccentric
          fashionistas – all that mattered was how fast you were, and all were
          welcomed under the NRS, led by the original&#160;<b>Night Riders.</b>
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3 pe-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={riders3} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          Now the spectacle is in full swing, with auto-shows and races every
          night, and not just between the Night Riders and their Italian
          exotics.
          <br />
          <br />
          Amid all the bright red Italian “Prancing Puppies” (Ferrari – prancing
          pony) and yellow “Charging Cats” (Lambos – charging bull) of Hotshot
          Locatelli and the original founders we are starting to see more and
          more muscle cars, bikes and imports from the Asian market. Cheaper to
          buy and easy to modify in order to compete against the exotics, this
          means endless possibilities to get into the society, and more chances
          to prove yourself against the best.
          <br />
          <br />
          The Night Riders are at the height of their popularity, but with all
          their inclusiveness with mice from all tiers within Mice City, this
          might just be the best way for The Agency and their undercover
          operatives to infiltrate the Crime Syndicate.
        </span>
      </div>
    </div>
  );
};
