import React from "react";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";
import riders1 from "../../../../assets/images/factions/Night Riders/ch1.webp";

export const NightRidersChapter1 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">Chapter.1 - New horizons</h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          The recent power shift in Mice City didn’t just mean big changes for
          the mice on top. Far from it, in fact it meant a sudden influx of “new
          cheddar” in town. Hundreds of middle class business owners willing to
          play ball with the Crime Syndicate opened up a diverse city wide
          cheddar laundering network.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3 d-inline"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={riders1} alt="" />
              </div>
            </div>
          )}
          This turned average working class citizens into wealthy business
          owners almost overnight. And with the newly rich we also get a brand
          new generation of trust-fund babies.
          <br />
          <br />
          Suddenly the streets were starting to fill up with adrenaline seeking
          mice who just wanted to be seen in the hottest exotic sports cars,
          wearing the most expensive clothing and getting the most attention.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={riders1} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          Naturally this led to “tail-measuring” contests between the
          baby-juniors, as they all wanted to prove they were the fastest, the
          best and the slickest of them all.
          <br />
          <br />
          So, each night, the twisty roads covering the hills around Mice City
          started to come alive with the rumble of revving engines and tire
          screeching. These speed freaks would race each-other from the top of
          the hill all the way into the middle of the city. The stakes kept
          getting higher, first racing for obscene amounts of cheddar and
          eventually they were all putting up their pink slips just for a chance
          to take on the very best.
          <br />
          <br />
          You might think the cops would put a stop to all of this, and to an
          extent they did try, arresting the very few they even managed to
          catch. But they were connected with the Crime Syndicate now, and
          everyone knew that even some of the police captains and detectives
          were on the payroll. So it didn’t take long before they were set free,
          and back behind the wheel.
          <br />
          <br />
          But up until this point, these mice were just known as thrill seekers,
          young new money with an adrenaline itch to scratch and the resources
          to do it. It would take someone to organize them, shape them into a
          crew around which an entire mythos of absolute freedom could form,
          attracting hundreds of like-minded individuals.
          <br />
          <br />
          And it all started when <b>Mickey Locatelli</b> came into town after
          retiring from his racing career in the European Fromage 1 Racing
          Series.
          <br />
          <br />
        </span>
      </div>
      <span></span>
    </div>
  );
};
