import React, { FC } from "react";
import whiteMouse from "../../assets/backgrounds/mouse_agency@2x.webp";
import crimeBanner from "../../assets/backgrounds/crime_banner2@2x.webp";
import fashionBanner from "../../assets/backgrounds/fashion_2xcroped.webp";
import { MintBanner } from "../../components/mintBanner/MintBanner";
import PageState from "../../components/PageState";

export const isGreaterThanMintTime = () => {
  const dateNow = new Date().getTime();
  return dateNow > 1645228800;
};

type DesktopBannerType = {
  mint: any;
  listing: any;
  buysCount: any;
  paymentToken: string;
  setPaymentToken: any;
};

export const DesktopBanner: FC<DesktopBannerType> = ({
  mint,
  listing,
  paymentToken,
  setPaymentToken,
  buysCount,
}) => {
  return (
    <>
      <div
        className="box-shadow w-100 mx-0"
        style={{ marginBottom: "-0.5rem" }}
      >
        <div className="banner-container mt-2 d-flex justify-content-between">
          <div className="d-flex">
            <div
              className="overflow-hidden position-absolute"
              style={{
                zIndex: "3",
                maxWidth: "475px",
                transform: "translateX(-1.5rem)",
              }}
            >
              <img className="image-full-size" src={whiteMouse} alt="" />
            </div>
            <div
              className="text-start w-100"
              style={{ zIndex: "2", maxWidth: "520px", marginLeft: "11rem" }}
            >
              <img className="image-full-size" src={crimeBanner} alt="" />
            </div>
            <div
              className="overflow-hidden position-absolute"
              style={{ zIndex: "1", maxWidth: "360px", marginLeft: "27.5rem" }}
            >
              <img className="image-full-size" src={fashionBanner} alt="" />
            </div>
          </div>
          <div
            className="discord-banner-container w-75"
            style={{ maxWidth: "27rem", transform: "translateX(6px)" }}
          >
            <div className="content flex-column align-items-center">
              <p>
                Welcome to the 1980s, set in an alternative Miami populated by
                mice. Various factions roam these mean streets. From the decade
                of big hair, excess, pastel suits & neon lights comes this
                collection of pure retro nostalgia.
                <br />
                <br />
                <b>Mint a Mouse</b>
                <br />
                ✅&nbsp;Fully Randomized on chain minting <br />
                ✅&nbsp;Compatible with all Elrond marketplaces <br />
                ✅&nbsp;90% of budget reinvested back to the community <br />
                ✅&nbsp;Experienced team & a vibrant community <br />
                ✅&nbsp;Crew3 Quests and Rewards <br />
                ✅&nbsp;NFT Staking
                <br />
              </p>
              {listing ? (
                <MintBanner
                  mint={mint}
                  listing={listing}
                  paymentToken={paymentToken}
                  setPaymentCount={setPaymentToken}
                  buysCount={buysCount}
                />
              ) : (
                <PageState svgComponent={<></>} spin />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="box-shadow w-75 mx-0" style={{ height: "1.5rem" }}>
        <div className="empty-banner mt-2 mt-0 d-flex h-100" />
      </div>
    </>
  );
};
