import React from "react";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import corpos1 from "../../assets/images/factions/Corpo/ch1.webp";

export const ThePlan = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        Shattered Alliance
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            Unfortunately for Rekko, this utopic feeling wasn’t going to last.
            Everything was about to change after he got a sealed black envelope
            addressed to his personal office on top of the Maustrap Tower.
          </b>
          <br />
          This is how he received all information from his mole inside the
          Agency: a team of Corpos specialized in corporate surveillance would
          get a scrambled message from the Flyboys, they would decode it and one
          of his most trusted enforcers would bring him the transcript sealed in
          an unmarked black envelope.
          <br />
          <div className="faction-shadow">
            <div
              className={`d-inline m-0 mt-2 ${isMobile ? "" : "pe-4"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={corpos1} alt="" />
            </div>
          </div>
          But today’s delivery brought with it a different sort of intelligence
          than the usual investigations, raids or operations.
          <br />
          Rekko was staring at the piece of paper in his hand, speechless and
          baffled by the absurdity he was reading.
          <br />
          <p className="dialog-font m-0">
            &nbsp;&nbsp;&nbsp;&nbsp;- No… he wouldn’t dare. Not even Ratface
            would be so reckless and power hungry to go after Corpo businesses.
            Could he?
          </p>
          <br />
          But the report was crystal clear. The “Great Art Heist” from 2 months
          ago, as it was now known, was pulled by 6 rats, later identified by
          the Agency as Crime Syndicate enforcers, and two unidentified Night
          Rider members.
          <br />
          <br />
          This didn’t surprise Rekko in the slightest, he was fully aware of
          Ratface’s hold over the Night Riders and “Hotshot” Locatelli.
          Montana’s ties to the Locatelli crime family were known all over Mice
          City.
          <br />
          The report had several mentions of an Agency asset deep undercover
          with Ratface’s inner circle, but unfortunately no details were
          available about the identity.
        </span>
      </div>
    </div>
  );
};
