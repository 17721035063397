import React from "react";
import agency2 from "../../../../assets/images/factions/Agency/Ch2.webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const AgencyChapter2 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.2 - Witnessing Criminal History
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          This oasis of “real law” in a city of corruption didn’t start out with
          some righteous high ranking captain, but with a detective from the
          downtown precinct. A damn good detective in fact –&#160;
          <b>Axel Mousley!</b>
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${
                  isMobile ? "" : "ps-3 pe-3 d-inline"
                }`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <img className="image-full-size" src={agency2} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          After a one year investigation into the South American Cartel’s main
          mouse in Mice City (a case no one imagined would lead anywhere),
          Mousley noticed all communication was eventually sent from the
          Cartel’s mice to a single mid-level member.
          <br />
          <br />
          Who was this guy and why did the entire Cartel trust all its
          information to a relatively unknown in the game?
          <br />
          <br />
          All they knew was that he seemed respected by every crime family in
          town, who called him “Ratface” because of his distinct snout covered
          in scars.
          <br />
          <br />
          Mousley was determined to find out, so he got in touch with a few
          trustworthy agents working undercover with some local gangs, and set
          up a solid surveillance network all tracking one guy.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3 pe-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={agency2} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          Eventually this paid off and after months of working they stumbled
          upon something neither of them could imagine – a meeting between
          Ratface and the most unscrupulous businessman in town, the VP of
          Maustrap Incorporated, Rodent Rekko himself. They didn’t know at the
          time, but what they had just witnessed was the deal which would make
          Rekko the CEO of Maustrap and Ratface the most influential and
          respected criminal in town.
          <br />
          <br />
          Mousley and his newly formed Task Force tried to go to their
          commanding officers with what they had found, but it was too late.
          Rekko had the Corpos pay off all the right mice, so any investigation
          into the matter got shut down fast.
          <br />
          <br />
          Seven months later, Ratface would arrange a sit-down with all five
          families in town and create the all-powerful Crime Syndicate ruling
          Mice City to this day.
          <br />
          <br />
        </span>
      </div>
    </div>
  );
};
