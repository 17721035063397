import { network, referer, xoProxy } from "../config.devnet";
import axios from "axios";

// export const getBuyListings = async (address: Address) => {
//   const result = await getListingsFromSC(address);
//
//   if (result) {
//     return result
//       .filter(
//         (col: any) =>
//           col.is_live &&
//           col.nft_left.toNumber() !== col.collection_size.toNumber() &&
//           col.collection_size.toNumber() === col.last_nftid.toNumber()
//       )
//       .map((col: any) => {
//         col.cid = col.cid.toString();
//         col.collectiontag = col.collectiontag.toString();
//         col.nft_ending = col.nft_ending.toString();
//         col.token = col.token.toString();
//         col.prices = col.prices.valueOf().map(({ token, price }: any) => {
//           return {
//             token: token.toString(),
//             price: price.toNumber(),
//           };
//         });
//         col.last_nftid = col.last_nftid.toNumber();
//         col.start_date = col.start_date.toNumber();
//         col.maxbuyscount = col.maxbuyscount.toNumber();
//         col.collection_size = col.collection_size.toNumber();
//         col.nft_left = col.nft_left.toNumber();
//         return col;
//       });
//   }
// };

export const getListingsFromSC = async (address: string) => {
  const { data } = await axios.get(
    `${network.apiAddress}/getListingsFromSC/${address}`,
    {
      headers: {
        Referer: referer,
      },
    }
  );
  return data;
};

export const getStages = async (smartContract: string) => {
  const { data } = await axios.get(
    `${xoProxy}/getStages/${smartContract}/MiceCity`,
    {
      headers: {
        Referer: referer,
      },
    }
  );
  return data;
};

export const getListings = async (smartContract: string) => {
  const { data } = await axios.get(
    `${xoProxy}/getListingsFromSC/${smartContract}`,
    {
      headers: {
        Referer: referer,
      },
    }
  );
  return data;
};
