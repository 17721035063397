import React, { useEffect, useRef, useState } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import crimeSyndicate from "../../../assets/containerImages/factions/crime.webp";
import corpos from "../../../assets/containerImages/factions/corpos.webp";
import nightRiders from "../../../assets/containerImages/factions/night-riders.webp";
import lawEnforcement from "../../../assets/containerImages/factions/agency.webp";
import clubbingAndFashion from "../../../assets/containerImages/factions/clubbing.webp";
import topRight from "../../../assets/containerImages/factions/bg_top_right.webp";
import bottomRight from "../../../assets/containerImages/factions/bg_right_bot.webp";
import bottomLeft from "../../../assets/containerImages/factions/faction_bg_left.webp";
import "./FactionsBanner.scss";
import { Factions } from "../../../model/Main";
import { createSearchParams, useNavigate } from "react-router-dom";

export const FactionsBanner = (props: any) => {
  const isMobile = useDisplaySize();
  const factionsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const factionsContainerRef = useRef<HTMLDivElement>(null);
  const factionSvgs = [
    {
      name: Factions.CrimeSyndicate,
      icon: crimeSyndicate,
    },
    {
      name: Factions.Corpos,
      icon: corpos,
    },
    {
      name: Factions.NighRiders,
      icon: nightRiders,
    },
    {
      name: Factions.Agency,
      icon: lawEnforcement,
    },
    {
      name: Factions.Clubbing,
      icon: clubbingAndFashion,
    },
  ];

  useEffect(() => {
    if (factionsRef.current && props.scroll) {
      factionsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [props.scroll]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (factionsContainerRef.current) {
        if (
          factionsContainerRef.current.getBoundingClientRect().top <
          window.innerHeight
        ) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    });
  }, []);

  const handleFactionClick = (faction: any) => {
    navigate({
      pathname: "factions",
      search: `?${createSearchParams({
        faction: faction,
      })}`,
    });
  };

  return (
    <>
      <div
        className="box-shadow w-100 mx-0"
        style={{ marginTop: "2.5rem" }}
        ref={factionsRef}
      >
        <div className="factions-container mt-2 mt-0 flex-column px-2">
          <div
            className={`title-box-shadow mx-0 ${
              isMobile ? "text-center" : "ps-5"
            }`}
          >
            <div className="title-container d-inline">
              <span>Factions</span>
            </div>
          </div>
          {!isMobile && (
            <div className="top-right-bg">
              <img className="image-full-size" src={topRight} alt="" />
            </div>
          )}
          {!isMobile && (
            <div className="bottom-right">
              <img className="image-full-size" src={bottomRight} alt="" />
            </div>
          )}
          {!isMobile && (
            <div className="bottom-left">
              <img className="image-full-size" src={bottomLeft} alt="" />
            </div>
          )}
          <div style={{ paddingTop: "1rem", paddingBottom: "7rem" }}>
            {!isMobile && (
              <div className="d-flex flex-column align-items-center pt-5">
                <span
                  className="h5 d-block"
                  style={{ color: "#FC5AFF", fontWeight: 600 }}
                >
                  Adrenaline, Power, Order, Speed or Style:
                </span>
                <span className="text-center d-block w-75">
                  Whatever you crave, you&apos;ll be able to find it in one of
                  the factions Mice City.
                  <br />
                  All with outrageously different lifestyles, wanting to carve
                  out their part of the city!
                  <br /> Who will you side with?
                </span>
              </div>
            )}
            <div
              className={`${
                isMobile
                  ? "d-flex flex-column w-75 m-auto"
                  : "mt-3 d-flex justify-content-between align-items-center container"
              }`}
            >
              <div
                ref={factionsContainerRef}
                className={`row ${
                  isMobile ? "" : "d-flex justify-content-center mt-4"
                }`}
              >
                {factionSvgs.map((faction, index) => (
                  <>
                    <div
                      className={`faction ${isMobile ? "my-5" : "col-3"} ${
                        visible ? "scale-" + index : ""
                      }`}
                      key={index}
                      onClick={() => handleFactionClick(faction.name)}
                    >
                      <img
                        className="image-full-size"
                        src={faction.icon}
                        alt=""
                      />
                    </div>
                    {!isMobile && index === 2 && (
                      <div className="py-3" key={"py-3"} />
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-shadow w-75 mx-0" style={{ height: "1.5rem" }}>
        <div className="empty-banner-faction mt-2 mt-0 d-flex h-100" />
      </div>
    </>
  );
};
