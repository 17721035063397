import React from "react";
import crime3 from "../../../../assets/images/factions/Crime/ch2.webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const SyndicateChapter2 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.2 - The Rise of Ratface
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          He was raised in the middle of the South American drug trade and was
          shaped by their ruthless business model and logistics organization
          spanning multiple countries. He was sent to Mice City to act as a
          delegate of the Cartel and spread their network. But he did more than
          that. Manny wanted to find out why the Cartel’s Mice City branch was
          producing so little income.
          <br />
          <br />
          And the answer was in plain sight: daily attacks towards and from the
          other families in the city. This was clearly counterproductive, not
          just for them, but for the Italians, Russians and the Japanese
          families. It was clear to him they couldn’t win this war. Each family
          had control of a certain essential part of the city, so none were
          lacking in resources or mouse-power.
          <div className="faction-shadow">
            <div
              className={`pb-3 pt-3 m-0 ${
                isMobile ? "" : "ps-3 pe-3 d-inline"
              }`}
              style={{
                maxWidth: isMobile ? "100%" : "37%",
                float: isMobile ? "none" : "left",
              }}
            >
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}
              <img className="image-full-size" src={crime3} alt="" />
            </div>
          </div>
          <br />
          But if the Mexican and Colombian cartels could work together in South
          America, why couldn’t he try to do it here, with the other criminal
          organizations? After all, he was given “carte blanche” from the Cartel
          who had faith in him; he could do as he saw fit, as long as he
          maximized profits.
          <br />
          <br />
          His plan was risky. He stopped all attacks against any of the other
          families for a week. This meant 7 days of nothing but defence, on
          their territories and rackets against the other gangs. And since they
          could focus all of their power in defending rather than attack, it
          also meant they could easily repel any incursion from the others. This
          meant that they didn’t look weak while holding back any aggression of
          their own. After the 7 days were up, he sent his right hand mouse,
          Leo, and a few guys to each of the families as ambassadors, to lay the
          groundwork for his new master plan and show the others his path to a
          better way forward.
          <br />
          <br />
          First he approached the Russian Mafia, power hungry and looking for
          ways to earn more, they saw possibility in Montana’s proposal. Next,
          Leo approached the Italian Mafia, impeccably well organized and with a
          deep history in this town’s past, they were a little harder to come
          around but the promise of no more fighting and growing their wallets
          convinced them to go along with the idea.
          <br />
          <br />
          These negotiations were tougher than you might think, and each of the
          2 bosses approached so far had reservations and tried to push back in
          their own particular way, but for now we don’t have time to get into
          every detail.
          <br />
          <br />
          What I want to focus on is the approach he made to the last boss - the
          Yakuza leader Misumoto Miceamori . This interaction would cement Manny
          &apos;Ratface&apos; Montana as not only a clever pacifist looking for
          profits, but also, if need be, a ruthless monster capable of doing
          anything to accomplish his goals by any means necessary if he was so
          pushed.
        </span>
      </div>
    </div>
  );
};
