import React, { FC } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import titleIcon from "../../../assets/storyCrossRoads/fallout/fallout_car.jpg";

import ConfettiExplosion from "@reonomy/react-confetti-explosion";

type StoryTitleType = {
  confetti: boolean;
  scrollToOutcome: any;
};

export const StoryTitleIV: FC<StoryTitleType> = ({
  confetti,
  scrollToOutcome,
}) => {
  const isMobile = useDisplaySize();

  return (
    <div
      className={`d-flex w-100 justify-content-between align-items-center ${
        isMobile ? "flex-column" : ""
      } `}
    >
      <div style={{ zIndex: 2 }}>
        <span
          style={{ fontWeight: 600, margin: "0" }}
          className="d-block h2 pb-3"
        >
          IV. Fallout
        </span>
        <div
          className="d-block h4"
          style={{ fontWeight: 500, color: "#F6B0E1", cursor: "pointer" }}
          onClick={scrollToOutcome}
        >
          {confetti && (
            <div className="d-flex justify-content-center">
              <ConfettiExplosion duration={3000} />
            </div>
          )}
        </div>
      </div>
      <div style={{ width: isMobile ? "50%" : "35%" }}>
        <img className="image-full-size" src={titleIcon} alt="" />
      </div>
    </div>
  );
};
