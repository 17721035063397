import React, { useState } from "react";
import agency from "../../../assets/images/factions/Agency/CH1.webp";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import topSecret from "../../../assets/storyCrossRoads/topSecret.png";

export const SettingTheStageI = () => {
  const isMobile = useDisplaySize();
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <div>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        Setting the Stage
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            It’s been 3 months since Johnny H first started showing up at the
            Night Riders Society’s weekly underground races in an attempt to
            gain favor with the OG Night Riders and eventually with their leader
            “Hotshot” Locatelli.
          </b>
          <br />
          <br />
          Even after all this time, he hadn’t lost sight of his mission:
          “Infiltrate the Night Riders, get access to The Crime Syndicate’s
          lower-level members and bring them into The Agency’s grasp so they can
          get a foothold into Ratface’s inner circle”&#160;
          <br />
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "30%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={agency} alt="" />
              </div>
            </div>
          )}
          After all, Axel Mousley – The Agency’s Task Force leader – personally
          chose him for this assignment, along with a crew of 5 other agents.
          Out of all 6 agents, only Johnny was good enough to join the Night
          Riders.
          <br />
          <br />
          Johnny smoked everything they threw at him on the streets, race after
          race. The Crimson Kid, as some of the OG Night Riders called Johnny
          because of his rad bright red muscle car, was now the one to beat if
          you wanted to prove your skills.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={agency} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          But being undercover for this long with little contact with his former
          life was taking its toll. He had to be on his toes every single minute
          of every day.
          <br />
          <br />
          These mice might seem like a friendly bunch of adrenaline junkies, but
          a lot of them had deep ties with the Crime Syndicate and were even
          running their own smuggling operations for the Syndicate.
          <br />
          <br />
          But there was one mouse who still puzzled him, and strangely enough,
          that was Mickey Hotshot Locatelli himself.
          <br />
          {showMore && (
            <span>
              The Night Riders’ infamous founder often seemed like a reluctant
              leader.
              <br />
              The sight of him arguing with his crew became more and more
              common, pushing back on demands for high stakes jobs they had to
              do for Ratface Montana and his Syndicate. Other times, he seemed
              fully dedicated to growing the Night Riders mythos and reach,
              patiently training more and more mice for the art of racing.
              <br />
              <br />
              <div className="faction-shadow">
                <div
                  className={`m-0 ${isMobile ? "" : "ps-3 pe-3 d-inline"}`}
                  style={{
                    maxWidth: isMobile ? "100%" : "37%",
                    float: isMobile ? "none" : "left",
                  }}
                >
                  {isMobile && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  <img className="image-full-size" src={topSecret} alt="" />
                </div>
              </div>
              It was like he was trying to save as many youngsters from a life
              of crime in Mice City; either that, or he was just building up the
              ranks of his crew to ramp up the number of jobs they can pull off
              - Johnny wasn’t sure about Locatelli’s true intentions.
              <br />
              <br />
              All that being said, Hotshot grew closer and closer to Johnny over
              the past months.
              <br />
              To Johnny’s surprise, it seemed like they were cut from the same
              cloth: both seeking the fleeting moments of freedom they found
              while racing on the limit, both looking down on the wannabe
              violent gangsters on the rise in Mice City and both hotheads often
              clashing with authority figures in their line of works.
              <br />
              <br />
              But now, The Syndicate is starting to increase its involvement in
              the Night Rider’s business.
              <br />
              The occasional smuggling runs were acceptable to Mickey, a good
              way to keep Ratface satisfied while earning a little extra funds
              for the NRS. But, what Ratface was proposing is on another level -
              a bank heist!
              <br />
              <br />
              Hotshot pushed back on this demand as often as he could, trying
              his best to position the Night Riders as an independent faction in
              Mice City’s colorful landscape, but Ratface Montana wouldn’t hear
              any of it.
              <br />
              <br />
              Finally, all that pressure from Ratface came to a head, when he
              issued an ultimatum. The message was clear: If Mickey Locatelli
              wants to keep his Night Riders independent, he’ll have to do this
              heist.
            </span>
          )}
          <div
            className="vote-button m-auto my-4"
            style={{ width: "max-content" }}
            onClick={() => setShowMore(!showMore)}
          >
            <span>{showMore ? "See less" : "Show more"}</span>
          </div>
        </span>
      </div>
    </div>
  );
};
