import PageState from "../PageState";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { FC } from "react";
import star from "../../assets/icons/star.svg";
import starDisabled from "../../assets/icons/star-disabled.svg";

type MiceListType = {
  loading: any;
  mice: any;
  fetchMoreData: any;
  hasMore: boolean;
};
export const MiceList: FC<MiceListType> = ({
  loading,
  mice,
  fetchMoreData,
  hasMore,
}) => {
  const redirectToMarket = (identifier: string) => {
    window.open(`https://xoxno.com/nft/${identifier}`, "_blank");
  };

  const drawStars = (rank: number, identifier: string) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      let image;
      if (i <= rank - 1) {
        image = star;
      } else {
        image = starDisabled;
      }
      stars.push(
        <div className="star" key={identifier + " " + i}>
          <img
            style={{ border: "none" }}
            className="image-full-size"
            src={image}
            alt=""
          />
        </div>
      );
    }

    return stars;
  };

  return (
    <div className="m-auto pt-4">
      {loading && <PageState svgComponent={<></>} spin />}
      {mice && mice.length > 0 && (
        <InfiniteScroll
          className="overflow-hidden"
          dataLength={mice.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div className="m-auto">
              <PageState svgComponent={<></>} spin />
            </div>
          }
        >
          <div className="container">
            <div className="row d-flex justify-content-center">
              {mice.map((og: any) => (
                <div
                  className="col-xl-2 col-md-3 col-5 og-card d-flex flex-column"
                  key={og.identifier}
                  onClick={() => redirectToMarket(og.identifier)}
                >
                  <div className="image-wrapper position-relative">
                    <div>
                      <img
                        className="image-full-size"
                        src={og.media.webpUrl}
                        alt=""
                      />
                    </div>
                    {/*<div className="rating w-100 d-flex justify-content-around">*/}
                    {/*  {drawStars(og.rankBarsToFill, mice.identifier)}*/}
                    {/*</div>*/}
                  </div>
                  <div className="w-100 position-relative">
                    <div className="strip" />
                    <div className="strip" />
                    <div className="strip" />
                    <div className="strip" />
                    <div className="info-box-shadow ms-1 position-absolute">
                      <div className="info-container flex-column text-white">
                        <div style={{ fontSize: "0.9em" }}># {og.nonce}</div>
                        <div style={{ fontSize: "0.7em" }}>
                          <span style={{ color: "rgb(13 69 129)" }}>
                            Rank:&nbsp;
                          </span>{" "}
                          {og.metadata.rarity.rank}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};
