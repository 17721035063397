import React, { FC, useEffect, useRef, useState } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import banner from "../../../assets/storyCrossRoads/fallout/fallout_vote.jpg";

type VoteType = {
  voteOption: string;
  didVote: boolean;
  hasMice: boolean;
  loadingVoteInfo: boolean;
  setVoted: any;
};

enum Options {
  "A" = "A",
  "B" = "B",
  "C" = "C",
}

export const TheVoteIV: FC<VoteType> = ({ didVote, voteOption }) => {
  const isMobile = useDisplaySize();
  const [selected, setSelected] = useState(Options.A);
  const newspaperRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  console.log(voteOption);

  useEffect(() => {
    setSelected(voteOption as Options);
  }, [voteOption]);

  return (
    <div className="d-flex flex-column align-items-center">
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Vote
      </span>
      <div
        ref={newspaperRef}
        className={`${isMobile ? "w-100" : "w-100"}  ${
          isMobile ? "d-flex justify-content-center" : ""
        }`}
        style={{
          marginBottom: "4rem",
        }}
      >
        <img className="image-full-size" src={banner} alt="" />
      </div>
      <b>
        Mousley already had a plan to stop this constant escalation between the
        two factions, and it would involve most of his undercover agents. But
        Ratface is making moves as well, trying to assert the Syndicate’s power
        in the face of this shocking insult.
      </b>
      <br />
      {showMore && (
        <div className="d-flex align-items-center flex-column pb-4">
          <div
            className={`d-flex align-items-center pb-4 ${
              isMobile ? "flex-column" : ""
            }`}
            style={{ height: !isMobile ? "300px" : "" }}
          >
            <div
              className={`vote-box h-100 ${
                selected === Options.A ? "pressed" : ""
              } ${isMobile ? "mb-4" : "me-4"}`}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.A)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                A.
              </span>
              <span>
                Mousley had informants within The Fashionistas. Since the Corpos
                now hold sway over The Twins, he would use them to leak the
                details of Johnny’s meeting with the senator, prompting Rekko to
                act and lure him into a trap, giving Ratface the opportunity to
                take him out and prevent a faction war. Taking out the Corpo
                leadership would be a bonus, or so Axel hoped…
              </span>
            </div>
            <div
              className={`vote-box h-100 ${
                selected === Options.B ? "pressed" : ""
              } `}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.B)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                B.
              </span>
              <span>
                Because of his newfound admiration for the Syndicate godfather,
                Johnny comes clean to Ratface, admits his connection with the
                Agency and offers to work as a double agent. Using this
                information Ratface is able to get to Rekko. How will he deal
                with him? Even Ratface hasn&lsquo;t decided on that yet…
              </span>
            </div>
          </div>
          <div
            className={`vote-box w-100 ${
              selected === Options.C ? "pressed" : ""
            } `}
            style={{ pointerEvents: didVote ? "none" : "auto" }}
            onClick={() => setSelected(Options.C)}
          >
            <span
              className="pb-3 d-block"
              style={{ fontSize: "20px", fontWeight: 600 }}
            >
              C.
            </span>
            <span>
              While Johnny tries to secure the senator’s support, Ratface uses
              one of his old partners to get into the same room as Rekko. The
              former Yakuza leader, Rakagashira, had many loyal mice working in
              the Maustrap Inc. headquarters, where they always held a strong
              presence. They would be the key to reaching the King of Greed.
            </span>
          </div>
        </div>
      )}
      <div
        className="vote-button m-auto my-4"
        style={{ width: "max-content" }}
        onClick={() => setShowMore(!showMore)}
      >
        <span>{showMore ? "Hide options" : "Show options"}</span>
      </div>
      {!didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          <span>Tune in next week and find out how the clash unfolds!</span>
        </div>
      )}
      {/*{didVote && (*/}
      {/*  <div*/}
      {/*    className="text-center m-auto pb-3"*/}
      {/*    style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}*/}
      {/*  >*/}
      {/*    <span>*/}
      {/*      <b>Tune in next week and find out how the clash unfolds!</b>*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};
