import React from "react";
import clubbing2 from "../../../../assets/images/factions/Clubbing and Fashion/ch3..webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const ClubbingChapter2 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">Chapter.2 - Party Agenda</h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          All this is run by the most unlikely faction in town –{" "}
          <b>The Fashionistas.</b>
          <br />
          <br />
          These snazzy looking mice only worry about one thing, and that’s
          keeping the party going at all costs.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${
                  isMobile ? "" : "ps-3 pe-3 d-inline"
                }`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <img className="image-full-size" src={clubbing2} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          That means that no matter who comes to the Neon Drive to rub shoulders
          with stars or go to their bodacious concerts and discos, they make
          sure the tension or disputes from the outside stay on the outside.
          <br />
          <br />
          Here there’s only one agenda: radical parties.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3 pe-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={clubbing2} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          And keeping that peace isn’t such a bad move for either faction: The
          Fashionistas get to keep their temple of excess open and grow more
          influential by the day, the Syndicate gets the perfect market for
          their drugs – bought in bulk by the Fashionistas to supply their
          clubs, The Corpos get to burn through all their cheddar and get access
          to the best VIP lounges on the Drive and the Night Riders get to race
          in front of the best crowds in town.
          <br />
          <br />
          And let’s not forget about The Agency’s Task Force operatives, who get
          to listen in on every one of Mice City’s factions all in one place.
          <br />
          <br />
          Just make sure you dress accordingly if you plan on swinging by,
          because the Fashion Police guarding every entrance to the Neon Drive
          won’t let just any mouse with a gnarly outfit walk in. After all, they
          have a reputation to keep.
        </span>
      </div>
    </div>
  );
};
