import React, { useState } from "react";
import corpos1 from "../../../assets/images/factions/Corpo/ch3.webp";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import ticket from "../../../assets/storyCrossRoads/burning_mouse/festival_ticket.png";

export const SettingTheStageII = () => {
  const isMobile = useDisplaySize();
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <div>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        Corporate Interest
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            Ever since Rodent Rekko secured his seat as the CEO of Maustrap and
            leader of the so called Corpos, he’s been hard at work sinking his
            claws into as many sectors of Mice City he could. He was now the
            shadow owner of every leading financial institution in town. He even
            had his people inside the treasury and the Town Hall.
          </b>
          <br />
          <br />
          This of course meant every single one of his subsidiaries and
          businesses were first in line for any government funding, approval or
          contracts. Couple this with his infamous “Deal with the Devil”, the
          alliance with Ratface Montana, which made sure he had no more
          opposition in town and you start to get a picture of the extent of his
          power and influence.&#160;
          <br />
          <br />
          {showMore && (
            <span>
              {!isMobile && (
                <div className="faction-shadow">
                  <div
                    className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
                    style={{
                      maxWidth: isMobile ? "100%" : "30%",
                      float: isMobile ? "none" : "right",
                    }}
                  >
                    <img className="image-full-size" src={corpos1} alt="" />
                  </div>
                </div>
              )}
              Rekko was furious that someone had pulled an insane heist at the
              largest bank he owned, stealing two one of a kind priceless art
              pieces. But he was sure that with Ratface’s help he would
              eventually find out who did this and make an example out of them.
              <br />
              However, Rodent had something else on his mind, so the heist could
              wait.
              <br />
              <br />
              After all his hostile takeovers within Mice City, there were still
              a few key areas which still eluded his complete control, the main
              one being The Agency. While Rekko had managed to get a few agents
              on his payroll, just enough to get the occasional tip about their
              operations, it was still not enough to have actual sway over their
              leadership. They were too well organized and isolated for that.
              {isMobile && (
                <div className="faction-shadow">
                  <div
                    className={`pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                    style={{
                      maxWidth: isMobile ? "100%" : "37%",
                      float: isMobile ? "none" : "right",
                    }}
                  >
                    <br />
                    <br />
                    <img className="image-full-size" src={corpos1} alt="" />
                  </div>
                </div>
              )}
              <br />
              <br />
              The other big absence from his catalog of “cheddar generators” was
              the enormous cash cow that is the Neon Drive. As much as he tried,
              he could never reach eye to eye with The Twins. Their oasis of
              pure excess, fashion and party freedom was still untouchable to
              any other business interest from the outside.
              <br />
              <br />
              But this wasn’t just any other corporate interest. It was Rodent
              Rekko asking, and he wasn’t about to quit.
              <br />
              <br />
              As luck would have, he might have just found his opportunity to
              access the obscene cheddar produced by the Clubbing & Fashion
              Scene and get a more stable foothold into the The Agency.
              <br />
              <div
                className="m-auto"
                style={{ width: isMobile ? "100%" : "85%" }}
              >
                <img className="image-full-size" src={ticket} alt="" />
              </div>
            </span>
          )}
          <div
            className="vote-button m-auto my-4"
            style={{ width: "max-content" }}
            onClick={() => setShowMore(!showMore)}
          >
            <span>{showMore ? "See less" : "Show more"}</span>
          </div>
        </span>
      </div>
    </div>
  );
};
