import React from "react";
import agency3 from "../../../../assets/images/factions/Agency/CH3.webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const AgencyChapter3 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.3 - Adapting to Unforeseen Setbacks
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          But Axel Mousley and his Task Force didn’t just give up. They adapted
          to the new order and kept their surveillance network alive.
          <br />
          <br />
          Unlike the Agency&apos;s operations in the past which had the support
          of politicians and officials alike, this time most of the leading
          brass is against them. This means they have to work quietly,
          undercover and without raising any suspicion.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${
                  isMobile ? "" : "ps-3 pe-3 d-inline"
                }`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={agency3} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          But simply infiltrating the Corpos’ or the Crime Syndicate’s inner
          circles is impossible, so the Task Force is starting its work from the
          bottom.
          <br />
          <br />
          They are sending undercover agents all over town to infiltrate Mice
          City’s racing, fashion and clubbing scenes.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3 pe-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={agency3} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          These circles always rub shoulders with members of the Syndicate or
          Corpos who are looking to spend some of all that new cheddar on fast
          cars, wild parties and crazy outfits.
          <br />
          <br />
          Naturally, the Night Riders and the party loving Fashionistas of Mice
          City are the go to mice for those types of needs.
          <br />
          <br />
          By now, the Task Force already has undercover agents among the biggest
          Night Rider crews and the hottest clubs in town.
          <br />
          <br />
          Reports are coming back that Johnny H, one of his most trusted
          undercover agents, and his team is getting closer and closer to
          Hotshot Locatelli and his OG Night Riders crew. That might be their
          best way of getting a peek behind the curtains at Ratface’s operation.
          <br />
          <br />
          It’s only a matter of time until they breach the Corpos and the
          Syndicate’s inner circle as well.
          <br />
          <br />
          Mousley trusted his friend, but knew that all that freedom and
          adrenaline found with the Night Riders might be too tempting for him
          to resist. Still he was patient, confident that Johnny would
          eventually deliver on his potential and finally get them their way in.
          <br />
          <br />
          It’s only a matter of time until they breach the Corpos and the
          Syndicate’s inner circle as well.
        </span>
      </div>
    </div>
  );
};
