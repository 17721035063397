import React from "react";
import "./VoteBanner.scss";
import voteLogo from "../../../assets/containerImages/vote/vote_logo.png";
import { useNavigate } from "react-router-dom";

export const VoteBanner = () => {
  const navigate = useNavigate();

  const handleStories = () => {
    navigate("/stories");
  };

  return (
    <div className="box-shadow py-4">
      <div className="vote-polygon-container">
        <div
          className="d-flex flex-column align-items-center m-auto py-5"
          style={{ maxWidth: "70%" }}
        >
          <div className="w-75 pb-2">
            <img className="image-full-size" src={voteLogo} alt="" />
          </div>
          <div className="text-center text-white pb-5">
            <span>
              As a Mice City NFT holder <b>you decide</b> your story. In the
              wild streets of the city, party, rob, extort, traffic or just try
              to make a living.
            </span>
          </div>
          <div className="vote-button" onClick={handleStories}>
            <span>Check out Mice Stories</span>
          </div>
        </div>
      </div>
    </div>
  );
};
