export interface GlobalModalType {
  openGlobalModal: boolean;
}

export interface InfoModalType {
  openInfoModal: boolean;
  title: string;
  message: any;
  hasIcon: boolean;
  icon: any;
}

export interface LoginInfo {
  access_token: string;
}

export interface StateType {
  globalModal: GlobalModalType;
  lastSessionId: string | null;
  infoModal: InfoModalType;
}

const globalModalInitialState = {
  openGlobalModal: false,
  globalModalMessage: "",
  globalModalLoading: false,
  globalModalStatusSuccess: false,
  globalModalErrorMessage: "",
  transaction: undefined,
};

const infoModalInitialState: InfoModalType = {
  title: "",
  openInfoModal: false,
  message: "",
  hasIcon: false,
  icon: "",
};

const initialState = (): StateType => {
  return {
    globalModal: globalModalInitialState,
    lastSessionId: null,
    infoModal: infoModalInitialState,
  };
};

export default initialState;
