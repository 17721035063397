import React from "react";
import crime1 from "../../../assets/images/factions/Crime/CH1.webp";
import agency1 from "../../../assets/images/factions/Agency/CH1.webp";
import agency3 from "../../../assets/images/factions/Agency/CH3.webp";
import smuggler from "../../../assets/storyCrossRoads/fallout/turtulneck_smugler.png";
import { useDisplaySize } from "../../../hooks/pageSizeHook";

export const SettingTheStageIV = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <br />
      <br />
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            The garage was filled with the smell of oil and fresh blood, but
            Johnny was already getting used to the scent.
          </b>
          <br />
          He kept staring at the priceless cars Hotshot had on display and
          thinking how simple his mission seemed at the beginning: infiltrate
          <div className="faction-shadow">
            <div
              className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
              style={{
                maxWidth: isMobile ? "80%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={agency1} alt="" />
            </div>
          </div>
          the Night Riders and learn as much as possible about the Syndicate’s
          captains close to Hotshot…little did he know how far it would go. Now,
          months after it all began, here he was, trusted by Ratface, a member
          of his inner circle, constantly living surrounded by madness and
          uncertainty.
          <br />
          <br />
          <span>
            “Why did I do it?” he kept thinking, “Why did I get him out of
            there?”
            <br />
            <br />
            IIt could have all ended for Ratface on this day, as Rekko’s Corpos
            fired on the safehouse, but something pushed Johnny to act.
            <br />
            He tried to tell himself that he did it to prevent a power struggle
            inside The Crime Syndicate, which would surely have happened if
            Ratface had died, plunging Mice City into chaos along with it.
            <br />
            <br />
            It didn’t matter now, it was done…
            <br />
            <br />
            He brought Ratface straight to the Night Riders. Hotshot’s garage
            was the safest place he could think of, since it was often visited
            by Syndicate members. Most of their surviving lieutenants were
            already here.
            <br />
            <br />
            <div className="faction-shadow">
              <div
                className={`d-inline m-0 ${isMobile ? "" : "pe-3 pb-2"}`}
                style={{
                  maxWidth: isMobile ? "80%" : "30%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <img className="image-full-size" src={crime1} alt="" />
              </div>
            </div>
            Johnny focused on Ratface, who was still staring at the walls,
            covered in his bodyguards’ blood during the shootout. He could see
            the rage in his eyes while he tried to think of his next move.
            <br />
            A lot of the enforcers dived in front of Ratface to protect him from
            getting shot. Johnny still couldn’t believe one mouse could inspire
            that much blind loyalty, but somehow, Ratface did.
            <br />
            <br />
            One of Ratface’s lieutenants interrupted the silence:
            <br />
            <br />
            <p className="dialog-font m-0">
              L: We need to act sir. We need to show that pompous greedy prick
              who runs this city! Give me 10 good enforcers and I’ll march
              straight into Maustrap Tower and tear him limb-from-li…
              <br />
              <br />
              Ratface: And then what my brilliant friend??? Rekko dies, the
              Corpos crumble, our financial bedrock disappears along with all
              our political influence! Does that sound like a smart move to you?
              <br />
              <br />
              L: Sir I was only trying to…
              <br />
              <br />
              R: You were only trying to act like a gangster! I don’t need to
              pour more gas on this fire. We need to deal with the fallout of
              our actions.
            </p>
            <div className="faction-shadow">
              <div
                className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "80%" : "30%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={agency3} alt="" />
              </div>
            </div>
            <br />
            Rekko may have taken drastic measures, but that’s only because I
            gave him an excuse when I sanctioned the Art Heist from his own
            business.
            <br />
            <br />
            <p className="dialog-font m-0">
              L: Those art pieces will win us favor with Senator Mooney. His
              political sway might just be enough to show Rekko we don’t
              entirely depend on his connections. I still say you made the right
              call with that Heist and it will be worth it.
              <br />
              <br />
              R: We shall see. For now, we need to keep a low profile and get on
              top of this situation. Johnny, I’ll need you for this too. Have
              you heard of George Younger?
              <br />
              <br />
              Johnny: The Suave Smuggler? Of course, he’s an urban legend, the
              mouse able to move anything, anywhere without getting caught
              <br />
              <br />
              R: Almost…he’s my urban legend, the mouse who smuggled every
              product I sold before I built the Syndicate. We’ve worked together
              since we were kids. I need you to go with him and deliver those
              art pieces to the senator and ask for his backing. George will get
              you to his compound undetected. You’ll leave tomorrow. I’ll get
              things moving here by the time you return.
              <br />
              <br />
              J: Consider it done.
            </p>
            <br />
            <div className="faction-shadow">
              <div
                className={`d-inline m-0 ${isMobile ? "" : "pe-3 pb-2"}`}
                style={{
                  maxWidth: isMobile ? "80%" : "30%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <img className="image-full-size" src={smuggler} alt="" />
              </div>
            </div>
            Johnny left the garage to go rest and prepare. He was starting to
            get paranoid from all this insanity. He could swear he noticed a
            Corpo surveillance van following him, but that was just his mind
            playing tricks. There was no way for anyone in the Corpos to know
            him or who he was.
            <br />
            <br />
            But this conflict was reaching new heights. Now even senators would
            be involved. When we helped Hotshot rob that bank for those art
            pieces, he had no idea Ratface wanted to use them as leverage for
            getting more political influence with Corpo control.
            <br />
            <br />
            He had always seen Ratface as a maniac and ruthless murderer but,
            being this close to him for so much time, he had to admit he was
            also a cunning and intelligent mouse.
            <br />
            <br />
            Even so, it was time to brief Axel Mousley about this. Johnny always
            kept his contact with the Agency’s Anti-Syndicate Task Force to a
            minimum in order to avoid suspicion, but this was important.
            <br />
            <br />
            He used his burner-phone to call Axel and get him up to speed. To
            his surprise, Axel and the Task Force were already investigating
            Ratface’s safehouse following the attack. This possible war between
            the Corpos and the Syndicate had the entire Agency working overtime.
          </span>
        </span>
      </div>
    </div>
  );
};
