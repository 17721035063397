import React, { FC, useEffect, useRef, useState } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import banner from "../../../assets/storyCrossRoads/serpentHeads/the_vote.jpg";
import "../../../pages/crossStories/CrossStories.scss";

type VoteType = {
  voteOption: string;
  didVote: boolean;
  hasMice: boolean;
  loadingVoteInfo: boolean;
  setVoted: any;
};

enum Options {
  "A" = "A",
  "B" = "B",
  "C" = "C",
}

export const TheVoteV: FC<VoteType> = ({ didVote, voteOption }) => {
  const isMobile = useDisplaySize();
  const [selected, setSelected] = useState(Options.A);
  const newspaperRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  console.log(voteOption);

  useEffect(() => {
    setSelected(voteOption as Options);
  }, [voteOption]);

  return (
    <div className="d-flex flex-column align-items-center">
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Vote
      </span>
      <div
        ref={newspaperRef}
        className={`${isMobile ? "w-100" : "w-100"}  ${
          isMobile ? "d-flex justify-content-center" : ""
        }`}
        style={{
          marginBottom: "4rem",
        }}
      >
        <img className="image-full-size" src={banner} alt="" />
      </div>
      <b>
        While Johnny struggles to act, Operation Serpent Heads goes ahead, with
        Axel Mousley coordinating the attack
      </b>
      <br />
      {showMore && (
        <div className="d-flex align-items-center flex-column pb-4">
          <div
            className={`d-flex align-items-center pb-4 ${
              isMobile ? "flex-column" : ""
            }`}
            style={{ height: !isMobile ? "300px" : "" }}
          >
            <div
              className={`vote-box h-100 ${
                selected === Options.A ? "pressed" : ""
              } ${isMobile ? "mb-4" : "me-4"}`}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.A)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                A.
              </span>
              <span>
                Ratface & Rekko are both taken by surprise, leaving them with
                little chance of escaping the Task Force
              </span>
            </div>
            <div
              className={`vote-box h-100 ${
                selected === Options.B ? "pressed" : ""
              } `}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.B)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                B.
              </span>
              <span>
                Rekko manages to escape, when he’s alerted about The Task Force
                breaching the perimeter. Ratface and his crew struggle to fight
                their way out, but their capture looks inevitable
              </span>
            </div>
          </div>
          <div
            className={`vote-box w-100 ${
              selected === Options.C ? "pressed" : ""
            } `}
            style={{ pointerEvents: didVote ? "none" : "auto" }}
            onClick={() => setSelected(Options.C)}
          >
            <span
              className="pb-3 d-block"
              style={{ fontSize: "20px", fontWeight: 600 }}
            >
              C.
            </span>
            <span>
              As soon as the attack starts, Ratface is taken to safety by his
              crew, but Rekko is trapped in the top floor, with his Corpo
              enforcers fighting the Agency. The odds are not in their favor,
              and capture looks likely
            </span>
          </div>
        </div>
      )}

      <div
        className="vote-button m-auto my-4"
        style={{ width: "max-content" }}
        onClick={() => setShowMore(!showMore)}
      >
        <span>{showMore ? "Hide options" : "Show options"}</span>
      </div>
      {!didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          {/*<span>Tune in next week and find out how the clash unfolds!</span>*/}
        </div>
      )}
      {didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          <span>
            <b>Tune in next week and find out how the clash unfolds!!</b>
          </span>
        </div>
      )}
    </div>
  );
};
