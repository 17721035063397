import React, { FC } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import crime1 from "../../../assets/images/factions/Crime/CH1.webp";
import corpos1 from "../../../assets/images/factions/Corpo/ch1.webp";
import gunfire from "../../../assets/storyCrossRoads/clashOfTheUnderlords/gunfire.mp4";
import clubbing1 from "../../../assets/images/factions/Clubbing and Fashion/ch3....webp";

type TheOutcomeType = {
  ref: any;
};

export const TheOutcomeIII: FC<TheOutcomeType> = ({ ref }) => {
  const isMobile = useDisplaySize();
  return (
    <div ref={ref}>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Outcome
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            Looking at this quiet suburban neighborhood you would think nothing
            of it. It just looked like a regular housing district for Mice
            City’s upper-middle class who wanted to live in the North-East part
            of town, near the sea.
          </b>
          <br />
          <br />
          And for the most part it was just that, except for the fact that it
          was the location of Ratface’s main safehouse, unknown to anyone except
          his inner circle. Ratface had bought the house closest to the sea,
          right where all the roads would converge, giving him as many escape
          routes as possible. Not just that, but he also owned the 5 surrounding
          houses, where all of his rats and personal security were stationed.
          <br />
          He might have been hiding in plain sight, but make no mistake, this
          was one of the most secure places in Mice City.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline ps-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={crime1} alt="" />
            </div>
          </div>
          Of course, Rekko was one of the few mice in town who knew about the
          Crime Syndicate leader’s stronghold. It was the place where they had
          met when planning to take out his old rival, Maury Ethelstein. This is
          where it all started: the rise of Ratface and the Corpos’ dominion
          over every political and business endeavor in Mice City.
          <br />
          And now, this could be where it all started to fall down.
          <br />
          <br />
          Rekko was still having second thoughts about this course of action,
          but it was too late to back out now. Everything was in motion. The 4
          SUVs filled with corpo special ops units were now slowly entering the
          street where Ratface’s safehouse was located. Rekko was close behind
          in his car with his elite personal security.
          <br />
          <br />
          When he laid out the plan to his enforcers, he made sure everyone
          followed it to the letter and that Ratface would be unharmed. That was
          the most important thing, since he was only sending a message, not
          starting an all-out war within the Syndicate.
          <br />
          They would hit the 5 surrounding houses at once, taking out as many of
          Montana’s rats and guards, while the two captains of these elite
          squads would fire on the main safehouse, just to suppress anyone
          trying to fire back. Rekko knew that Ratface’s office and living room
          were completely armor plated, so he would be safe from the bullet
          storm hitting the rest of the house.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline pe-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={corpos1} alt="" />
            </div>
          </div>
          The teams were now in position. But before giving the go-ahead, Rekko
          took one last look at the main safehouse.
          <br />
          <br />
          He saw all of the Syndicate’s signature black cars parked in front,
          meaning that the place was swarming with rats. But one car stood out:
          a crimson muscle car, already famous to anyone familiar with the Night
          Riders’ weekly night races.
          <br />
          What was a top NRS member doing around Ratface’s inner circle? Then,
          the thought hit him: could this be The Agency’s man on the inside? The
          Twins’ mole inside the Agency (his mole to be exact) had mentioned
          that this undercover agent managed to gain Ratface’s trust quickly,
          but it didn’t say how. And it clearly wasn’t anyone who was already a
          member of the Syndicate since the reports were fresh, it had to be an
          outsider.
          <br />
          <br />
          Suddenly, Rekko thought of the Great Art Heist again and everything
          started to make sense: Ratface’s crew had used two precision drivers,
          unmatched by any of the agents pursuing them (or so the official
          reports said). It made sense to get someone from the Night Riders,
          some of the top guns, especially since Ratface had ties with Hotshot’s
          family. And it would also be easier for the Agency to infiltrate the
          NRS than going straight for the Syndicate.
          <br />
          <br />
          Rekko was quite pleased with himself for thinking of this, but was it
          true? He might be able to see soon enough. He was getting ready to
          start the attack, but first he wanted to call Ratface…
          <br />
          <br />
          <b style={{ fontSize: "1.2rem" }}>The Attack on Fortress</b>
          <br />
          <br />
          Meanwhile, inside the compound, Ratface was having a meeting with his
          lieutenants about the recent reports of friction between The
          Fashionistas and The Corpos. He didn’t like the possibility of
          upsetting the balance between Mice City’s factions. If anyone would be
          making any moves, he wanted that someone to be himself.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline ps-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={clubbing1} alt="" />
            </div>
          </div>
          In the room, Johnny H was waiting for Ratface to finish his meeting.
          They had another visit to make at Hotshot’s place to discuss some
          other move he didn’t know about. Johnny was starting to notice the
          Crime Syndicate boss getting more and more confident around him. Now
          he was even in the room while he briefed his inner circle. The hard
          work was finally paying off for the sly undercover agent.
          <br />
          The meeting was interrupted by Ratface’s personal phone. He picked it
          up when he saw who was calling:
          <br />
          <p className="dialog-font m-0 pt-1">
            Ratface: - Mr. Rekko, you rarely call us poor bottom rats directly!
            I’m joking of course, what can I help you with?
          </p>
          <p className="dialog-font m-0 pt-1 pb-1">
            Rekko: - Ever the sly one, aren’t you? Tell me, were you this
            chipper when you attacked my bank, killed my men and stole MY
            MONEY??
          </p>
          Ratface fell silent for a few moments, his rats immediately noticed
          his reaction
          <br />
          <p className="dialog-font m-0 pt-1">
            Ratface: - Whatever you may have heard, you need to be rational. We
            can have a sit-down and clear up any misunderstanding you may have.
          </p>
          <p className="dialog-font m-0 pt-1">
            Rekko: - Oh I’m rational my friend. You of all people should know
            that. Being rational and aware of everything going on in this town
            made me what I am, what we BOTH are… And I think it’s time you
            remembered the terms of our alliance, and the consequences for
            breaking them…
          </p>
          <p className="dialog-font m-0 pt-1 pb-1">
            As soon as Rekko hung up the call, Ratface’s enforcers started
            shouting on their radios: - Sir, there’s movement around every
            house! They’re spec ops sir!
          </p>
          The next moment gunfire erupted, from every side of the compound.
          Syndicate enforcers were firing back, the house was drenched in a hail
          of gunfire.
          <br />
          The windows were shattering, splinters flying everywhere, screaming
          mice on both sides. Once every few seconds, someone would shout: “Sir,
          they took us by surprise, three of the houses were wiped out!!! We
          can’t hold them fore…” silence…
          <br />
          <br />
          <div className="d-inline">
            <video className="w-100" loop autoPlay>
              <source src={gunfire} type="video/mp4" />
            </video>
          </div>
          Everyone was on the ground, trying to avoid the gunfire, which seemed
          to go on forever. Johnny grabbed Ratface by his arm and shouted at 4
          of his lieutenants:
          <br />
          <p className="dialog-font m-0">
            - My car’s right in front of the driveway! They seem to be focusing
            their forces on the surrounding houses. If we can clear a path to
            the car, I can get the boss out! Move, show him your loyalty!!!
          </p>
          <br />
          Ratface was surprised by the boy’s reaction. They all carefully moved
          through the house, keeping low. They entered the garage from inside
          the house, and his 4 rats opened fire on the few spec ops attacking
          the main house.
          <br />
          While the corpos were suppressed, Johnny H and Ratface jumped in his
          crimson muscle car, fired up the engine and floored it, squeezing
          straight through a narrow alley way just barely wide enough to fit the
          car. Ratface was once again astonished by the kid’s genius behind the
          wheel. And this time, that genius had saved his life…
          <br />
          <br />
          <b style={{ fontSize: "1.2rem" }}>Victory and Revelations</b>
          <br />
          <br />
          Rekko, sat back in his car while all the chaos was unfolding, and
          watched the red car drive away with Ratface. His suspicion was proved
          correct. It was the Crimson Kid taking Ratface to safety. Hotshot’s
          very own second in command. He was now almost certain that this was
          the Agency’s man.
          <br />
          <br />
          But he wanted a clear confirmation from his mole inside the Agency
          before using this new found information in any way.
          <br />
          <br />
          For now, he had to focus on what would come next, how would Ratface
          react to this. He had to prepare. So, he called off his men, and just
          as fast as the attack started, they had all cleared out and left any
          survivors to tend to their casualties.
          <br />
          <br />
          One thing was clear, Mice City was going to change forever…
        </span>
      </div>
      <div
        className="d-block h4 pt-4"
        style={{ fontWeight: 500, color: "#F6B0E1" }}
      >
        TO BE CONTINUED in future Story Crossroads events
      </div>
    </div>
  );
};
