import React from "react";
import corpos1 from "../../../../assets/images/factions/Corpo/ch3.webp";
import corpos2 from "../../../../assets/images/factions/Corpo/ch3..webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const CorposChapter3 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.3 - Deal with the Devil
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          So when it looked as though the CEO position was uncertain, Rekko cut
          a deal with Ratface to simply work his magic and help Pops into an
          early retirement.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${
                  isMobile ? "" : "ps-3 pe-3 d-inline"
                }`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={corpos1} alt="" />
              </div>
            </div>
          )}
          In exchange for full financial support for his new project involving
          every criminal family in town (the soon to be Crime Syndicate),
          Ratface agreed. But he did have one more condition: that Rekko would
          be present when took care of Pops. If they were going to do business
          together, Ratface wanted to know that Rekko wasn’t squeamish about the
          dirty deeds involved.
          <br />
          <br />
          Rekko didn’t skip a beat and agreed, surprising even Ratface with his
          callousness.
          <br />
          <br />
          Two days later he arranged to meet with Ratface at the Mice City
          docks. When he got there, Ratface and his men were already waiting
          with a confused Maury.When he saw Rekko, he asked him:
          <ul>
            <li>
              Rody, what’s going on? Who are these men and what do they want?{" "}
            </li>
            <li>
              Don’t call me that old man! I’m not one of the dumb employees you
              got working for you, which you can just sweet talk... I told you
              not to stand against me in the race for CEO.. but you had to be
              righteous! Now you’ll see just how serious I am about that!
            </li>
            <li>
              Please, it’s not too late to step away from this madness...
              please..
            </li>
          </ul>
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3 pe-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={corpos1} alt="" />
                <br />
                <br />
              </div>
            </div>
          )}
          Rodent Rekko said nothing, just staring at his old mentor, when
          Ratface stepped by his side and handed him a gun and said:
          <br />
          <br />
          <ul>
            <li>
              This part is not negotiable. I want you to pull the trigger. The
              cleanup is already set up: his body will be found in another town,
              with a *mistress* by his side, a paper trail for gambling debts
              already in place. I’ve taken care of everything, it won’t lead
              back to us.
            </li>
          </ul>
          <br />
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${
                  isMobile ? "" : "ps-3 pe-3 d-inline"
                }`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <img className="image-full-size" src={corpos2} alt="" />
              </div>
            </div>
          )}
          Maury was speechless, trembling and looking at Rekko, begging him with
          his eyes, but Rekko was staring at his shoes, thinking when he finally
          said:
          <br />
          <br />
          <div style={{ transform: isMobile ? "" : "translateX(19px)" }}>
            <ul>
              <li className="pe-2">
                As long as he doesn’t get any blood on my new shoes...
              </li>
            </ul>
          </div>
          He took the gun, made two steps back to protect his precious Asyago
          shoes from any stains, turned to Maury, aimed the gun at his terrified
          eyes and pulled the trigger . After a quick pop from the gun, Maury’s
          lifeless body was on the ground.
          <br />
          <br />
          Again, even Ratface was surprised by the lack of emotion this man
          showed, killing a defenseless old man who had been his mentor. He
          guessed that was to be expected of a egomaniacal CEO, but still
          surprising to him.
          <br />
          <br />
          Just like that his rival was gone, “sleeping with the kittens” as they
          say, and Rekko was now the CEO of Maustrap, and Ratface had secured a
          partnership with the most powerful businessman in town.
          <br />
          <br />
          This unholy partnership slowly evolved and, once the Crime Syndicate
          was fully formed, their combined might let them rule over every single
          inch of Mice City.
          <br />
          <br />
        </span>
      </div>
    </div>
  );
};
