export enum Menu {
  About,
  Factions,
  Specs,
  RoadMap,
  Rats,
  FAQs,
  Team,
}

export enum Factions {
  CrimeSyndicate = "crime",
  NighRiders = "night",
  Corpos = "Corpos",
  Agency = "agency",
  Clubbing = "clubbing",
}
