import React, { useState } from "react";
import crime1 from "../../assets/images/factions/Crime/CH1.webp";
import agency1 from "../../assets/images/factions/Agency/CH1.webp";
import smuggler from "../../assets/images/factions/Corpo/ch1.webp";
import { useDisplaySize } from "../../hooks/pageSizeHook";

export const SettingTheStage = () => {
  const isMobile = useDisplaySize();
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <div>
      <span className="d-block py-4 h4" style={{ fontWeight: 600 }}>
        Suspicion under pressure
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            The Agency was getting ready to move Rekko to their secure compound
            right outside Mice City. Axel Mousley knew very well that keeping
            the leader of the Corpos, one of the most powerful mice in town, in
            a regular police station would inevitably result in the remainder of
            his spec ops team breaking him out.
          </b>
          <br />
          <br />
          He was so close to completely shutting down one of most influential
          Factions in the criminal underworld, so nothing could be left to
          chance. So, the agents acted fast, putting together a convoy to escort
          and guard the prison bus transporting their “prize”.
          <br />
          The only thing Axel was worried about was the 20 miles of highway to
          the compound. They would be at their most vulnerable out in the open,
          but the escort convoy composed of agents with years of experience in
          defensive driving in high stakes situations.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
              style={{
                maxWidth: isMobile ? "80%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={agency1} alt="" />
            </div>
          </div>
          Johnny H knew exactly how the Agency would act while moving a high
          priority target to a secure location. In his early years at The
          Agency, he took part in several such scenarios. But explaining this to
          Ratface brought about unnecessary suspicion to his position in the
          Syndicate. Ratface immediately picked up on this:
          <br />
          <br />
          <p className="dialog-font m-0">
            Ratface: How would you know exactly how the Agency Task Force would
            act? How could you possibly know their exact route to their
            compound?
          </p>
          <br />
          Johnny knew he couldn’t make him let this go. But he also trusted
          Ratface to act on the more immediate danger.
          <br />
          <br />
          <p className="dialog-font m-0">
            Johnny: For now, let’s just say I have my own insight and pipeline
            inside some of The Agency’s inner workings. I know you’re owed a lot
            more explanations, but now is not the time. We have to act, sooner
            rather than later.
          </p>
          <br />
          Ratface was furious, although his calm and calculated demeanor didn’t
          betray any of his true emotions. To Johnny he only looked to be
          weighing the information.
          <br />
          <br />
          {showMore && (
            <>
              But the one thing Ratface hated the most in his closest mice was
              any sort of shady deals done behind his back. And a connection to
              the Agency wasn’t something to look past. But the kid was right,
              they had bigger fish to fry right now.
              <br />
              <br />
              If they didn’t break Rekko out, the Corpo empire could topple from
              within along with the iron grasp they had on the financial sector
              of Mice City. And the next domino piece to fall would inevitably
              be The Crime Syndicate, which still relied heavily on Corpo
              influence. Ratface would never allow this to happen.
              <br />
              <br />
              <p className="dialog-font m-0">
                Ratface: Then we act! But once it’s done, you lay all your cards
                on the table. This isn’t a request… Now tell me what you need to
                stop that convoy.
              </p>
              <br />
              <br />
              <span
                className="d-block pt-4 pb-2 h3"
                style={{ fontWeight: 600 }}
              >
                The Breakdown
              </span>
              Johnny and Hotshot already had a plan in motion. Ratface provided
              them with 4 modified high-performance sedans, as well as weapons
              to incapacitate the Agency’s vehicles.
              <br />
              <br />
              The cars Ratface got them could easily blend in as civilian
              vehicles on the highway, unlike the head-turning track monsters
              the Night Riders had at their disposal.
              <br />
              <br />
              The crew was set up of 10 mice: Hotshot, Johnny and 4 more high
              precision drivers from the Night Riders’ crew along with 4 Crime
              Syndicate enforcers.
              <br />
              <br />
              The plan was relatively straight forward:
              <br />
              The 4 black sedans would each be driven by a Night Rider member.
              Each car would have a Syndicate enforcer riding shotgun, armed
              with a high-powered crossbow attached to a titanium cable designed
              to be shot at the Agency vehicles’ wheels and incapacitate them.
              <br />
              <br />
              Hotshot and Johnny, driving Hotshot’s highly modified bright
              orange Japanese sports car, would be waiting further up the
              highway, near a large intersection, ready to swoop in once the
              convoy was out of the picture and the transport bus was
              vulnerable.
              <br />
              <br />
              <span
                className="d-block pt-4 pb-2 h3"
                style={{ fontWeight: 600 }}
              >
                The Takedown
              </span>
              The team got organized just in time. The 4 black sedans were 10
              miles from the compound.
              <br />
              <br />
              They were driving as slowly as possible on the highway without
              raising suspicion, spread around the lanes among other civilian
              cars.
              <br />
              <br />
              <div className="faction-shadow">
                <div
                  className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
                  style={{
                    maxWidth: isMobile ? "80%" : "30%",
                    float: isMobile ? "none" : "right",
                  }}
                >
                  <img className="image-full-size" src={smuggler} alt="" />
                </div>
              </div>
              The convoy was just now closing in on the crew. They immediately
              got ready.
              <br />
              <br />
              The prison transport bus was surrounded by 4 armored trucks, two
              guarding the front and two guarding the rear.
              <br />
              <br />
              As the convoy passed them, the Night Riders made their move. They
              floored it, the highway was bathed in the booming sound of their
              exhaust.
              <br />
              <br />
              Two Night Riders surrounded the two armored trucks guarding the
              rear and the other two sped up and did the same to the trucks
              guarding the front.
              <br />
              <br />
              Before the agents got a chance to react, the Syndicate enforcers
              leaned out the window and fired their crossbow at the Agency
              trucks’ wheels.
              <br />
              <br />
              The titanium cables attached to the bolt wrapped around the
              truck’s wheels, immediately locking their wheels, sending two
              trucks at the back screeching off into the highway’s barriers. Job
              done.
              <br />
              <br />
              The front crew weren’t as lucky. One of the Syndicate enforcers
              managed to fire on the lead truck, locking its wheels and sending
              it tumbling into a ditch where it burst into flames. The second
              truck, however, acted fast, and before the Night Rider crew
              managed to shoot its wheels, two agents popped out the windows and
              opened fire, killing the two mice in the car.
              <br />
              <br />
              <div className="faction-shadow">
                <div
                  className={`d-inline m-0 ${isMobile ? "" : "pe-3"}`}
                  style={{
                    maxWidth: isMobile ? "80%" : "30%",
                    float: isMobile ? "none" : "left",
                  }}
                >
                  <img className="image-full-size" src={crime1} alt="" />
                </div>
              </div>
              Then they turned their attention to the other black sedan near the
              front of the convoy.
              <br />
              <br />
              As soon as the agents opened fire, the NRS driver took evasive
              actions. The Syndicate enforcer wasn’t as lucky and got shot in a
              hail of bullets engulfing the car, so the driver had to pull out
              of the chase.
              <br />
              <br />
              That just left the 2 black sedans which had just cleared out the
              two trucks at the rear of the convoy.
              <br />
              <br />
              The intersection where Hotshot and Johnny were waiting was just up
              ahead, so they had to move fast. They quickly engaged the armored
              truck on both sides, trying to distract it while Hotshot moved in.
              <br />
              They tried to lock up the truck’s wheels with the crossbow but
              this time the truck was anticipating them, driving very
              aggressively.
              <br />
              All the while, the transport truck, carrying Rekko, tried to shove
              the two cars off the road.
              <br />
              <br />
              While the vehicular slaughter was in full force, Hotshot and
              Johnny’s roaring engine took center stage, immediately going
              alongside the transport truck while the two black sedans kept the
              armored truck distracted and unable to assist.
              <br />
              <br />
            </>
          )}
        </span>
      </div>
      <div
        className="vote-button m-auto my-4"
        style={{ width: "max-content" }}
        onClick={() => setShowMore(!showMore)}
      >
        <span>{showMore ? "Hide" : "Show more"}</span>
      </div>
    </div>
  );
};
