import React, { FC, useEffect, useRef, useState } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import banner from "../../../assets/storyCrossRoads/burning_mouse/festival_banner.jpg";
import { useDispatch } from "../../../context";
import axios from "axios";
import { referer } from "../../../config.devnet";
import { useGetAccountInfo, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { getNetworkConfig } from "@multiversx/sdk-dapp/utils";

type VoteType = {
  voteOption: string;
  didVote: boolean;
  hasMice: boolean;
  loadingVoteInfo: boolean;
  setVoted: any;
};

enum Options {
  "A" = "A",
  "B" = "B",
  "C" = "C",
}

const END_DATE = 1653246000000;

export const TheVoteII: FC<VoteType> = ({
  hasMice,
  didVote,
  setVoted,
  loadingVoteInfo,
  voteOption,
}) => {
  const isMobile = useDisplaySize();
  const [selected, setSelected] = useState(Options.A);
  const [isVoting, setIsVoting] = useState(false);
  const dispatch = useDispatch();
  const { address } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const { apiAddress } = getNetworkConfig();
  const newspaperRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  console.log(voteOption);

  useEffect(() => {
    setSelected(voteOption as Options);
  }, [voteOption]);

  const vote = async () => {
    let voteResponse;
    try {
      setIsVoting(true);
      const local_token = localStorage.getItem(`login.${address}`);
      if (local_token) {
        const jsonToken = JSON.parse(local_token);
        voteResponse = await axios.post(
          `${apiAddress}/micecity/voteStory/`,
          {
            title: "The Burning Mouse Festival",
            voteOption: selected,
            timestamp: Date.now(),
          },
          {
            headers: {
              Authorization: `Bearer ${jsonToken?.access_token}`,
              Referer: referer,
            },
          }
        );
      } else {
        openErrorModal();
      }
    } catch (e: any) {
      openErrorModal();
    }
    if (voteResponse && voteResponse.status === 201) {
      dispatch({
        type: "setInfoModal",
        infoModal: {
          openInfoModal: true,
          title: "Success",
          message: "Thank you for contributing to our story!",
          icon: "🐭🐭🐭",
          hasIcon: false,
        },
      });
      setVoted(true);
    } else {
      openErrorModal();
    }
    setIsVoting(false);
  };

  const openErrorModal = () => {
    dispatch({
      type: "setInfoModal",
      infoModal: {
        openInfoModal: true,
        title: "Error",
        message: "Something went wrong. Please try again!",
        icon: "⚠️",
        hasIcon: false,
      },
    });
  };

  const onVote = async () => {
    if (!isLoggedIn) {
      dispatch({
        type: "setGlobalModal",
        globalModal: { openGlobalModal: true },
      });
      return;
    }
    if (!hasMice) {
      dispatch({
        type: "setInfoModal",
        infoModal: {
          openInfoModal: true,
          title: "Mint required",
          message: (
            <span>
              You don&#39;t have mice in your wallet. Mint some from{" "}
              <a href="/">our home page</a>
            </span>
          ),
          icon: "🐭🐭🐭",
          hasIcon: false,
        },
      });
      return;
    }
    if (!didVote) {
      await vote();
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Vote
      </span>
      <div
        ref={newspaperRef}
        className={`${isMobile ? "w-100" : "w-100"}  ${
          isMobile ? "d-flex justify-content-center" : ""
        }`}
        style={{
          marginBottom: "4rem",
        }}
      >
        <img className="image-full-size" src={banner} alt="" />
      </div>
      {showMore && (
        <div className="d-flex align-items-center flex-column pb-4">
          <div
            className={`d-flex align-items-center pb-4 ${
              isMobile ? "flex-column" : ""
            }`}
            style={{ height: !isMobile ? "300px" : "" }}
          >
            <div
              className={`vote-box h-100 ${
                selected === Options.A ? "pressed" : ""
              } ${isMobile ? "mb-4" : "me-4"}`}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.A)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                A.
              </span>
              <span>
                The Kingpin of the 1%, Rodent Rekko, was seen sitting down at
                the VIP table with The Flyboys. We wonder if he intends to hire
                them for a private show (Rekko gets The Flyboys to spy for him,
                possibly leading him to Ratface’s involvement in The Great Art
                Heist)
              </span>
            </div>
            <div
              className={`vote-box h-100 ${
                selected === Options.B ? "pressed" : ""
              } `}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.B)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                B.
              </span>
              <span>
                The show’s as awesome as promised, but strangely there’s a few
                too many mean looking security mice around (The Flyboys inform
                The Agency about Rekko’s proposal. The agents attempt to bring
                him in and charge him blackmailing government officials)
              </span>
            </div>
          </div>
          <div
            className={`vote-box w-100 ${
              selected === Options.C ? "pressed" : ""
            } `}
            style={{ pointerEvents: didVote ? "none" : "auto" }}
            onClick={() => setSelected(Options.C)}
          >
            <span
              className="pb-3 d-block"
              style={{ fontSize: "20px", fontWeight: 600 }}
            >
              C.
            </span>
            <span>
              It seems Mr. Rekko’s going from one high profile meeting to
              another. This time he was seen discussing with the Neon Drive’s
              party patrons, The Twins. Although things may have not gone his
              way as he left quite in a bad mood. (The Twins refuse to go along
              with Rekko’s deal, now both are going to try to use The Flyboys
              and their Agency connections to get the upper hand)
            </span>
          </div>
        </div>
      )}
      <div
        className="vote-button m-auto my-4"
        style={{ width: "max-content" }}
        onClick={() => setShowMore(!showMore)}
      >
        <span>{showMore ? "Hide options" : "Show options"}</span>
      </div>
      {!didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          <span>
            Magazine will be back with more rad moments as the festival keeps
            rockin’ on!
          </span>
        </div>
      )}
      {END_DATE > Date.now() && (
        <div
          className={`vote-button mb-4 ${didVote ? "disabled" : ""}`}
          style={{ pointerEvents: loadingVoteInfo ? "none" : "auto" }}
          onClick={onVote}
        >
          {isVoting || loadingVoteInfo ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <span>
              {isLoggedIn ? (didVote ? "Voted" : "Vote") : "Login to vote"}
            </span>
          )}
        </div>
      )}
      {didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          <span>
            Magazine will be back with more rad moments as the festival keeps
            rockin’ on!
          </span>
        </div>
      )}
      <span
        className="d-block h4 text-center"
        style={{ fontWeight: "bolder", color: "#ED9AC8" }}
      >
        You have decided!
        <br /> And the winner is 🥁🥁🥁: Option C!
        <br />
        <br /> Stay tuned to find out how the story ends!
      </span>
    </div>
  );
};
