import React, { FC, useEffect, useRef, useState } from "react";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import banner from "../../assets/storyCrossRoads/highwayTakedown/vote_banner.jpg";
import "./CrossStories.scss";
import { useDispatch } from "../../context";
import axios from "axios";
import { END_DATE } from "./CrossStories";
import { referer } from "../../config.devnet";
import { useGetAccountInfo, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { getNetworkConfig } from "@multiversx/sdk-dapp/utils";

type VoteType = {
  voteOption: string;
  didVote: boolean;
  hasMice: boolean;
  loadingVoteInfo: boolean;
  setVoted: any;
};

enum Options {
  "A" = "A",
  "B" = "B",
  "C" = "C",
}

export const TheVote: FC<VoteType> = ({
  hasMice,
  didVote,
  setVoted,
  loadingVoteInfo,
  voteOption,
}) => {
  const isMobile = useDisplaySize();
  const [selected, setSelected] = useState(Options.A);
  const [isVoting, setIsVoting] = useState(false);
  const dispatch = useDispatch();
  const { address } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const { apiAddress } = getNetworkConfig();
  const newspaperRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  console.log(voteOption);

  useEffect(() => {
    setSelected(voteOption as Options);
  }, [voteOption]);

  const vote = async () => {
    let voteResponse;
    try {
      setIsVoting(true);
      const local_token = localStorage.getItem(`login.${address}`);
      if (local_token) {
        const jsonToken = JSON.parse(local_token);
        voteResponse = await axios.post(
          `${apiAddress}/micecity/voteStory`,
          {
            title: "Highway Takedown",
            voteOption: selected,
            timestamp: Date.now(),
          },
          {
            headers: {
              Authorization: `Bearer ${jsonToken?.access_token}`,
              Referer: referer,
            },
          }
        );
      } else {
        openErrorModal();
      }
    } catch (e: any) {
      openErrorModal();
    }
    if (voteResponse && voteResponse.status === 201) {
      dispatch({
        type: "setInfoModal",
        infoModal: {
          openInfoModal: true,
          title: "Success",
          message: "Thank you for contributing to our story!",
          icon: "🐭🐭🐭",
          hasIcon: false,
        },
      });
      setVoted(true);
    } else {
      openErrorModal();
    }
    setIsVoting(false);
  };

  const openErrorModal = () => {
    dispatch({
      type: "setInfoModal",
      infoModal: {
        openInfoModal: true,
        title: "Error",
        message: "Something went wrong. Please try again!",
        icon: "⚠️",
        hasIcon: false,
      },
    });
  };

  const onVote = async () => {
    if (!isLoggedIn) {
      dispatch({
        type: "setGlobalModal",
        globalModal: { openGlobalModal: true },
      });
      return;
    }
    if (!hasMice) {
      dispatch({
        type: "setInfoModal",
        infoModal: {
          openInfoModal: true,
          title: "Mint required",
          message: (
            <span>
              You don&#39;t have mice in your wallet. Mint some from{" "}
              <a href="/">our home page</a>
            </span>
          ),
          icon: "🐭🐭🐭",
          hasIcon: false,
        },
      });
      return;
    }
    if (!didVote) {
      await vote();
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Vote
      </span>
      <div
        ref={newspaperRef}
        className={`${isMobile ? "w-100" : "w-100"}  ${
          isMobile ? "d-flex justify-content-center" : ""
        }`}
        style={{
          marginBottom: "4rem",
        }}
      >
        <img className="image-full-size" src={banner} alt="" />
      </div>
      <b>
        With Hotshot behind the wheel, Johnny shot the hinges of the truck door
        with a high-powered shotgun, blowing it open.
        <br />
        As Hotshot kept the car steady at high speed, Johnny risked his own life
        and jumped straight into the truck through the opened door.
      </b>
      <br />

      {showMore && (
        <div className="d-flex align-items-center flex-column pb-4">
          <div
            className={`d-flex align-items-center pb-4 ${
              isMobile ? "flex-column" : ""
            }`}
            style={{ height: !isMobile ? "300px" : "" }}
          >
            <div
              className={`vote-box h-100 ${
                selected === Options.A ? "pressed" : ""
              } ${isMobile ? "mb-4" : "me-4"}`}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.A)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                A.
              </span>
              <span>
                Johnny subdues the driver and gets Rekko in Hotshot’s car.
                Unfortunately, during the battle with the armored truck, one of
                the crew’s black sedans crashes into the bus, leaving Johnny
                stranded as the rest of the crew escapes
              </span>
            </div>
            <div
              className={`vote-box h-100 ${
                selected === Options.B ? "pressed" : ""
              } `}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.B)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                B.
              </span>
              <span>
                The armored truck takes out both of the remaining black sedans
                driven by the Night Riders and opens fire on Hotshot’s car,
                forcing him to pull away. This leaves Johnny captured, Rekko
                still in the Agency’s grasp, with Axel Mousley on his way to the
                scene
              </span>
            </div>
          </div>
          <div
            className={`vote-box w-100 ${
              selected === Options.C ? "pressed" : ""
            } `}
            style={{ pointerEvents: didVote ? "none" : "auto" }}
            onClick={() => setSelected(Options.C)}
          >
            <span
              className="pb-3 d-block"
              style={{ fontSize: "20px", fontWeight: 600 }}
            >
              C.
            </span>
            <span>
              The crew manages to take out the final armored truck. The crew
              moves Rekko and Hotshot into one of the black cars and drives to
              the safehouse. Johnny takes Hotshot’s bright orange sports car and
              leads the Agency’s back-up team away from the escaping crew
            </span>
          </div>
        </div>
      )}

      <div
        className="vote-button m-auto my-4"
        style={{ width: "max-content" }}
        onClick={() => setShowMore(!showMore)}
      >
        <span>{showMore ? "Hide options" : "Show options"}</span>
      </div>

      {!didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          {/*<span>Tune in next week and find out how the clash unfolds!</span>*/}
        </div>
      )}
      {END_DATE > Date.now() && (
        <div
          className={`vote-button mb-4 ${didVote ? "disabled" : ""}`}
          style={{ pointerEvents: loadingVoteInfo ? "none" : "auto" }}
          onClick={onVote}
        >
          {isVoting || loadingVoteInfo ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <span>
              {isLoggedIn ? (didVote ? "Voted" : "Vote") : "Login to vote"}
            </span>
          )}
        </div>
      )}
      {didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          <span>
            <b>Tune in next week and find out how the clash unfolds!!</b>
          </span>
        </div>
      )}
    </div>
  );
};
