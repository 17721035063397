import { GlobalModalType, InfoModalType, StateType } from "./state";

export type ActionType =
  | {
      type: "setGlobalModal";
      globalModal: GlobalModalType;
    }
  | {
      type: "setLastSessionId";
      lastSessionId: string | null;
    }
  | {
      type: "setInfoModal";
      infoModal: InfoModalType;
    };

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case "setGlobalModal": {
      return {
        ...state,
        globalModal: { ...action.globalModal },
      };
    }
    case "setLastSessionId": {
      return {
        ...state,
        lastSessionId: action.lastSessionId,
      };
    }
    case "setInfoModal": {
      return {
        ...state,
        infoModal: { ...action.infoModal },
      };
    }
    default: {
      throw new Error("Unhandled action type");
    }
  }
}
