import React, { FC, useEffect } from "react";

import { useDisplaySize } from "../../hooks/pageSizeHook";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import "./GlobalModal.scss";
import { useContext, useDispatch } from "../../context";
import axios from "axios";
import { referer } from "../../config.devnet";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { getIsLoggedIn, getNetworkConfig } from "@multiversx/sdk-dapp/utils";
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton,
} from "@multiversx/sdk-dapp/UI";

type GlobalModalType = {
  isOpened: boolean;
  setIsOpened: any;
};

const GlobalModal: FC<GlobalModalType> = ({ isOpened, setIsOpened }) => {
  const { isLoggedIn } = useGetLoginInfo();
  const isMobile = useDisplaySize();
  const { apiAddress } = getNetworkConfig();
  const [token, setToken] = React.useState<string>("");
  const { globalModal } = useContext();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  });

  useEffect(() => {
    if (isLoggedIn) {
      closeModal();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const fn = async () => {
      const { data } = await axios.post(
        `${apiAddress}/init`,
        {},
        {
          headers: {
            Referer: referer,
          },
        }
      );
      setToken(data.loginToken);
      localStorage.setItem("loginToken", data.loginToken);
    };
    const LoggedIn = getIsLoggedIn();
    if (LoggedIn && isOpened) {
      window.location.href = "/";
    } else {
      fn();
    }
  }, []);

  const closeModal = () => {
    if (isOpened) {
      dispatch({
        type: "setGlobalModal",
        globalModal: { openGlobalModal: false },
      });
      setIsOpened(false);
    }
  };

  return (
    <MDBModal
      tabIndex="-1"
      show={globalModal.openGlobalModal}
      setShow={setIsOpened}
      staticBackdrop={true}
    >
      <div className="box-shadow" style={{ transform: "translateY(50%)" }}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="font-retro">Login</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={closeModal} />
            </MDBModalHeader>
            <MDBModalBody className="px-5">
              <div className="d-flex align-items-center flex-column py-4">
                <h5 className="mb-4 font-retro">Pick a login method</h5>
                <div className={isMobile ? "text-center" : "d-flex"}>
                  <div className="button-shadow">
                    <ExtensionLoginButton loginButtonText={"Extension"} />
                  </div>
                  <div className="button-shadow">
                    <WebWalletLoginButton
                      callbackRoute={window.location.pathname}
                      loginButtonText={"Web Wallet"}
                      className={"no-wrap"}
                    />
                  </div>
                  <div className="button-shadow">
                    <LedgerLoginButton
                      loginButtonText={"Ledger"}
                      callbackRoute={window.location.pathname}
                    />
                  </div>
                  <div className="button-shadow">
                    <WalletConnectLoginButton
                      loginButtonText={"xPortal"}
                      isWalletConnectV2={true}
                    />
                  </div>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <div className="button-shadow-secondary">
                <MDBBtn color="secondary" onClick={closeModal}>
                  Close
                </MDBBtn>
              </div>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </div>
    </MDBModal>
  );
};
export default GlobalModal;
