import React, { FC, useEffect, useState } from "react";
import { MobileBanner } from "./mobileBanner";
import { DesktopBanner } from "./desktopBanner";
import { SpecsBanner } from "./banners/SpecsBanner";
import { Menu } from "../../model/Main";
import { FactionsBanner } from "./banners/FactionsBanner";
import { WelcomeBanner } from "./banners/WelcomeBanner";
import { RoadmapBanner } from "./banners/RoadmapBanner";
import { SewerRatsBanner } from "./banners/SewerRatsBanner";
import { TeamBanner } from "./banners/TeamBanner";
import { FaqBanner } from "./banners/FaqBanner";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import {
  Account,
  Address,
  BigUIntValue,
  BytesValue,
  ContractFunction,
  TokenPayment,
  Transaction,
  TransactionPayload,
  U64Value,
} from "@elrondnetwork/erdjs/out";
import { buysPerWallet } from "../../services/TrustSCService";
import BigNumber from "bignumber.js";
import { useContext, useDispatch } from "../../context";
import { VoteBanner } from "./banners/VoteBanner";
import ReactGA from "react-ga";
import { getTokenBalance } from "../../hooks/tokenBalances";
import {
  useGetAccountInfo,
  useGetLoginInfo,
  useTrackTransactionStatus,
} from "@multiversx/sdk-dapp/hooks";
import { denominate, getChainID } from "@multiversx/sdk-dapp/utils";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { getStages } from "../../services/mintConvertService";

type HomeType = {
  selectedMenu: any;
};

// const collectionInfo = {
//   tag: "MiceCityClub",
// };
//
// export interface LockedMexType {
//   collection: string;
//   nonce: number;
//   balance: BigNumber;
// }

export const Home: FC<HomeType> = ({ selectedMenu }) => {
  const isMobile = useDisplaySize();
  const { address, account } = useGetAccountInfo();
  const [paymentToken, setPaymentToken] = React.useState<any>({
    text: "EGLD",
  });
  const [listing, setListing] = useState<any>();
  const [buysCount, setBuysCount] = useState<any>();
  const chainId = getChainID();
  const [userSC, setUserSC] = React.useState<string>("");
  const { isLoggedIn } = useGetLoginInfo();
  const dispatch = useDispatch();
  const { lastSessionId } = useContext();

  useTrackTransactionStatus({
    transactionId: lastSessionId ? lastSessionId : "",
    onSuccess: async () => {
      dispatch({
        type: "setInfoModal",
        infoModal: {
          openInfoModal: true,
          title: "Congrats",
          message: "Mice are on the way. Please check your wallet!",
          icon: "🐭🐭🐭",
          hasIcon: false,
        },
      });
    },
  });

  React.useEffect(() => {
    const fn = async () => {
      try {
        const contract =
          "erd1qqqqqqqqqqqqqpgq2t6ef4u9ts3j86504sx0zlvw0vujfq6yys5sqfg40f";
        if (contract) {
          // const result = await getListingsFromSC(contract);
          const collectionInfo = await getStages(contract);
          setUserSC(contract);
          setListing(collectionInfo[0]);
        }
      } catch (e: any) {
        console.log("error");
        console.log(e);
        dispatch({
          type: "setInfoModal",
          infoModal: {
            openInfoModal: true,
            title: "Careful",
            message: "Unable to get Collection data. Please reload the page!",
            icon: "⚠️",
            hasIcon: false,
          },
        });
      }
    };

    fn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fn = async () => {
      try {
        if (address && listing) {
          try {
            const buysCo = await buysPerWallet(
              listing.tag.toString(),
              userSC,
              address
            );
            setBuysCount(buysCo);
          } catch (error) {
            setBuysCount(null);
          }
        } else {
          setBuysCount(null);
        }
      } catch (e: any) {
        dispatch({
          type: "setInfoModal",
          infoModal: {
            openInfoModal: true,
            title: "Careful",
            message: "Unable to get mint data. Please reload the page!",
            icon: "⚠️",
            hasIcon: false,
          },
        });
      }
    };

    fn();
  }, [listing, address]);

  const mintNft = async (e: any, quantity: any) => {
    e.preventDefault();
    //TODO use service
    if (!isLoggedIn) {
      dispatch({
        type: "setGlobalModal",
        globalModal: { openGlobalModal: true },
      });
      return;
    }

    ReactGA.event({
      category: "user",
      action: "mint",
      label: "mint label",
    });

    let paymentPrice = listing.prices
      .filter(
        (price: any) => price.token_identifier.toString() === paymentToken.text
      )[0]
      .amount.toString();
    if (paymentToken.text.toString().includes("USDC")) {
      paymentPrice = denominate({
        input: paymentPrice,
        denomination: 12,
      });
    }
    const accountBalance = account.balance ?? 0;
    if (paymentToken.text === "EGLD") {
      if (
        new BigNumber(accountBalance).lt(
          TokenPayment.egldFromBigInteger(paymentPrice)
            .valueOf()
            .times(quantity)
        )
      ) {
        dispatch({
          type: "setInfoModal",
          infoModal: {
            openInfoModal: true,
            title: "Careful",
            message: "You don't have enough money in your wallet!",
            icon: "🧀",
            hasIcon: false,
          },
        });
        return;
      }
      const bidSC = new ContractFunction("buy");
      const payload = TransactionPayload.contractCall()
        .setFunction(bidSC)
        .setArgs([
          BytesValue.fromUTF8(listing.tag.toString()),
          BytesValue.fromUTF8(listing.tag.toString()),
          new U64Value(new BigNumber(quantity)),
        ])
        .build();
      //see if should be used for nonce
      // const provider = new ProxyNetworkProvider(gateway, {
      //   timeout: 100000,
      // });
      const senderAccount = new Account(new Address(address));
      const sendTX = new Transaction({
        receiver: new Address(userSC),
        nonce: senderAccount.nonce,
        sender: senderAccount.address,
        data: payload,
        value: TokenPayment.egldFromBigInteger(paymentPrice)
          .valueOf()
          .times(quantity),
        gasLimit: 35000000 + 6000000 * quantity,
        chainID: chainId,
      });
      const { sessionId } = await sendTransactions({
        transactions: sendTX,
        transactionsDisplayInfo: {
          processingMessage: "Minting your mouse",
          errorMessage: "An error has occurred during minting!",
          successMessage: "Mint successful",
          transactionDuration: 30000,
        },
      });
      dispatch({ type: "setLastSessionId", lastSessionId: sessionId });
    } else {
      const balance = await getTokenBalance(address.toString(), [
        paymentToken.text,
      ]);
      const balanceReadable = new BigNumber(
        balance[paymentToken.text]
      ).toNumber();

      if (
        balanceReadable <
        parseInt(denominate({ input: (paymentPrice * quantity).toString() }))
      ) {
        dispatch({
          type: "setInfoModal",
          infoModal: {
            openInfoModal: true,
            title: "Careful",
            message: "You don't have enough money in your wallet!",
            icon: "🧀",
            hasIcon: false,
          },
        });
        return;
      }
      const bidSC = new ContractFunction("ESDTTransfer");
      const payload = TransactionPayload.contractCall()
        .setFunction(bidSC)
        .setArgs([
          BytesValue.fromUTF8(paymentToken.text),
          new BigUIntValue(
            TokenPayment.fungibleFromAmount(
              paymentToken.text,
              new BigNumber(paymentPrice).times(quantity),
              0
            ).valueOf()
          ),
          BytesValue.fromUTF8("buy"),
          BytesValue.fromUTF8("MiceCity"),
          new U64Value(new BigNumber(quantity)),
        ])
        .build();
      // const syncAccount = await getNetworkProxy().getAccount(
      //   new Address(address)
      // );
      const senderAccount = new Account(new Address(address));
      const sendTX = new Transaction({
        receiver: new Address(userSC),
        nonce: senderAccount.nonce,
        sender: senderAccount.address,
        data: payload,
        value: "0",
        gasLimit: 35000000 + 6000000 * quantity,
        chainID: chainId,
      });
      const { sessionId } = await sendTransactions({
        transactions: sendTX,
        transactionsDisplayInfo: {
          processingMessage: "Mint in process",
          errorMessage: "An error has occurred during minting!",
          successMessage: "Mint successful",
          transactionDuration: 30000,
        },
      });
      dispatch({ type: "setLastSessionId", lastSessionId: sessionId });
    }
  };

  return (
    <>
      <SewerRatsBanner scroll={selectedMenu === Menu.Rats} />
      {isMobile ? (
        <MobileBanner
          mint={mintNft}
          listing={listing}
          buysCount={buysCount}
          paymentToken={paymentToken.text}
          setPaymentToken={setPaymentToken}
        />
      ) : (
        <DesktopBanner
          mint={mintNft}
          listing={listing}
          buysCount={buysCount}
          paymentToken={paymentToken.text}
          setPaymentToken={setPaymentToken}
        />
      )}
      <VoteBanner />
      <SpecsBanner scroll={selectedMenu === Menu.Specs} />
      <FactionsBanner scroll={selectedMenu === Menu.Factions} />
      <WelcomeBanner />
      <RoadmapBanner scroll={selectedMenu === Menu.RoadMap} />
      <TeamBanner scroll={selectedMenu === Menu.Team} />
      <FaqBanner scroll={selectedMenu === Menu.FAQs} />
    </>
  );
};
