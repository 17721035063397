import React from "react";
import crime1 from "../../../../assets/images/factions/Crime/ch3.webp";
import crime3 from "../../../../assets/images/factions/Crime/ch3_.webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const SyndicateChapter3 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">Chapter.3 - Misumoto’s Mistake</h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          Leo had only one more crime family to convince - the Yakuza, an
          honor-bound society, with deep rooted traditions in Japanese culture.
          <br />
          <br />
          The only problem was their unpredictable leader, Misumoto, who started
          to abandon the old ways, looked at only securing his position as
          leader, even going so far as sending his sadistic young brother to
          murder members of his own crew if he thought they were not dedicated
          enough. Tensions were high within the organization, Misumoto’s
          lieutenants were fed up, but tradition forbade them to move against
          their leader. One of the lieutenants, Rakagashira - the oldest and
          wisest of Misumoto’s clan, saw a solution in Ratface’s new proposal,
          which was making waves around Mice City.
          <div className="faction-shadow">
            <div
              className={`pb-3 pt-3 m-0 ${
                isMobile ? "" : "ps-3 pe-3 d-inline"
              }`}
              style={{
                maxWidth: isMobile ? "100%" : "37%",
                float: isMobile ? "none" : "right",
              }}
            >
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}
              <img className="image-full-size" src={crime3} alt="" />
            </div>
          </div>
          <br />
          <br />
          So he did all he could to convince Misumoto to sit down with Ratface’s
          emissary. Maybe, just maybe, the prospect of peace and wealth would
          stave off Misumoto’s bloodlust - or so Rakagashira thought. And so the
          meeting took place.
          <br />
          <br />
          So Ratface was waiting for word from his friend Leo on how the final
          talk went. By nightfall he got his answer. A box was sent from
          Misumoto to his house with a note on it:
          <br />
          <br />
          “Unwashed southern rats have no place in my house, but this may look
          nice on your desk”
          <br />
          <br />
          He opened the boxed and fell to his knees in shock, still holding
          Misumoto’s “gift”. He stared inside the box, into Leo’s bloody
          lifeless eyes. His childhood friend, his brother, taken from him
          because of one egotistical mouse’s insanity.
          <br />
          <br />
          Ratface’s crew tried to talk to him but he was speechless, still on
          his knees, tears in his eyes, until finally he turned to his loyal
          mice and said only one thing: “Bring him to me!”
          <br />
          <br />
          Over the next few days Ratface focused the cartel’s entire resources
          to capturing Misumoto and his little brother. He got a message
          informing him about the Yakuza boss’s location - Rakagashira had had
          enough of his sadistic bloodlust bringing dishonor to their
          organization, so he gave up his master to Ratface.
          <br />
          <br />
          A few days later, Ratface’s mice managed to get Misumoto and his
          brother while coming out of a restaurant and brought him to Ratface’s
          house, in the very office where he opened the box with Leo’s head.
          <br />
          <br />
          And here, Ratface’s ruthlessness came out. He put a rope around
          Misumoto’s neck, tied the other end to his heavy solid wood desk and
          tied his hands. A few steps in front of Misumoto he placed a chair
          where he tied his little brother. And he made Misumoto watch while
          Ratface’s enforcers savagely beat his little brother for 10 minutes,
          all while Misumoto struggled and screamed to let him go. After they
          were done, Misumoto’s neck was bloody from the rope tightening around
          his neck, and his brother was barely breathing.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`pb-3 pt-3 m-0 ${
                isMobile ? "" : "ps-3 pe-3 d-inline"
              }`}
              style={{
                maxWidth: isMobile ? "100%" : "37%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={crime1} alt="" />
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}
            </div>
          </div>
          But the worst was yet to come for him. Ratface leaned in close to
          Misumoto and said: “You took my brother, now you’ll watch me take
          yours”.
          <br />
          <br />
          He grabbed another rope, tied it around Misumoto’s barely conscious
          little brother and passed it through a pipe running across his office
          ceiling. He then asked his enforcers to pull the rope and, slowly,
          they started lifting Misumoto’s brother by the neck , hanging him in
          front of the Yakuza boss.
          <br />
          <br />
          In his desperation, Misumoto struggled to reach his brother, pulling
          more on more viciously on the rope around his neck, trying all he
          could to save his only brother. But it was all useless, the harder he
          pulled, the tighter the rope got around his neck, slowly squeezing the
          life out of him. Soon, both Misumoto and his brother were dead,
          strangled.
          <br />
          <br />
          Ratface had done it. He got the egotistical Yakuza boss to kill
          himself while watching his own brother get hanged a few feet away from
          his reach.
          <br />
          <br />
          The tale of Ratface’s vengeance spread around all four of the crime
          families. Now they all knew who they were dealing with and to what
          lengths Manny Ratface Montana would go to, if he was pushed hard
          enough.
          <br />
          <br />
          The Yakuza’s new boss understood this better than most, since he was
          the one who helped Ratface in his vengeance.
          <br />
          <br />
          Rakagashira was the one unanimously chosen to represent the Yakuza
          into this new era - wisdom over foolishness. He gladly accepted
          Ratface’s proposal for cooperation between the families. After all, it
          was something he had desired for years under the bloody leadership of
          Misumoto.
          <br />
          <br />
          Soon, all four families started doing business with each other and
          sharing the wealth. Now they saw Ratface’s plan was more than just an
          optimist’s dream. And they started looking to him as their true
          leader, liked and respected by all, not to mention feared.
          <br />
          <br />
        </span>
      </div>
    </div>
  );
};
