import React, { FC } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import agency from "../../../assets/images/factions/Agency/CH1.webp";
import agency2 from "../../../assets/images/factions/Agency/Ch2.webp";
import riders2 from "../../../assets/images/factions/Night Riders/ch2 & ch4.webp";
import riders3 from "../../../assets/images/factions/Night Riders/ch2_.webp";

type TheOutcomeType = {
  ref: any;
};

export const TheOutcomeI: FC<TheOutcomeType> = ({ ref }) => {
  const isMobile = useDisplaySize();
  return (
    <div ref={ref}>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Outcome
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            Johnny couldn’t believe how fast and efficient Ratface’s mice had
            been in subduing the entire bank. It all felt to him like watching a
            movie. He still couldn’t believe he was actually going through with
            this – a heist , working alongside the enemy – The Crime Syndicate.
          </b>
          <br />
          <br />
          But right now he couldn’t think about this… Right now he was Johnny
          “The Crimson Kid”, not Johnny the agent. He would deal with the
          fallback when the time came. They all entered the bank on foot,
          heavily armed and took the bank by storm. With a few warning shots and
          threats they got control of the entire bank in just 120 seconds.
          Exactly as planned, like clockwork.
          <br />
          <br />
          The 6 mice divided the bank into three sections coordinated by two
          mice each. They didn’t use any actual names, only codenames: Rat 1, 2,
          ...6 and of course Driver 1 (Hotshot) and Driver 2 (Johnny).
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline ps-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={riders2} alt="" />
            </div>
          </div>
          TRat 1 & Rat 2 handled the hostages. They were all moved near the
          entrance, to act as a makeshift buffer between them and agents which
          would inevitably show up. Rat 3 & Rat 4 secured and tied up the bank
          tellers, bank manager and most importantly the guards. They moved them
          against the wall at the back of the bank. And of course Rat 5 & Rat 6
          would handle the main attraction – getting the two paintings out of
          their highly reinforced glass casing in the middle of the lobby.
          <br />
          <br />
          As soon as they started cutting the glass casing, an alarm would go
          off. From this point on they only had 3 more minutes until The Agency
          would surround the entire bank. As soon as they got the paintings out
          of the casing and into their briefcases, they moved to the next stage
          of the plan – the most dangerous part.
          <br />
          <br />
          The team took the emergency exit downstairs to the underground parking
          lot were 2 matte black and highly modified sedans were waiting for
          them. Ratface got his contacts to smuggle them into the parking lot
          one day earlier - these were the perks of working for the most
          powerful criminal organization in the country.
          <br />
          <br />
          Johnny was starting to see how The Crime Syndicate had gotten so
          strong and untouchable. They were like a well oiled machine, a
          professional criminal elite organized into a veritable corporate-like
          giant empire.
          <br />
          <br />
          Now they had to get out and face the onslaught of gunfire that awaited
          them outside and the relentless agents that would pursue them as soon
          as they drove away. This is where the 2 precision drivers come into
          play.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline pe-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={riders3} alt="" />
            </div>
          </div>
          First they needed a breach in the blockade, so the 6 Rats approached
          the exit ramp to the parking lot, armed with heavy duty machine guns
          and body armor, and rained hell on the agents waiting outside.
          Meanwhile, Johnny and Hotshot each got in their car and waited for the
          signal to go.
          <br />
          <br />
          Johnny knew the procedure in these situations: all agents would return
          fire from cover, as a distraction, never exposing themselves, and
          mostly relying on snipers in nearby buildings to take the kill shots.
          <br />
          <br />
          He hoped that would mean no agent would get hit. He kept telling
          himself he was doing the right thing, the only way to get in deeper
          and closer to Ratface. The only way to help out Hotshot, a good man
          forced into this situation for the sake of his crew. Mousley had to
          understand... After all, it was why he chose him for this undercover
          assignment: to make the tough calls when the time came.
          <br />
          <br />
          Like a sudden jolt, Johnny was snapped out of his momentary
          contemplation by two of the Rats screaming at them to get ready. One
          of the snipers had gotten a lucky shot, hitting one of Ratface’s mice
          between his armor plating.
          <br />
          They were no longer an effective unit, so they had to move and adapt –
          again Johnny thought: professionals through and through, working like
          a Swiss watch.
          <br />
          <br />
          They drove up the exit ramp, stopped so the gunmen would get in, 3 in
          each car. They got in 1 by 1, constantly providing cover fire until
          their injured partner was inside.
          <br />
          With everyone in the cars, stage 3 was ready to go – The Escape.
          <br />
          <br />
          Johnny and Hotshot floored it and drove straight for a section of the
          blockade where Ratface’s mice focused their firepower, so the agents
          on the ground had to disperse. This gave them the perfect place to
          smash through with their armor plated cars.
          <br />
          Hotshot was the lead car, with Johnny close behind. They went straight
          for the blockade, with everyone bracing for the crash. The violent
          crash knocked everyone around, but the cars held up – just as planned.
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline ps-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={agency} alt="" />
            </div>
          </div>
          They were now through and heading for Neon Drive, the fastest way out
          of town to the safehouse, with 6 police cruisers on their tail.
          <br />
          When Johnny looked in this rear view mirror he couldn’t believe his
          eyes. Axel Mousley’s gray police interceptor was among the pursuing
          force.
          <br />
          <br />
          He felt hands tightening up around the steering wheel. Axel must have
          been called out when The Agency was informed that the robbery was
          targeting a bank full of hostages since he was their best negotiator.
          <br />
          <br />
          Now he must have caught sight of Johnny as they were getting out of
          the parking lot. He had no idea how to get in contact with him at this
          point, to explain his plan. But no time for that now.
          <br />
          Hotshot cut through an underpass, with Johnny close behind. Now they
          were on the Neon Drive, with just 4 cruisers left in hot pursuit,
          including Mousley.
          <br />
          <br />
          Axel couldn’t believe his eyes! As soon as he recognized Johnny behind
          the wheel of the car on the parking lot exit ramp, he jumped straight
          in his car to join the chase. He had no idea what Johnny was thinking.
          Did he flip on The Agency? Did he fall for the Night Riders lifestyle?
          Or was he playing the long game and trying an insane move to go deeper
          undercover?
          <br />
          Whatever the reason, he had to be the one to bring him in, if he got
          caught. Axel was the only one who could identify Johnny as an agent if
          they got taken down.
          <br />
          <br />
          The two matte black cars were almost at the end of the Neon Drive,
          when two SWAT trucks came out of the side streets and blocked the way.
          Hotshot and Johnny slammed on the brakes and the cars ground to a
          halt, side by side.
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline pe-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={agency2} alt="" />
            </div>
          </div>
          <br />
          Mousley and 4 police cruisers blocking them from the rear and the SWAT
          trucks blocking the way forward. They were busted. Unless…
          <br />
          <br />
          Johnny saw an entrance to a strip mall on the left side of the
          boulevard. Without thinking, he floored the gas, turned left and
          smashed straight through the glass door of the mall. Hotshot was
          stunned, but quickly followed him.
          <br />
          They both plowed through the mall, smashing every stand in their way
          and eventually crashed right through the exit doors on the other side
          of the building, going straight into the connecting streets on the
          other side of the mall.
          <br />
          <br />
          They were free… before the agents could act, they were already out of
          sight, riding for the safehouse.
          <br />
          Hotshot pulled alongside Johnny’s car, with a big grin on his face. He
          gave him a nod of approval. Johnny was ecstatic. He couldn’t believe
          that move actually worked.
          <br />
          <br />
          One hour later, after ditching the getaway cars for an old van, they
          pulled in the front of the safehouse, just outside Mice City.
          <br />
          Once they walked through the door, to their surprise, Ratface was
          waiting for them, relaxing on the couch, smoking a cigar with a glass
          of whiskey in hand. As soon as he saw the cases holding the two
          paintings, he looked at Hotshot and gave him a quick smile.
          <br />
          <br />
          R: - You see Mickey, I knew you were the man to get this done. You
          just needed the proper motivation
          <br />
          H: - You have the kid to thank for that. It was his crazy idea and
          insane drive that got us out
          <br />
          R: - Well then, I’m not beneath giving my thanks to those who deserve
          it. And anyone in this city knows, my regards are hard to come by. So
          what’s your name kid?
          <br />
          <br />
          Johnny was stunned, he was face to face with the biggest kingpin in
          the world, the most powerful mouse in Mice City - Manny Ratface
          Montana.
          <br />
          This was it, the moment he risked it all for. Johnny shook his hand
          and said: “Johnny sir, it’s an honor the meet you in the flesh”
          <br />
          <br />
        </span>
      </div>
      <div
        className="d-block h4 pt-4"
        style={{ fontWeight: 500, color: "#F6B0E1" }}
      >
        TO BE CONTINUED in future Story Crossroads events
      </div>
    </div>
  );
};
