// import { allTokensMap, ElrondAPI, network } from "@config";
// import { useContext } from "@context";
// import { BalanceType, LockedMexType } from "@context/state";

// import { getUserNFTs } from "@helpers/asyncRequests";
import axios from "axios";
import BigNumber from "bignumber.js";
import { ElrondAPI } from "../config.devnet";
// import { IToken } from "components_new/FiltersMenu/types";
// import { getToken } from "./getToken";

// const findTokenTickerByText = (text: string) => {
//   return (
//     Object.keys(allTokensMap).find((key) => {
//       const value = getToken(key, allTokensMap);
//       return value.text === text;
//     }) ?? ""
//   );
// };
//
// export const findTokenDecimalsByTicker = (text: any) => {
//   if (text in allTokensMap) {
//     if ("decimals" in allTokensMap[text as IToken]) {
//       return (allTokensMap[text as IToken] as any).decimals;
//     } else {
//       return 18;
//     }
//   } else {
//     return 18;
//   }
// };

export const getTokenBalance = async (
  address: string,
  tokens: Array<string>
) => {
  try {
    const { data } = await axios.get(
      `${ElrondAPI}/accounts/${address}/tokens`,
      {
        params: {
          identifiers: tokens.join(","),
        },
        timeout: 15000,
      }
    );
    const result: any = {};
    data.forEach((tokenInfo: any) => {
      tokenInfo.balance = new BigNumber(tokenInfo.balance).shiftedBy(
        -tokenInfo.decimals
      );
      result[tokenInfo.identifier] = tokenInfo.balance;
    });
    return result;
  } catch (err) {
    return [];
  }
};

// export const getAllBalances = async ({
//   account,
//   dispatch,
// }: {
//   account: AccountType;
//   dispatch: any;
// }): Promise<void> => {
//   if (!account) return;
//
//   const { address, balance } = account;
//   if (!address) return;
//
//   const balances = {
//     EGLD: new BigNumber(balance).shiftedBy(-18),
//     ...(await getTokenBalance(address)),
//   };
//
//   dispatch({
//     type: "setBalances",
//     balances,
//   });
// };

// export type LockedMexReturnType = {
//   hasEnoughLKMEX: boolean;
//   tokenDetails: LockedMexType;
// };

// export const useHasEnough = () => {
//   const { balances } = useContext();
//   return function (
//     ticker: keyof BalanceType,
//     amount: BigNumber,
//     extraTicker?: keyof BalanceType,
//   ): LockedMexReturnType | boolean {
//     const balance = balances[ticker];
//     let extraBalance: BigNumber | LockedMexType = new BigNumber(0);
//     let extraDecimals: any;
//     if (extraTicker) {
//       extraBalance = balances[extraTicker];
//       extraDecimals = findTokenDecimalsByTicker(extraTicker);
//     }
//     const decimals = findTokenDecimalsByTicker(ticker);
//     if (!balance) {
//       if (extraTicker && extraBalance && !("balance" in extraBalance)) {
//         return {
//           hasEnoughLKMEX: extraBalance.gte(
//             new BigNumber(amount).shiftedBy(-extraDecimals),
//           ),
//           tokenDetails: {
//             collection: extraTicker,
//             nonce: 0,
//             balance: extraBalance,
//           },
//         };
//       }
//       return false;
//     }
//     if ("balance" in balance) {
//       return {
//         hasEnoughLKMEX: new BigNumber(
//           (balance as unknown as LockedMexType).balance,
//         )
//           .shiftedBy(decimals)
//           .gte(amount),
//         tokenDetails: balance as unknown as LockedMexType,
//       };
//     }
//     const hasMainMoney = balance.gte(
//       new BigNumber(amount).shiftedBy(-decimals),
//     );
//     if (hasMainMoney) {
//       return hasMainMoney;
//     } else if (extraTicker && extraBalance && !("balance" in extraBalance)) {
//       return {
//         hasEnoughLKMEX: extraBalance.gte(
//           new BigNumber(amount).shiftedBy(-extraDecimals),
//         ),
//         tokenDetails: {
//           collection: extraTicker,
//           nonce: 0,
//           balance: extraBalance,
//         },
//       };
//     } else {
//       return false;
//     }
//   };
// };

// export interface useBalanceReturnType {
//   balance: number;
//   tokenDetails?: LockedMexType;
// }

// export const useBalance = () => {
//   const { balances } = useContext();
//   return function (ticker: keyof BalanceType): useBalanceReturnType {
//     const balance = balances[ticker];
//     if (!balance) return { balance: 0 };
//     if ("balance" in balance) {
//       return {
//         balance: Number(
//           new BigNumber((balance as unknown as LockedMexType).balance),
//         ),
//         tokenDetails: balance as unknown as LockedMexType,
//       };
//     }
//     return {
//       balance: balance as any,
//     };
//   };
// };
