import React from "react";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";
import corpos1 from "../../../../assets/images/factions/Corpo/ch1.webp";

export const CorposChapter1 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">Chapter.1 - Corporate Control</h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          By now you’ve heard about the ruthless crime mice taking over the city
          by forming the powerful Crime Syndicate, but they’re not the only big
          players calling the shots in town.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`d-inline pt-2 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={corpos1} alt="" />
              </div>
            </div>
          )}
          In fact some might say the Syndicate owes its existence due to the
          corrupt big corporation directors, counselors, lawyers and local
          politicians, otherwise known as&#160;<b>The Corpos.</b>
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-2 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={corpos1} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          By moving the largest chunks of the cheddar produced by the
          Syndicate’s drug, extortion and smuggled goods operations through
          their various companies, they reinvest it into the very industries the
          Syndicate exploits.
          <br />
          <br />
          This way they keep control of the growing economy of Mice City and
          ensure their mice get every key influence job in the city while also
          churning out clean and almost untraceable cheddar for both the
          Syndicate and themselves. It’s an unbelievably delicate balancing act
          between The Corpos and The Syndicate, kept together by only one thing:
          greed.
          <br />
          <br />
          But this level of intricate economic control isn’t easy to maintain,
          and until recently no company would dare risk their reputation and
          wealth in such dangerous and illegal practices. Thankfully for the
          criminal elite, the largest investment firm in the country,{" "}
          <b>Maustrap Incorporated,</b> was taken over by what would prove to be
          the most ambitious, unscrupulous and greedy CEO in its history:{" "}
          <b>Rodent Rekko.</b>
          <br />
          <br />
          <br />
        </span>
      </div>
      <span></span>
    </div>
  );
};
