import React, { FC } from "react";
import { isSafari } from "react-device-detect";
import crimeBanner from "../../assets/backgrounds/crime_banner.webp";
import fashionBanner from "../../assets/backgrounds/fashion_2xcroped.webp";
import whiteMouse from "../../assets/backgrounds/white_crime.webp";
import "./LandingPage.scss";
import { MintBanner } from "../../components/mintBanner/MintBanner";
import PageState from "../../components/PageState";

type MobileBannerType = {
  mint: any;
  listing: any;
  buysCount: any;
  paymentToken: string;
  setPaymentToken: any;
};

export const MobileBanner: FC<MobileBannerType> = ({
  mint,
  listing,
  paymentToken,
  setPaymentToken,
  buysCount,
}) => {
  return (
    <>
      <div
        className="box-shadow mx-auto w-100"
        style={{ marginBottom: "-0.8rem" }}
      >
        <div
          className="banner-container mt-2 mt-0 d-flex"
          style={{ minHeight: "18rem" }}
        >
          <div>
            <div
              className="position-absolute overflow-hidden"
              style={{ maxWidth: "17rem", zIndex: 4 }}
            >
              <img className="image-full-size" src={whiteMouse} alt="" />
            </div>
            <div
              className="position-absolute overflow-hidden"
              style={{
                maxWidth: "18rem",
                transform: "translateX(7.6rem)",
                zIndex: "3",
              }}
            >
              <img className="image-full-size" src={crimeBanner} alt="" />
            </div>
            <div
              className="position-absolute overflow-hidden"
              style={{
                maxWidth: "13.2rem",
                zIndex: "2",
                transform: isSafari
                  ? "translate(17.5rem, -0.5rem)"
                  : "translate(17.5rem, -0.5rem)",
              }}
            >
              <img className="image-full-size" src={fashionBanner} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="discord-banner-container m-auto">
        <div className="content flex-column align-items-center">
          <p>
            Welcome to the 1980s, set in an alternative Miami populated by mice.
            Various factions roam these mean streets. From the decade of big
            hair, excess, pastel suits & neon lights comes this collection of
            pure retro nostalgia.
            <br />
            <br />
            <b>Mint a Mouse</b>
            <br />
            ✅&nbsp; Fully Randomized on chain minting <br />
            ✅&nbsp; Compatible with all Elrond marketplaces <br />
            ✅&nbsp; 90% of budget reinvested back to the community <br />
            ✅&nbsp; Experienced team & a vibrant community <br />
            ✅&nbsp; Crew3 Quests and Rewards <br />
            ✅&nbsp; NFT Staking
            <br />
          </p>
          {listing ? (
            <MintBanner
              mint={mint}
              listing={listing}
              paymentToken={paymentToken}
              setPaymentCount={setPaymentToken}
              buysCount={buysCount}
            />
          ) : (
            <PageState svgComponent={<></>} spin />
          )}
        </div>
      </div>
    </>
  );
};
