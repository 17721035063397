import React from "react";
import crime1 from "../../../assets/images/factions/Crime/CH1.webp";
import { useDisplaySize } from "../../../hooks/pageSizeHook";

export const SettingTheStageIII = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        Reveling in Victory
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            The Corpos’ grip over Mice City was at an all-time high, thanks to
            the cunning Rodent Rekko and his ruthless play to bring the entire
            Clubbing & Fashion scene under his heel.
          </b>
          <div className="faction-shadow">
            <div
              className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
              style={{
                maxWidth: isMobile ? "80%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={crime1} alt="" />
            </div>
          </div>
          <br />
          <br />
          His power grab wasn’t just about boosting his ego by gaining control
          of the most desirable faction in Mice City. Sure, it started out that
          way, but once he uncovered The Flyboys’ ties with The Agency, he
          shifted his focus. He finally saw a way of gaining access to Axel
          Mousley’s Task Force – the only thing lacking from the Corpos’ vast
          portfolio of leverage across the city.&#160;
          <br />
          <br />
          <span>
            Now he was enjoying an unrivaled expansion into virtually every
            territory and business he saw fit, all while dodging any
            investigation or targeted operation from the Agency before it even
            officially got off the ground.
            <br />
            <br />
            In his own demented way, Rekko was actually thankful to the Twins
            for opening his eyes to this “Ace up his sleeve” which were the
            Flyboys.
            <br />
            <br />
            <br />
          </span>
        </span>
      </div>
    </div>
  );
};
