import React, { useState } from "react";
import "./Customize.scss";
import "../landingPage/LandingPage.scss";
import { Carousel } from "react-bootstrap";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import newLogo from "../../assets/containerImages/sewerRats/SewerRatslogoNew.png";

export const Customize = () => {
  const [index, setIndex] = useState(0);
  const isMobile = useDisplaySize();
  const traits = [
    "https://micecity.blob.core.windows.net/site-resources/custom-sewer/sewer-custom/option1.png",
    "https://micecity.blob.core.windows.net/site-resources/custom-sewer/sewer-custom/option2.png",
    "https://micecity.blob.core.windows.net/site-resources/custom-sewer/sewer-custom/option3.png",
    "https://micecity.blob.core.windows.net/site-resources/custom-sewer/sewer-custom/option4.png",
    "https://micecity.blob.core.windows.net/site-resources/custom-sewer/sewer-custom/option5.png",
  ];
  const base =
    "https://micecity.blob.core.windows.net/site-resources/custom-sewer/sewer-custom/base.png";

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const arrow = (direction: string) => {
    if (isMobile) return <></>;
    return (
      <div
        className={`arrow-shadow ms-2 scale-animate ${direction}`}
        data-mdb-toggle="collapse"
      >
        <div
          className={`arrow ${direction}`}
          style={{ marginTop: 0, cursor: "pointer" }}
        />
      </div>
    );
  };

  return (
    <React.Suspense fallback={<></>}>
      <div className="box-shadow py-2">
        <div className="customize-character-container">
          <div className="pt-5 pb-2 d-flex flex-column justify-content-center align-items-center position-relative">
            <div
              className="position-relative"
              style={{
                maxWidth: isMobile ? "80%" : "45%",
                marginBottom: isMobile ? "0.5rem" : "1rem",
              }}
            >
              <img className="image-full-size" src={newLogo} alt="" />
            </div>
            <div style={{ fontFamily: "parkinson, serif", fontSize: "2rem" }}>
              Character Creation
            </div>
            <div style={{ width: "500px" }} className="position-relative">
              <div className="position-absolute" style={{ width: "500px" }}>
                <img className="image-full-size" src={base} alt="" />
              </div>
              <Carousel
                interval={null}
                activeIndex={index}
                onSelect={handleSelect}
                nextIcon={arrow("left")}
                prevIcon={arrow("right")}
              >
                {traits.map((trait, index) => (
                  <Carousel.Item
                    key={
                      index + trait.substring(trait.length - 7, trait.length)
                    }
                  >
                    <div className="carousel-element">
                      <img
                        className="d-block image-full-size"
                        src={trait}
                        alt="First slide"
                      />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className="d-flex justify-content-center align-items-center pt-3 gap-2">
                {traits.map((trait, traitIndex) => (
                  <div
                    style={{
                      width: "100px",
                      cursor: "pointer",
                      outline:
                        traitIndex === index ? "4px solid #FBDF89" : "none",
                    }}
                    key={traitIndex}
                    onClick={() => handleSelect(traitIndex)}
                  >
                    <img className="image-full-size" src={trait} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Suspense>
  );
};
