import React, { useState } from "react";
import crime1 from "../../../assets/images/factions/Crime/CH1.webp";
import agency1 from "../../../assets/images/factions/Agency/CH1.webp";
import smuggler from "../../../assets/images/factions/Corpo/ch1.webp";
import { useDisplaySize } from "../../../hooks/pageSizeHook";

export const SettingTheStageV = () => {
  const isMobile = useDisplaySize();
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <div>
      <span className="d-block py-4 h4" style={{ fontWeight: 600 }}>
        The Calm Before the Storm
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            As Ratface (and strangely, Mousley) had instructed, Johnny was ready
            to pay Senator Mooney a visit. The whole of Mice City was on high
            alert since the attack on the Syndicate safehouses, with both Corpo
            and Syndicate enforcers swarming the city, not to mention the
            increased desperation inside The Agency.
          </b>
          <br />
          <br />
          <div className="faction-shadow">
            <div
              className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
              style={{
                maxWidth: isMobile ? "80%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={agency1} alt="" />
            </div>
          </div>
          With George Younger riding alongside him, Johnny was able the
          smuggler’s secret routes to get out of town without bumping into any
          of the factions. They were like two ghosts riding through a land under
          martial law.
          <br />
          <br />
          Within hours they reached the senator’s house. Younger waited in the
          car while Johnny went to speak to Mooney, who was already informed
          about Ratface’s trusted advisor coming to broker a deal.
          <br />
          <br />
          Two hours later, they had come to terms: the Senator would start
          favoring any of the Syndicate’s shell companies and bend the law as
          much as he could in order to keep them off Ratface&lsquo;s back. In
          exchange he would have a say in the Syndicate’s business decisions
          and, of course, profits.
          <br />
          <br />
          Syndicate’s power toward more productive outcomes for the citizens,
          Ratface thought he was reestablishing balance between the faction and
          all Johnny could think was that he just avoided an all-out war in Mice
          City.
          <br />
          As Johnny was leaving, he asked Mooney if could make a call to his
          superior. Mooney of course agreed to give Ratface the good news. Only
          Johnny wasn’t calling Ratface, he was calling Axel.
          <br />
          <br />
          {showMore && (
            <>
              <span
                className="d-block pt-4 pb-2 h3"
                style={{ fontWeight: 600 }}
              >
                Boiling Point
              </span>
              Axel Mousley started to feel his Task Force were losing their grip
              on the whole situation. He always feared the day where the Corpo
              and Crime Syndicate’s unholy alliance would fall apart, leaving
              only chaos behind. That was the whole drive behind his Task Force.
              <br />
              <br />
              Now, with everything reaching a boiling point, he decided to take
              extreme measures using agent Johnny H’s latest information.
              <br />
              This was the first time the two most dangerous mice in the city
              were together in one place. The first time since Johnny had seen
              them both murder Maury, Rekko’s rival, all those years ago.
              <br />
              <br />
              That was the day he decided to create the Task Force, the day the
              Crime Syndicate and the Corpos started their reign over Mice City.
              <br />
              <br />
              Axel had always tried to bring them down from the inside, to avoid
              any fallout, but that was too late now, with open conflict between
              them. He feared the worse, so he activated his contingency plan
              <br />
              <br />
              <span
                className="d-block pt-4 pb-2 h3"
                style={{ fontWeight: 600 }}
              >
                Operation Serpent Heads
              </span>
              Johnny kept calling for a few minutes until Axel picked up:
              <p className="dialog-font m-0">
                J: Sir, it’s me, I just secured Ratface’s way to peace. We can
                keep both Factions under control!
                <br />
                <br />
                A: It’s too late, Johnny… You were right, our agents just
                spotted Ratface going into Maustrap Tower. But he wasn’t alone,
                he had a crew of his rats with him. He’s attacking Rekko.
                <br />
                <br />
                J: NO! Trust me, he’s only going to have a sit down with Rekko.
                He only wants peace, just like us. I know it sounds insane, but
                this time we want the same thing.
                <div className="faction-shadow">
                  <div
                    className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
                    style={{
                      maxWidth: isMobile ? "80%" : "30%",
                      float: isMobile ? "none" : "right",
                    }}
                  >
                    <img className="image-full-size" src={crime1} alt="" />
                  </div>
                </div>
                A: Kid, I know you’ve been undercover for a long time. That can
                mess with your head, you can start to get attached. But I can’t
                risk them starting a war like we had all those years ago. If I
                can take them both now, I’ll act on it.
                <br />
                <br />
                J: That’s bullshit! For fuck’s sake, trust me, he’s not going in
                there to spill blood. And if you take either of them out, you’ll
                be leaving a power vacuum inside the Factions. That’s going to
                cause a power struggle 10 times bloodier than what we’ve seen so
                far.
                <br />
                <br />
                A: You want me to trust that the most ruthless rat in criminal
                history is going after his enemy to have chit chat? I can’t
                gamble the future of Mice City’s innocents on your hunch…
                Operation Serpent Heads is already underway. The Task Force is
                briefed and on their way.
                <br />
                <br />
                J: Don’t do this! Call them off! Trust me, I have everything I
                need to get both of them to stand down.
                <br />
                <br />
                A: I would… if I hadn’t just seen that maniac, Ratface Montana,
                go inside the Tower with his death squad.
                <br />
                <br />
              </p>
              Get downtown as soon as you can. You knew the operation better
              than anyone. We’re moving with two teams, one landing on the roof
              and another storming the front. Hurry up!
              <br />
              <span
                className="d-block pt-4 pb-2 h3"
                style={{ fontWeight: 600 }}
              >
                Time to act!
              </span>
              Johnny was left with his phone in his hands. He had to act.
              <br />
              He knew his way was right, as crazy as it made him look.
              <br />
              Ratface’s intentions were true, he actually believed that. And the
              fucked-up thing was that so were Axel’s intentions.
              <br />
              <div className="faction-shadow">
                <div
                  className={`d-inline m-0 ${isMobile ? "" : "pe-3 pb-2"}`}
                  style={{
                    maxWidth: isMobile ? "80%" : "30%",
                    float: isMobile ? "none" : "left",
                  }}
                >
                  <img className="image-full-size" src={smuggler} alt="" />
                </div>
              </div>
              He couldn’t call Ratface. Operation Serpent Heads meant the entire
              block surrounding Maustrap Tower would have radio jammers to stop
              any communication. So, he couldn’t warn anyone.
              <br />
              <br />
              Suddenly, an even more outlandish idea hit him. He knew that
              Operation Serpent Heads was the Task Force’s ultimate move in case
              of emergency. An all-out stealth attack on the Faction safehouses.
              Only this time they just had one location to cover, making it
              easier.
              <br />
              <br />
              So Ratface & Rekko were likely going down. But Axel would make
              sure he wouldn’t kill them, just capture them.
              <br />
              <br />
              Johnny’s fear was that with the extreme tension in both factions,
              it wouldn’t take long for fighting within both organizations to
              start.
              <br />
              <br />
              So, he came up with an operation of his own. He needed a crew of
              insane drivers, fast cars, and a lot of luck.
              <br />
              Naturally, he called Hotshot…
            </>
          )}
        </span>
      </div>
      <div
        className="vote-button m-auto my-4"
        style={{ width: "max-content" }}
        onClick={() => setShowMore(!showMore)}
      >
        <span>{showMore ? "Hide" : "Show more"}</span>
      </div>
    </div>
  );
};
