import React, { FC, useEffect } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import { Button } from "../button";
import egld from "../../assets/images/elrond-symbol.svg";
import whiteDiscord from "../../assets/images/social/Discord-Logo-White.svg";
import { isGreaterThanMintTime } from "../../pages/landingPage/desktopBanner";
import { useMintHook } from "../../hooks/mintHook";
import { tokensMap } from "../../config.devnet";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import { denominate } from "@multiversx/sdk-dapp/utils";
import "./MintBanner.scss";

type MintBannerType = {
  mint: any;
  listing: any;
  buysCount: any;
  paymentToken: string;
  setPaymentCount: any;
};

export type IToken = keyof typeof tokensMap;

export const MintBanner: FC<MintBannerType> = ({
  mint,
  listing,
  paymentToken,
  setPaymentCount,
  buysCount,
}) => {
  const maxBuyCount =
    listing.max_per_wallet === "0" ? 8000 - parseInt(listing.int_count) : 50;
  const {
    token,
    buyAmount,
    incrementAmount,
    decrementAmount,
    incrementToken,
    decrementToken,
  } = useMintHook(
    listing.prices.length,
    buysCount ? maxBuyCount - buysCount : maxBuyCount
  );

  // listing.prices.splice(-1);
  const prices = listing.prices.reduce(
    (acc: Record<string, string>, curr: any) => {
      if (acc[curr.token_identifier]) return acc;
      else {
        acc[curr.token_identifier] = curr.token_identifier.includes("USDC")
          ? denominate({
              input: curr.amounttoLocaleString("fullwide", {
                useGrouping: false,
              }),
              denomination: 12,
              decimals: 2,
            })
          : curr.token_identifier.includes("VITAL")
          ? denominate({
              input: curr.amounttoLocaleString("fullwide", {
                useGrouping: false,
              }),
              denomination: 10,
              decimals: 2,
            })
          : denominate({
              input: Number(curr.amount).toLocaleString("fullwide", {
                useGrouping: false,
              }),
              denomination: 18,
              decimals: 2,
            });
      }
      return acc;
    },
    {}
  );

  const prefixes = Object.keys(prices).reduce((acc: any, key: string) => {
    acc[key] = Number(prices[key]).toFixed(2);
    return acc;
  }, {});
  const amount = prefixes[paymentToken] * buyAmount;
  const icon = tokensMap[listing.prices[token].token_identifier.toString()].svg;
  const mintNft = (e: any) => {
    mint(e, buyAmount);
  };
  const isMobile = useDisplaySize();

  const renderTooltip = (args: any) => (
    <Tooltip id="button-tooltip" {...args}>
      {args.text}
    </Tooltip>
  );

  useEffect(() => {
    setPaymentCount({ text: listing.prices[token].token_identifier });
  }, [token]);

  return (
    <>
      <div className="d-flex justify-content-around align-items-end">
        <div className="text-center">
          <div
            style={{
              fontFamily: "parkinson, serif",
              fontSize: isMobile ? "35px" : "20px",
            }}
          >
            Mice
          </div>
          <div>
            <MDBIcon
              className="scale-animate"
              style={{ color: "pink", cursor: "pointer" }}
              icon="minus"
              size={`${isMobile ? "2x" : "lg"}`}
              onClick={decrementAmount}
            />
            <span
              className="amount px-2"
              style={{ fontSize: isMobile ? "35px" : "" }}
            >
              {buyAmount}
            </span>
            <MDBIcon
              className="scale-animate"
              style={{ color: "pink", cursor: "pointer" }}
              icon="plus"
              size={`${isMobile ? "2x" : "lg"}`}
              onClick={incrementAmount}
            />
          </div>
        </div>
        <div className="text-center">
          <div
            style={{
              fontFamily: "parkinson, serif",
              fontSize: isMobile ? "1.8rem" : "1.2rem",
            }}
          >
            {prefixes &&
              Boolean(prefixes[paymentToken]) &&
              `${
                // paymentToken === "LKMEX-aab910"
                //   ? (
                //       (parseFloat(prefixes[paymentToken].replace(/,/g, "")) /
                //         1000000) *
                //       buyAmount
                //     ).toFixed(1) :
                amount % 1 != 0 ? amount.toFixed(2) : amount
              }${paymentToken === "LKMEX-aab910" ? "M" : ""}`}
          </div>
        </div>
        <div style={{ lineHeight: isMobile ? "55px" : "" }}>
          <div
            className="d-block text-center"
            style={{
              fontFamily: "parkinson, serif",
              fontSize: isMobile ? "35px" : "20px",
            }}
          >
            Token
          </div>
          <div className="d-flex align-items-center">
            <div
              className="arrow-shadow right ms-2 scale-animate"
              data-mdb-toggle="collapse"
            >
              <div
                className="arrow right "
                style={{ marginTop: 0, cursor: "pointer" }}
                onClick={decrementToken}
              />
            </div>
            <div
              className="mx-2"
              style={{
                width: isMobile ? "55px" : "30px",
                borderRadius: "50%",
                border:
                  listing.prices[token].token_identifier.toString() ===
                  "LKMEX-aab910"
                    ? "1px #2776BB solid"
                    : "",
              }}
            >
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 50, hide: 400 }}
                overlay={renderTooltip({ text: paymentToken })}
              >
                <div className="token-symbol">
                  <img className="image-full-size" src={icon} alt="" />
                </div>
              </OverlayTrigger>
            </div>
            <div
              className="arrow-shadow me-2 scale-animate"
              data-mdb-toggle="collapse"
            >
              <div
                className="arrow left"
                style={{ marginTop: 0, cursor: "pointer" }}
                onClick={incrementToken}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-center pt-3 pb-2"
        style={{ lineHeight: "2rem", marginLeft: "-40px" }}
      >
        <div>
          <Button
            fontSize="2rem"
            icon={isGreaterThanMintTime() ? egld : whiteDiscord}
            label={isGreaterThanMintTime() ? "MINT" : "JOIN DISCORD"}
            action={mintNft}
          />
        </div>
      </div>
    </>
  );
};
