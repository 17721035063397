import React, { useEffect, useRef } from "react";
import "./SewerRatsBanner.scss";
import sewerRats from "../../../assets/containerImages/sewerRats/ratsFactoryBanner.webp";
import transformationTitle from "../../../assets/containerImages/sewerRats/transformation-title.png";
import transformationBanner from "../../../assets/containerImages/sewerRats/transformation-banner.png";
import newLogo from "../../../assets/containerImages/sewerRats/SewerRatslogoNew.png";
import hand from "../../../assets/containerImages/sewerRats/banner-hand.png";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import desktopLogo from "../../../assets/backgrounds/logoMiceCity.png";
import mobileLogo from "../../../assets/backgrounds/logoMiceCity.png";
import { socialMediaIcons } from "../NavigationBar";
import { isSafari } from "react-device-detect";

export const SewerRatsBanner = (props: any) => {
  const ratsRef = useRef<HTMLDivElement>(null);
  const isMobile = useDisplaySize();

  useEffect(() => {
    if (ratsRef.current && props.scroll) {
      ratsRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [props.scroll]);

  return (
    <div
      className="mb-5 sewer-box w-100 mx-0"
      style={{ backgroundColor: "black" }}
    >
      <div ref={ratsRef} className="position-relative">
        {isMobile ? (
          <div
            className={`position-absolute ${
              isSafari ? "" : "text-center w-100"
            }`}
            style={{
              marginTop: "-4.6rem",
              zIndex: "1",
              transform: isSafari ? "translateX(11em)" : "translateX(0)",
            }}
          >
            <img style={{ width: "170px" }} src={mobileLogo} alt="" />
          </div>
        ) : (
          <div
            className="position-absolute"
            style={{
              marginLeft: "-17rem",
              zIndex: "1",
            }}
          >
            <div
              style={{
                width: "43%",
                transform: "translateX(1rem)",
              }}
            >
              <img
                className="d-block image-full-size"
                src={desktopLogo}
                alt=""
              />
              <div className="d-flex justify-content-center text-center pt-3">
                {socialMediaIcons.map((media, index) => (
                  <div
                    onClick={() => window.open(media.url, "_blank")}
                    className="mx-2"
                    style={{
                      width: "1.8rem",
                      height: "1.5rem",
                      cursor: "pointer",
                    }}
                    key={index}
                  >
                    <img className="w-100 h-100" src={media.icon} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="sewer-container mt-0 position-relative justify-content-center">
          <img
            className="w-100"
            src={sewerRats}
            alt=""
            style={{
              height: isMobile ? "39rem" : "100%",
              objectFit: isMobile ? "cover" : undefined,
              borderBottom: "6px solid #6AF22C",
            }}
          />
          <span className="position-absolute text-container text-white d-block mt-5">
            <div
              className="position-relative"
              style={{ marginBottom: isMobile ? "0.5rem" : "2rem" }}
            >
              <img className="image-full-size" src={newLogo} alt="" />
            </div>
            <div
              style={{
                opacity: 0.66,
                backgroundColor: "#000000",
                padding: "2rem",
              }}
            >
              Mutated Rats living in the underground of the city.
              <br /> Created by the greed for cheese, Corpos have polluted the
              underground of Mice City for decades. <br />
              <br /> The sewer rats have learned how to adapt and profit from
              this pollution, one day hoping to emerge victorious from the
              undergrounds.
              <br />
              <br />
              <b>Coming early this year...</b>
            </div>
          </span>
        </div>
      </div>
      <div className="px-4" style={{ maxWidth: isMobile ? "90%" : "60%" }}>
        <div className="mt-5 pt-4 text-center mb-4">
          <img
            className="image-full-size"
            style={{ width: isMobile ? "70%" : "100%" }}
            src={transformationTitle}
            alt=""
          />
        </div>
        <div
          style={{ width: isMobile ? "100%" : "106%" }}
          className="text-center"
        >
          <img className="image-full-size" src={transformationBanner} alt="" />
        </div>
        <div
          className="ps-5 d-flex flex-column"
          style={{
            color: "#7BFF63",
            gap: "2rem",
            fontSize: isMobile ? "0.9rem" : "1rem",
          }}
        >
          <span className="d-inline-block fw-bold">
            How do I get one?&nbsp;&nbsp;🐀
          </span>
          <span className="d-inline-block">
            1. Get your hands on an{" "}
            <a href="/OGs" style={{ color: "inherit" }}>
              <u>OG Mice City NFT</u>
            </a>
            <br /> The first 800 owners of a Mice City NFT will be eligible to
            claim one Sewer Rat.
          </span>
          <span className="d-inline-block">
            2. Whitelist
            <br /> We will soon announce a whitelist. Participate in the
            community to learn more.
          </span>
          <span className="d-inline-block">
            3. Wait for the main mint
            <br /> Once the OG & Whitelist NFTs are distributed, you will be
            able to get your hands on a Sewer Rat NFT.
          </span>
        </div>
        <div className="mt-5 text-center" style={{ marginBottom: "-2rem" }}>
          <img src={hand} alt="" />
        </div>
      </div>
      {/*TODO remove comment when banner will move back*/}
      {/*<div className="box-shadow w-75 mx-0" style={{ height: "1.5rem" }}>*/}
      {/*  <div className="empty-banner-faction mt-2 mt-0 d-flex h-100"/>*/}
      {/*</div>*/}
    </div>
  );
};
