import React, { useEffect, useRef, useState } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import "./FaqBanner.scss";
import { MDBCollapse } from "mdb-react-ui-kit";

const questions = [
  {
    question: "What is Mice City?",
    answer:
      "The most complex NFT collection inspired by 1980s excess, pastel suits, " +
      "big hair & neon lights, projected to hit the metaverse in an alternative Miami populated by mice. " +
      "Each NFT is unique, algorithmically generated out of 400+ unique traits hand-drawn by our artist. ",
  },
  {
    question: "What is the population of Mice City?",
    answer:
      "At the time of recording this information the city can accommodate 8000 citizens. Mean bastards " +
      "thirsty for cheddar, superficial wealth, heroic mice, speed demons and others - you will find them in Mice City.",
  },
  {
    question: "What is the utility of my mouse?",
    answer:
      "As a citizen of Mice City you have the power. You know better what your community needs, along our director you will have the chance to shape to future of your people. Of course, your involvement will be rewarded!\n" +
      "\n" +
      "Your mouse can serve as your digital identity, and open digital doors for you.\n" +
      "Future projects will be developed by the team to add more utility and boost the collection value.",
  },
  {
    question: "When do we open the gates of Mice City? ",
    answer:
      "Minting will be available on 18th of February via XOXNO Market for a flat price of 0.8 EGLD.",
  },
  {
    question: "Who are the founders of Mice City?",
    answer:
      "Four friends decided to join the trend and build a new city for the metaverse:\n" +
      "kosy - The artist 🎨\n" +
      "MLA - Hacker Mouse 👨‍💻\n" +
      "Tyrion - The Director 🎬\n" +
      "Dalv - Hacker Mouse 👨‍💻",
  },
  {
    question: "Which chain are you going to mint on?",
    answer:
      "Mice City will launch on Elrond blockchain for a flat price of 0.5 EGLD to assure a fair " +
      "launch and distribution. Elrond is an emerging blockchain with huge potential; it is fast, secure, " +
      "reliable with insignificant transaction and minting costs.",
  },
  {
    question: "Mice City OGs ?",
    answer:
      "The first 800 inhabitants will represent the building blocks of future generations. " +
      "Their contribution will never be forgotten and they will be entitled to special & unique perks. ",
  },
];

export const FaqBanner = (props: any) => {
  const [rotate, setRotate] = useState(-1);
  const isMobile = useDisplaySize();
  const faqsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (faqsRef.current && props.scroll) {
      faqsRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [props.scroll]);

  const handleToggleQuestion = (index: number) => {
    if (rotate === index) {
      setRotate(-1);
    } else {
      setRotate(index);
    }
  };

  const renderText = (text: string) => {
    return text.split("\n").map((str, index) => <div key={index}>{str}</div>);
  };

  return (
    <div ref={faqsRef}>
      <div className="box-shadow w-100 mx-0" style={{ marginTop: "2.5rem" }}>
        <div className="faq-container mt-2 mt-0 flex-column px-2">
          <div
            className={`title-box-shadow mx-0 ${
              isMobile ? "text-center" : "ps-5"
            }`}
          >
            <div className="title-container d-inline">
              <span>FAQs</span>
            </div>
          </div>
          <div className="questions d-flex flex-column justify-content-around py-5">
            {questions.map((question: any, index) => (
              <div className="py-3" key={index}>
                <div
                  className="question-container"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  onClick={() => handleToggleQuestion(index)}
                >
                  <div className="arrow-shadow" data-mdb-toggle="collapse">
                    <div
                      className={`arrow ${rotate === index ? "animate" : ""}`}
                    />
                  </div>
                  <span className="d-block fw-bold h5 m-0 ps-3">
                    {question.question}
                  </span>
                </div>
                {question.answer && (
                  <MDBCollapse
                    style={{ maxWidth: isMobile ? "85%" : "74%" }}
                    show={rotate === index}
                  >
                    {renderText(question.answer)}
                  </MDBCollapse>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
