import React from "react";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";
import crime1 from "../../../../assets/images/factions/Crime/ch4.webp";

export const SyndicateChapter4 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.4 - The Syndicate is born
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          Ratface wasn’t content with just an uneasy business alliance between
          the families, he saw even more potential.
          <br />
          <br />
          In a shock move that would change everything, he managed to broker a
          deal with Rodent Rekko, the soon to be leader of the Corpos in Mice
          City. But we’ll get into that story a bit later.
          <br />
          <br />
          For now all we need to know is that this deal gave Ratface unfiltered
          access to an obscene amount of wealth and resources, enabling him to
          put his master plan into action.
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "pt-3" : "ps-3 d-inline"}`}
              style={{
                maxWidth: isMobile ? "100%" : "37%",
                float: isMobile ? "none" : "right",
              }}
            >
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}
              <img className="image-full-size" src={crime1} alt="" />
            </div>
          </div>
          <br />
          <br />
          So once again, using himself as a mediator, he made the impossible
          happen. He got the four crime families, once sworn enemies, to
          completely merge together in one single all-encompassing organization.
          <br />
          <br />
          This wasn’t just a simple run of the mill crime family anymore… No,
          this was a Fortune 500 company working in the shadows, Crime Inc.,
          with RatFace as its unofficial CEO.
          <br />
          <br />
          And it worked, the money pouring into the city tripled in a matter of
          months, and the guys on top suddenly found themselves kings over an
          empire of excess.
          <br />
          <br />
          But like every great empire in history, the bigger you get, the harder
          it is to keep it together.
          <br />
          <br />
          And all those mountains of cheddar bring out the grubby mitts of
          greed.
          <br />
          <br />
          Now, the Syndicate is starting to show some of its weaknesses, with
          every crime family trying to chip away just a little bit more from the
          others’ share, indirectly competing with each other to oversee and
          control the most profitable businesses in the city.
          <br />
          <br />
          Only time will tell if Ratface will be able to keep the other bosses
          in line or if it will all crumble and get swallowed up by The
          Agency&apos;s operatives waiting for a sign to make their move and
          take them apart.
          <br />
          <br />
        </span>
      </div>
      <span></span>
    </div>
  );
};
