import React from "react";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";
import agency1 from "../../../../assets/images/factions/Agency/CH1.webp";

export const AgencyChapter1 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">Chapter.1 - The ”Real” Law</h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          So far you’ve met most of the different factions in Mice City and it
          may seem that there isn’t a single mouse in town who isn’t living on
          the wrong side of the law. But that isn’t entirely the case.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3 d-inline"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={agency1} alt="" />
              </div>
            </div>
          )}
          There&apos;s still a centralized law enforcement institution in Mice
          City encompassing the police, firefighters, army, special operatives
          and even governmental space programs under one single banner -{" "}
          <b>The Agency,</b>&#160;although not all of its members are as clean
          as you would expect.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={agency1} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          Even so, there are still those determined to fight back; small groups
          of dedicated police mice from different law enforcement agencies all
          over town who all want to put an end to the Syndicate’s reign over the
          city.
          <br />
          <br />
          But that’s easier said than done. With the Syndicate controlling every
          aspect of criminal enterprise from manufacturing to distribution and
          The Corpos covering their tracks and laundering their Cheddar it seems
          impossible to get any case against them to stick. Hell, even the
          captains of most police precincts and local counselors are working for
          the Corpos.
          <br />
          <br />
          Yet, somehow among all this madness, these brave (some might call them
          crazy) detectives, street level cops and undercover agents with a keen
          nose for any illegal cheddar have banded together to form The
          Agency&apos;s first Anti-Syndicate&#160;<b>Task Force.</b>
          <br />
          <br />
        </span>
      </div>
      <span></span>
    </div>
  );
};
