import React, { FC } from "react";
import "./button.scss";
import { isGreaterThanMintTime } from "../pages/landingPage/desktopBanner";
import { useDisplaySize } from "../hooks/pageSizeHook";

type ButtonType = {
  icon?: any;
  label: string;
  action: any;
  fontSize: string;
  classname?: string;
};

export const Button: FC<ButtonType> = ({
  action,
  icon,
  label,
  fontSize,
  classname,
}) => {
  const isMobile = useDisplaySize();

  return (
    <div className={`button-container ${classname}`} onClick={action}>
      <div className="polygon-wrapper">
        <div
          className="label-wrapper"
          style={{
            paddingBottom: isMobile ? "5px" : "5px",
            padding: isMobile ? "15px" : "",
          }}
        >
          {icon && (
            <div
              className="image-wrapper"
              style={{
                marginRight: isGreaterThanMintTime() ? "0.2rem" : "1rem",
              }}
            >
              <img
                className="image-full-size"
                src={icon}
                alt="discord"
                style={{
                  maxWidth: isMobile ? "100%" : "80%",
                }}
              />
            </div>
          )}
          <div
            className="label"
            style={{
              whiteSpace: "nowrap",
              fontSize: isMobile ? fontSize : "1.3rem",
              fontWeight: "700",
            }}
          >
            {label}
          </div>
        </div>
      </div>
    </div>
  );
};
