import React, { useEffect } from "react";
import "./App.scss";
import ReactGA from "react-ga";
import { FirstLookPage } from "./pages/firstLook/firstLookPage";
import { Root } from "./pages/landingPage/Root";
import { network, referer } from "./config.devnet";
import PageState from "./components/PageState";
import axios from "axios";
import { ContextProvider } from "./context";
import { useDisplaySize } from "./hooks/pageSizeHook";
import { DappProvider } from "@multiversx/sdk-dapp/wrappers";
import { TransactionsToastList } from "@multiversx/sdk-dapp/UI/TransactionsToastList";
import { SignTransactionsModals } from "@multiversx/sdk-dapp/UI/SignTransactionsModals";

export const routeNames = {
  home: "/",
  profile: "/profile",
  transaction: "/transaction",
  ledger: "/ledger",
  walletconnect: "/walletconnect",
};

function App() {
  const firstLook = false;
  const [bridges, setBridges] = React.useState<any>(undefined);
  const isMobile = useDisplaySize();

  React.useEffect(() => {
    const fn = async () => {
      const { data } = await axios.get(
        "https://wallet-connect.multiversx.com/onlinews",
        {
          headers: {
            Referer: referer,
          },
        }
      );
      const onlines = data.online.map((url: string) => {
        return url + "?env=browser&host=trust.market&protocol=wc&version=1";
      });
      setBridges(onlines);
    };
    fn();
  }, []);

  useEffect(() => {
    ReactGA.initialize("UA-219786111-1");
    ReactGA.pageview("Home page view");
  }, []);

  if (bridges === undefined) {
    return <PageState svgComponent={<></>} spin />;
  }

  //993e02c935a354e0fa10a6233d3029cf
  return (
    <React.Suspense fallback={<></>}>
      <DappProvider
        environment="mainnet"
        customNetworkConfig={{
          ...network,
          walletConnectBridgeAddresses: bridges,
          walletConnectV2ProjectId: "f13666fe4668b18064288b736992e576",
          skipFetchFromServer: true,
        }}
      >
        <>
          <ContextProvider>
            <div className="App">
              {firstLook ? <FirstLookPage /> : <Root />}
            </div>
          </ContextProvider>
          <SignTransactionsModals />
          <div
            className="position-fixed box-shadow"
            style={{
              bottom: "3rem",
              right: isMobile ? "1rem" : "3rem",
              zIndex: 1040,
              minWidth: "400px",
            }}
          >
            <TransactionsToastList />
          </div>
        </>
      </DappProvider>
    </React.Suspense>
  );
}

export default App;
