import React, { FC, useEffect, useRef, useState } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import banner from "../../../assets/storyCrossRoads/clashOfTheUnderlords/vote_banner.png";
import "../../../pages/crossStories/CrossStories.scss";

type VoteType = {
  voteOption: string;
  didVote: boolean;
  hasMice: boolean;
  loadingVoteInfo: boolean;
  setVoted: any;
};

enum Options {
  "A" = "A",
  "B" = "B",
  "C" = "C",
}

export const TheVoteIII: FC<VoteType> = ({ didVote, voteOption }) => {
  const isMobile = useDisplaySize();
  const [selected, setSelected] = useState(Options.A);
  const newspaperRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  console.log(voteOption);

  useEffect(() => {
    setSelected(voteOption as Options);
  }, [voteOption]);

  return (
    <div className="d-flex flex-column align-items-center">
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Vote
      </span>
      <div
        ref={newspaperRef}
        className={`${isMobile ? "w-100" : "w-100"}  ${
          isMobile ? "d-flex justify-content-center" : ""
        }`}
        style={{
          marginBottom: "4rem",
        }}
      >
        <img className="image-full-size" src={banner} alt="" />
      </div>
      <b>
        Rekko and his corpo mice contemplated several ways of going against the
        ruthless criminal juggernaut which would weaken the Syndicate enough for
        them to try and get the upper hand. In the end, only one plan showed the
        most promise:
      </b>
      <br />
      {showMore && (
        <div className="d-flex align-items-center flex-column pb-4">
          <div
            className={`d-flex align-items-center pb-4 ${
              isMobile ? "flex-column" : ""
            }`}
            style={{ height: !isMobile ? "300px" : "" }}
          >
            <div
              className={`vote-box h-100 ${
                selected === Options.A ? "pressed" : ""
              } ${isMobile ? "mb-4" : "me-4"}`}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.A)}
            >
              <span
                className="pb-3 d-block pt-3"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                A.
              </span>
              <span>
                The Corpos will approach The Twins and get them to find the
                identity of the undercover agent infiltrated with The Syndicate.
                In exchange Rekko would agree to loosen their control over the
                Clubbing & Fashion Scene, shifting the balance of power in Mice
                City
              </span>
            </div>
            <div
              className={`vote-box h-100 ${
                selected === Options.B ? "pressed" : ""
              } `}
              style={{
                pointerEvents: didVote ? "none" : "auto",
                width: "auto",
              }}
              onClick={() => setSelected(Options.B)}
            >
              <span
                className="pb-3 d-block"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                B.
              </span>
              <span>
                The Syndicate enjoyed almost complete immunity from most of the
                political and financial sectors in Mice City, thanks to Rekko’s
                influence. That’s why their best play was to turn all those
                assets against Ratface’s rackets, putting overnight pressure on
                his operation from multiple angles.
              </span>
            </div>
          </div>
          <div
            className={`vote-box w-100 ${
              selected === Options.C ? "pressed" : ""
            } `}
            style={{ pointerEvents: didVote ? "none" : "auto" }}
            onClick={() => setSelected(Options.C)}
          >
            <span
              className="pb-3 d-block"
              style={{ fontSize: "20px", fontWeight: 600 }}
            >
              C.
            </span>
            <span>
              Rekko thought of several clever ways of getting the Syndicate to
              fall back in line with their original “unholy alliance”, as it was
              called. But in the end, the most effective way would still be a
              show of force. A clear message to Ratface that the Corpos aren’t
              just a bunch of corrupt bureaucrats. So Rekko’s private corpo
              special ops units were getting ready for an actual strike against
              The Crime Syndicate, even though Ratface would have the advantage.
            </span>
          </div>
        </div>
      )}
      <div
        className="vote-button m-auto my-4"
        style={{ width: "max-content" }}
        onClick={() => setShowMore(!showMore)}
      >
        <span>{showMore ? "Hide options" : "Show options"}</span>
      </div>
      {!didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          <span>Tune in next week and find out how the clash unfolds!</span>
        </div>
      )}
      {didVote && (
        <div
          className="text-center m-auto pb-3"
          style={{ maxWidth: isMobile ? "70%" : "50%", justifySelf: "center" }}
        >
          <span>
            <b>Tune in next week and find out how the clash unfolds!</b>
          </span>
        </div>
      )}
      <span
        className="d-block h4 text-center"
        style={{ fontWeight: "bolder", color: "#ED9AC8" }}
      >
        You have decided!
        <br /> And the winner is 🥁🥁🥁: Option C!
        <br />
        <br /> Stay tuned to find out how the story ends!
      </span>
    </div>
  );
};
