import React from "react";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";
import crime1 from "../../../../assets/images/factions/Crime/CH1.webp";

export const SyndicateChapter1 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.1 - Welcome to the jungle
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          If you ask your everyday working mouse about Mice City, they’ll tell
          you it’s the go to place to spend that hard earned Cheddar in the
          perfect extravagant vacation hotspot.&#160;
          <br />
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={crime1} alt="" />
              </div>
            </div>
          )}
          Casinos, resorts, night-clubs, strip-clubs, high-end fashion stores
          galore, world class restaurants, you name it and Mice City has it.
          <br />
          <br />
          But all this excess isn’t sustainable by any law-abiding business
          model. This means that all this world-class extravagance needs a world
          class criminal organization pulling the strings and bypassing all the
          usual budget limitations, red-tape and paperwork.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={crime1} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          This need of a so-called “shadow leadership” paved the way to the
          creation of one of the most unlikely, unstable and most powerful
          criminal organizations in the world – the{" "}
          <b>Mice City Crime Syndicate.</b>
          <br />
          <br />
          In a move that shocked every law enforcement agency in town, the 4
          biggest crime families in town –the Yakuza, the Italian Mafia, the
          Russian Mob and the South American Cartel - suddenly stopped fighting
          with each other and, against every tradition held sacred by most of
          their predecessors, merged their forces in an unstoppable organized
          crime behemoth.
          <br />
          <br />
          This move started taking shape when{" "}
          <b>Manny &quot;Rat-face&quot; Montana</b> proposed a meet between all
          4 bosses and tried to do the impossible: get four arch-enemies to form
          an alliance.
          <br />
          <br />
          <br />
          But we’re getting a bit ahead of ourselves. Who was Manny Montana and
          why did all four families respect him enough to indulge his crazy
          plan?
          <br />
          <br />
        </span>
      </div>
      <span></span>
    </div>
  );
};
