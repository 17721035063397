import React, {
  FC,
  forwardRef,
  ReactNode,
  Ref,
  useEffect,
  useState,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LandingPage.scss";
import { Dropdown, FormControl, Nav, Navbar } from "react-bootstrap";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import discordIcon from "../../assets/icons/sociaMedia/discord.svg";
import twitterIcon from "../../assets/icons/sociaMedia/twitter.svg";
import instagramIcon from "../../assets/images/social/iconmonstr-instagram-11.svg";
import telegram from "../../assets/icons/sociaMedia/telegram.png";
import { Menu } from "../../model/Main";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { useDispatch } from "../../context";
import axios from "axios";
import { referer } from "../../config.devnet";
import { getNetworkConfig, logout } from "@multiversx/sdk-dapp/utils";
import { useGetAccountInfo, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import CustomDropdown from "../../components/common/CustomDropdown";

export const socialMediaIcons = [
  {
    icon: discordIcon,
    url: "https://discord.gg/PGJfJGN9Mz",
  },
  {
    icon: telegram,
    url: "https://t.me/+f34z1-BLx3FjOGI0",
  },
  {
    icon: twitterIcon,
    url: "https://twitter.com/MiceCityClub",
  },
  {
    icon: instagramIcon,
    url: "https://www.instagram.com/mice.city/",
  },
];

interface CustomToggleProps {
  children: ReactNode;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const CustomToggle = forwardRef<HTMLAnchorElement, CustomToggleProps>(
  ({ children, onClick }: CustomToggleProps, ref: Ref<HTMLAnchorElement>) => (
    <a
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  )
);

interface CustomMenuProps {
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  "aria-labelledby"?: string;
}

const CustomMenu = forwardRef<HTMLDivElement, CustomMenuProps>(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              (child as React.ReactElement).props.children
                .toLowerCase()
                .startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export const NavigationBar = (props: any) => {
  const navigate = useNavigate();
  const isMobile = useDisplaySize();
  const { explorerAddress } = getNetworkConfig();
  const [isOpened, setIsOpened] = useState(false);
  const dispatch = useDispatch();
  const { isLoggedIn, tokenLogin } = useGetLoginInfo();
  const { address, account } = useGetAccountInfo();
  const { apiAddress } = getNetworkConfig();
  const { search } = useLocation();

  const handleToggleClick = () => {
    setIsOpened(!isOpened);
  };

  const handleSpecs = (faction: any) => {
    navigate("/");
    props.setSelectedMenu(faction);
  };

  const handleStories = () => {
    navigate("/stories");
  };

  const handleOGS = () => {
    navigate("/OGs");
  };

  const trimAddress = (text: string) => {
    return (
      text.substr(0, 7) + "......" + text.substr(text.length - 5, text.length)
    );
  };

  useEffect(() => {
    const fn = async () => {
      if (tokenLogin?.signature && tokenLogin?.loginToken) {
        const local_token = localStorage.getItem(`login.${address}`);
        if (local_token) {
          const tokenInfo = JSON.parse(local_token);
          if (tokenInfo.expires < new Date().getTime()) {
            localStorage.removeItem(`login.${address}`);
            const { data } = await axios.post(
              `${apiAddress}/login`,
              {
                signature: tokenLogin?.signature,
                loginToken: tokenLogin?.loginToken,
                address,
                data: {},
              },
              {
                headers: {
                  Referer: referer,
                },
              }
            );
            const expires = new Date().getTime() + 86400000;
            localStorage.setItem(
              `login.${address}`,
              JSON.stringify({ ...data, expires })
            );
          }
        } else {
          const { data } = await axios.post(
            `${apiAddress}/login`,
            {
              signature: tokenLogin?.signature,
              loginToken: tokenLogin?.loginToken,
              address,
              data: {},
            },
            {
              headers: {
                Referer: referer,
              },
            }
          );
          const expires = new Date().getTime() + 86400000;
          localStorage.setItem(
            `login.${address}`,
            JSON.stringify({ ...data, expires })
          );
        }
      } else {
        const local_token = localStorage.getItem(`login.${address}`);
        if (local_token) {
          const tokenInfo = JSON.parse(local_token);
          if (tokenInfo.expires < new Date().getTime()) {
            const urlSearchParams = new URLSearchParams(search);
            const params = Object.fromEntries(urlSearchParams as any);
            const { signature } = params;

            const lToken = localStorage.getItem("loginToken");
            if (signature) {
              const { data } = await axios.post(
                `${apiAddress}/login`,
                {
                  signature: signature,
                  loginToken: lToken,
                  address,
                  data: {},
                },
                {
                  headers: {
                    Referer: referer,
                  },
                }
              );
              const expires = new Date().getTime() + 86400000;
              localStorage.setItem(
                `login.${address}`,
                JSON.stringify({ ...data, expires })
              );
              localStorage.removeItem("loginToken");
            } else {
              console.log("logout");
              localStorage.removeItem(`login.${address}`);
              logout("/");
            }
          }
        } else {
          const urlSearchParams = new URLSearchParams(search);
          const params = Object.fromEntries(urlSearchParams as any);
          const { signature } = params;
          if (signature) {
            const lToken = localStorage.getItem("loginToken");
            const { data } = await axios.post(
              `${apiAddress}/login`,
              {
                signature: signature,
                loginToken: lToken,
                address,
                data: {},
              },
              {
                headers: {
                  Referer: referer,
                },
              }
            );
            const expires = new Date().getTime() + 86400000;
            localStorage.setItem(
              `login.${address}`,
              JSON.stringify({ ...data, expires })
            );
            localStorage.removeItem("loginToken");
          } else {
            console.log("logout");
            localStorage.removeItem(`login.${address}`);
            //TODO fix xoxno login
            // logout("/");
          }
        }
      }
    };
    if (isLoggedIn) {
      fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLogin, address]);

  const handleLogin = (e: any) => {
    if (isLoggedIn) {
      e.preventDefault();
      logout();
    } else {
      dispatch({
        type: "setGlobalModal",
        globalModal: { openGlobalModal: true },
      });
    }
  };

  const handleDropdownSelect = (item: string | null) => {
    console.log(`Selected: ${item}`);
  };

  return (
    <div
      className={`d-flex ${
        isMobile ? "w-100 justify-content-between" : "justify-content-end"
      }`}
      style={{ marginBottom: isMobile ? "" : "", fontWeight: "bold" }}
    >
      <Navbar
        className={`w-100 ${isMobile && isOpened ? "mb-5" : ""}`}
        collapseOnSelect
        expand="lg"
        bg="bg-transparent"
        variant="light"
      >
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="text-dark"
          onClick={handleToggleClick}
        />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="mr-auto">
            {/*<Nav.Link*/}
            {/*  style={{ color: "#00CE3E" }}*/}
            {/*  className={!isMobile ? "mx-3" : ""}*/}
            {/*  onClick={() => navigate("/customize")}*/}
            {/*>*/}
            {/*  CREATE&nbsp;CHARACTER*/}
            {/*</Nav.Link>*/}
            <Nav.Link
              className={!isMobile ? "mx-3" : ""}
              onClick={handleStories}
            >
              MC&nbsp;STORIES
            </Nav.Link>
            {/*<Nav.Link className={!isMobile ? "mx-3" : ""} onClick={handleOGS}>*/}
            {/*  OGs*/}
            {/*</Nav.Link>*/}
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="mr-auto"
                style={{ lineHeight: "1rem", paddingBottom: "4px" }}
              >
                <CustomDropdown
                  title="SPECIAL NFTs"
                  items={["OGs", "Minted Mice Madness", "Sales Super Stake"]}
                  onSelect={handleDropdownSelect}
                  className="my-custom-dropdown"
                  style={{ margin: isMobile ? "" : " 0 1rem" }}
                />
              </Nav>
            </Navbar.Collapse>
            <Nav.Link
              className={!isMobile ? "mx-3" : ""}
              onClick={() => handleSpecs(Menu.Specs)}
            >
              COLLECTION&nbsp;SPECS&nbsp;&&nbsp;FACTIONS
            </Nav.Link>
            <Nav.Link
              className={!isMobile ? "mx-3" : ""}
              onClick={() => handleSpecs(Menu.RoadMap)}
            >
              ROADMAP
            </Nav.Link>
            <Nav.Link
              className={!isMobile ? "mx-3" : ""}
              onClick={() => handleSpecs(Menu.Team)}
            >
              TEAM
            </Nav.Link>
            <Nav.Link
              className={!isMobile ? "mx-3" : ""}
              onClick={() => handleSpecs(Menu.FAQs)}
            >
              FAQs
            </Nav.Link>
            {isLoggedIn && account && account.address && (
              <span
                className="address pe-2"
                style={{
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                }}
              >
                <a
                  href={`${explorerAddress}/accounts/${address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {account.username
                    ? `@${account.username.replace(".elrond", "")}`
                    : trimAddress(account.address)}
                </a>{" "}
              </span>
            )}
            <div className="login-button">
              <Button
                fontSize="1.3rem"
                label={isLoggedIn ? "Logout" : "Login"}
                action={handleLogin}
              />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div
        className="d-flex justify-content-between align-items-center align-self-start pt-3"
        style={{ zIndex: "2" }}
      >
        {isMobile &&
          socialMediaIcons.map((media, index) => (
            <div
              onClick={() => window.open(media.url, "_blank")}
              className="mx-2"
              style={{ width: "1.8rem", height: "1.5rem", cursor: "pointer" }}
              key={index}
            >
              <img className="w-100 h-100" src={media.icon} alt="" />
            </div>
          ))}
      </div>
    </div>
  );
};
