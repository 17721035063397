import { AbiRegistry } from "@elrondnetwork/erdjs/out";

export class SmartContractAbis {
  private static manager: AbiRegistry;
  private static minter: AbiRegistry;
  private static market: AbiRegistry;

  public static async getMarket(): Promise<AbiRegistry> {
    if (!SmartContractAbis.market) {
      SmartContractAbis.market = await AbiRegistry.create({
        name: "market",
        endpoints: [`${process.env.PUBLIC_URL}/market.json`],
        types: [],
      });
    }

    return SmartContractAbis.market;
  }

  public static async getManager(): Promise<AbiRegistry> {
    if (!SmartContractAbis.manager) {
      SmartContractAbis.manager = await AbiRegistry.create({
        name: "market",
        endpoints: [`${process.env.PUBLIC_URL}/manage.json`],
        types: [],
      });
    }

    return SmartContractAbis.manager;
  }

  public static async getMinter(): Promise<AbiRegistry> {
    if (!SmartContractAbis.minter) {
      SmartContractAbis.minter = await AbiRegistry.create({
        name: "market",
        endpoints: [`${process.env.PUBLIC_URL}/minter.json`],
        types: [],
      });
    }

    return SmartContractAbis.minter;
  }
}
