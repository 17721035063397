import React from "react";
import clubbing2 from "../../../../assets/images/factions/Clubbing and Fashion/ch3....webp";
import clubbing3 from "../../../../assets/images/factions/Clubbing and Fashion/ch3...webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const ClubbingChapter3 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">Chapter.3 - Outcasts & Empires</h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          But how did this pastel kingdom of suede, music and drugs get its
          start? And who are the mice behind the name? Everyone is familiar with
          this elusive &quot;brand&quot; name, Fashionistas, and the mystique it
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3 d-inline"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={clubbing2} alt="" />
              </div>
            </div>
          )}
          brings, but not many know that it was started by the same mice who are
          still leading the faction today.
          <br />
          <br />
          Looking back at them 10 years ago, you would hardly recognize any of
          them: <b>Winston</b>&#160;- a wannabe fashion designer, his twin
          sister&#160;<b>Winnie</b>&#160;with an unbelievable knack for business
          and marketing (also known as&#160;<b>The Twins</b>) and their
          childhood friend&#160;<b>Diego</b>&#160;- a party addict with a knack
          for organizing events.
          <br />
          <br />
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={clubbing3} alt="" />
                <br />
                <br />
              </div>
            </div>
          )}
          At the time, the twins were working for a now forgotten clothing store
          downtown Mice City on a mostly abandoned Neon Drive, which got its
          name from the cheap motel signs near the store. You have to remember
          this was before the Crime Syndicate and the Corpos were even a thing.
          And the Neon Drive was just a street like any other.
          <br />
          <br />
          These 3 youngsters became friends in the slums of Mice City, where the
          Russian mob, Italian mafia and South American cartels were fighting
          each other daily for territory. They were outcasts of this violent
          system, as they didn&apos;t care about who was the most powerful gang
          in the neighborhood. They had extravagant taste, always attracted to
          spectacle and cutting edge trends in the fashion and entertainment
          scenes.
          <br />
          <br />
          So naturally they found each other, like an oasis of freethinkers in a
          desert of squares.
          <br />
          <br />
          All three of them started spending all their free time together,
          sharing in their big dreams of freedom from this mundane day to day
          struggle, and of one day getting a chance to be a part of something
          big, where creativity was key, not gun-power. After a few years of
          working cheap jobs since finishing high-school and living in cheap
          motels, opportunity presented itself and they weren&apos;t going to
          let this pass them by.
          <br />
          <br />
          The store where the Twins were working was going out of business, so
          they pulled what money all 3 of them managed to save and bought it for
          themselves. But not just to try and sell clothes anymore... no! It
          would be a complete overhaul.
          <div className="faction-shadow">
            <div
              className={`pb-3 pt-3 m-0 ${isMobile ? "" : "pe-3 d-inline"}`}
              style={{
                maxWidth: isMobile ? "100%" : "37%",
                float: isMobile ? "none" : "left",
              }}
            >
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}

              <img className="image-full-size" src={clubbing3} alt="" />
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}
            </div>
          </div>
          They would get Winston to design his own avant-garde clothing line,
          something he had dreamt of all his life and now finally had the
          resources to do it. His final collection sparked a sensation in the
          fashion world to this day - ASYAGO, the clothing brand everyone in
          Mice City wants to be seen wearing, but which few can afford.
          <br />
          <br />
          Diego would transform the place into a never before seen disco-store
          hybrid, in his usual extravagant party style, running around the clock
          and Winnie would handle production and marketing with her business
          savvy. Parties, clothing, fashion and music - all in one place, at the
          same time and without rest.
          <br />
          <br />
          They had been outcasts, but now they were the talk of the town. They
          didn&apos;t shy away from the bad reputation the Neon Drive had. So
          they installed big neon signs above their place, with the name of the
          locale in bold flashing pink color - The Fashionistas.
          <br />
          <br />
          Soon mice started coming downtown on the Neon Drive for the
          experience, to get the latest in fashion, enjoy the music, lights and
          good vibes. And it just grew from there. They bought out every single
          building on the Drive and transformed it into a self-contained kingdom
          of non-stop fashion and partying.
          <br />
          <br />
          Just like that &quot;The Fashionistas&quot; was transformed from a
          unique store/club combo to an entire block of concert arenas, high end
          stores and discos with hundreds of members and employees.
          <br />
          <br />
          The Fashionistas’ faction of extravagance, style and taste was born,
          with the three creative minds still running it to this day.
        </span>
      </div>
    </div>
  );
};
