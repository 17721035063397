import React, { useEffect, useRef, useState } from "react";
import "./CrossStories.scss";
import { StoryBanner } from "./StoryBanner";
import { ThePlan } from "./ThePlan";
import { StoryTitleI } from "../../components/stories/firstStory/StoryTitleI";
import { SettingTheStageI } from "../../components/stories/firstStory/SettingUpTheStageI";
import { ThePlanI } from "../../components/stories/firstStory/ThePlanI";
import { TheVoteI } from "../../components/stories/firstStory/TheVoteI";
import { TheOutcomeI } from "../../components/stories/firstStory/TheOutcomeI";
import axios from "axios";
import { StoryTitleII } from "../../components/stories/secondStory/StoryTitleII";
import { SettingTheStageII } from "../../components/stories/secondStory/SettingTheStageII";
import { ThePlanII } from "../../components/stories/secondStory/ThePlanII";
import { TheFlyboysII } from "../../components/stories/secondStory/TheFlyboysII";
import { TheVoteII } from "../../components/stories/secondStory/TheVoteII";
import { TheOutcomeII } from "../../components/stories/secondStory/TheOutcomeII";
import { StoryTitleIII } from "../../components/stories/thirdStory/StoryTitleIII";
import { SettingTheStageIII } from "../../components/stories/thirdStory/SettingTheStageIII";
import { TheFlyboysIII } from "../../components/stories/thirdStory/TheFlyboysIII";
import { TheVoteIII } from "../../components/stories/thirdStory/TheVoteIII";
import { TheOutcomeIII } from "../../components/stories/thirdStory/TheOutcomeIII";
import { StoryTitleIV } from "../../components/stories/fourthStory/StoryTitleIV";
import { SettingTheStageIV } from "../../components/stories/fourthStory/SettingTheStageIV";
import { TheVoteIV } from "../../components/stories/fourthStory/TheVoteIV";
import { TheOutcomeIV } from "../../components/stories/fourthStory/TheOutcomeIV";
import { StoryTitle } from "./StoryTitle";
import { SettingTheStage } from "./SettingTheStage";
import { TheVote } from "./TheVote";
import { SettingTheStageV } from "../../components/stories/fifthStory/SettingTheStageV";
import { TheVoteV } from "../../components/stories/fifthStory/TheVoteV";
import { TheOutcomeV } from "../../components/stories/fifthStory/TheOutcomeV";
import { TheOutcome } from "./TheOutcome";
import { StoryTitleV } from "../../components/stories/fifthStory/StoryTitleV";
import { referer } from "../../config.devnet";
import { useGetAccountInfo, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { getNetworkConfig } from "@multiversx/sdk-dapp/utils";

export const END_DATE = 1662922800000;

export const getRemainingTime = () => {
  const dateNow = Date.now();
  const countdown = END_DATE - dateNow;
  const millisDay = 24 * 60 * 60 * 1000;
  const millisHour = 60 * 60 * 1000;
  const days = Math.floor(countdown / millisDay);
  const hours = Math.floor((countdown - days * millisDay) / millisHour);

  if (days > 0) {
    return days + ` ${days > 1 ? "days" : "day"} remaining`;
  }

  if (END_DATE < dateNow) {
    return "You have decided! Check out the outcome!";
  }

  return hours === 0
    ? "Voting will end soon!"
    : hours + ` ${hours > 1 ? "hours" : "hour"} remaining`;
};

export enum Stories {
  THE_GREAT_HEIST,
  BURNING_MOUSE_FESTIVAL,
  CLASH_OF_THE_UNDERWORLDS,
  FALLOUT,
  SERPENT_HEADS,
  HIGHWAY_TAKEDOWN,
}

export const CrossStories = () => {
  const { address } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const [hasMice, setHasMice] = useState(false);
  const [didVote, setDidVote] = useState(false);
  const [voteOption, setVoteOption] = useState("A");
  const [loadingVoteInfo, seLoadingVoteInfo] = useState(false);
  const { apiAddress } = getNetworkConfig();
  const [confetti, setConfetti] = useState<boolean>(false);
  const outcomeRef = useRef<HTMLDivElement>(null);
  const [currentStory, setCurrentStory] = useState(Stories.THE_GREAT_HEIST);

  useEffect(() => {
    window.scrollTo({
      top: 300,
    });
    setTimeout(() => setConfetti(true), 500);
    setTimeout(() => setConfetti(false), 2800);
  }, []);

  useEffect(() => {
    const fn = async () => {
      if (address) {
        seLoadingVoteInfo(true);
        const hasMiceResponse = await axios.get(
          `${apiAddress}/micecity/getMiceNfts/${address}`,
          {
            headers: {
              Referer: referer,
            },
          }
        );
        const didVoteResponse = await axios.get(
          `${apiAddress}/micecity/getVote/${address}`,
          {
            headers: {
              Referer: referer,
            },
          }
        );
        console.log(didVoteResponse);
        if (
          didVoteResponse.data.status === 404 ||
          !didVoteResponse.data.votes[5]
        ) {
          setDidVote(false);
        } else {
          setVoteOption(didVoteResponse.data.votes[5].voteOption);
          setDidVote(true);
        }
        setHasMice(hasMiceResponse.data.ownsMiceCity);
      } else {
        setHasMice(false);
        setDidVote(false);
      }
      seLoadingVoteInfo(false);
    };

    fn();
  }, [isLoggedIn, address]);

  const getBody = () => {
    switch (currentStory) {
      case Stories.BURNING_MOUSE_FESTIVAL:
        return (
          <>
            <StoryTitleII
              confetti={confetti}
              scrollToOutcome={scrollToOutcome}
            />
            <SettingTheStageII />
            <ThePlanII />
            <TheFlyboysII />
            <TheVoteII
              voteOption={voteOption}
              hasMice={hasMice}
              didVote={didVote}
              setVoted={setDidVote}
              loadingVoteInfo={loadingVoteInfo}
            />
            <div ref={outcomeRef}>
              <TheOutcomeII ref={outcomeRef} />
            </div>
          </>
        );
      case Stories.THE_GREAT_HEIST:
        return (
          <>
            <StoryTitleI confetti={false} scrollToOutcome={scrollToOutcome} />
            <SettingTheStageI />
            <ThePlanI />
            <TheVoteI
              voteOption={voteOption}
              hasMice={hasMice}
              didVote={didVote}
              setVoted={setDidVote}
              loadingVoteInfo={loadingVoteInfo}
            />
            <div ref={outcomeRef}>
              <TheOutcomeI ref={outcomeRef} />
            </div>
          </>
        );
      case Stories.CLASH_OF_THE_UNDERWORLDS:
        return (
          <>
            <StoryTitleIII
              confetti={confetti}
              scrollToOutcome={scrollToOutcome}
            />
            <SettingTheStageIII />
            <ThePlan />
            <TheFlyboysIII />
            <TheVoteIII
              voteOption={voteOption}
              hasMice={hasMice}
              didVote={didVote}
              setVoted={setDidVote}
              loadingVoteInfo={loadingVoteInfo}
            />
            <div ref={outcomeRef}>
              <TheOutcomeIII ref={outcomeRef} />
            </div>
          </>
        );
      case Stories.FALLOUT:
        return (
          <>
            <StoryTitleIV confetti={false} scrollToOutcome={scrollToOutcome} />
            <SettingTheStageIV />
            <TheVoteIV
              voteOption={voteOption}
              hasMice={hasMice}
              didVote={didVote}
              setVoted={setDidVote}
              loadingVoteInfo={loadingVoteInfo}
            />
            <div ref={outcomeRef}>
              <TheOutcomeIV ref={outcomeRef} />
            </div>
          </>
        );
      case Stories.SERPENT_HEADS:
        return (
          <>
            <StoryTitleV confetti={false} scrollToOutcome={scrollToOutcome} />
            <SettingTheStageV />
            <TheVoteV
              voteOption={voteOption}
              hasMice={hasMice}
              didVote={didVote}
              setVoted={setDidVote}
              loadingVoteInfo={loadingVoteInfo}
            />
            <div ref={outcomeRef}>
              <TheOutcomeV ref={outcomeRef} />
            </div>
          </>
        );
      case Stories.HIGHWAY_TAKEDOWN:
        return (
          <>
            <StoryTitle confetti={confetti} scrollToOutcome={scrollToOutcome} />
            <SettingTheStage />
            <TheVote
              voteOption={voteOption}
              hasMice={hasMice}
              didVote={didVote}
              setVoted={setDidVote}
              loadingVoteInfo={loadingVoteInfo}
            />
            <div ref={outcomeRef}>
              <TheOutcome ref={outcomeRef} />
            </div>
          </>
        );
    }
  };

  const scrollToOutcome = () => {
    if (outcomeRef.current) {
      window.scrollTo(0, outcomeRef.current.offsetTop + 400);
    }
  };

  return (
    <div className="stories-page">
      <div className="story-page-container">
        <StoryBanner setStory={setCurrentStory} />
        <div className="box-shadow mt-5">
          <div className="story-container">{getBody()}</div>
        </div>
      </div>
    </div>
  );
};
