import EGLD from "./assets/icons/elrondSymbols/elrond.svg";
import LKMEX from "./assets/icons/elrondSymbols/lkmex.svg";
import MEX from "./assets/icons/elrondSymbols/mex.svg";
import RIDE from "./assets/icons/elrondSymbols/ride.svg";
import USDC from "./assets/icons/elrondSymbols/usdc.svg";
import WATER from "./assets/icons/elrondSymbols/water.svg";
import WEGLD from "./assets/icons/elrondSymbols/wegld.svg";
import ZPAY from "./assets/icons/elrondSymbols/zpay.svg";
import RARE from "./assets/icons/elrondSymbols/RARE.png";
import { NetworkType } from "@multiversx/sdk-dapp/types";
import { getChainID } from "@multiversx/sdk-dapp/utils";

export const referer = "https://micecity.club/";
export const decimals = 2;
export const denomination = 18;
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 50000;
export const gasPerDataByte = 1500;

export const walletConnectBridgeAddresses: string[] = [
  "wss://wallet-connect-fra-sock.multiversx.com/wallet-connect-1?env=browser&host=trust.market&protocol=wc&version=1",
  "wss://wallet-connect-fra-sock.multiversx.com/wallet-connect-3?env=browser&host=trust.market&protocol=wc&version=1",
  "wss://wallet-connect-fra-sock.multiversx.com/wallet-connect-2?env=browser&host=trust.market&protocol=wc&version=1",
  "wss://wallet-connect-fra-sock.multiversx.com/wallet-connect-0?env=browser&host=trust.market&protocol=wc&version=1",
];

export const mlaAPi = "https://mvx-data-api.mlaorg.com";

export const gatewayAddresses: string[] = [
  "https://gateway.multiversx.tools",
  "https://gateway.multiversx.com",
];

export const gateway: string =
  gatewayAddresses[Math.floor(Math.random() * gatewayAddresses.length)];
export const walletConnectBridge: string =
  walletConnectBridgeAddresses[
    Math.floor(Math.random() * walletConnectBridgeAddresses.length)
  ];

export const walletConnectDeepLink =
  "https://maiar.page.link/?apn=com.multiversx.maiar.wallet&isi=1519405832&ibi=com.multiversx.maiar.wallet&link=https://maiar.com/";

export const PreSaleManager =
  "erd1qqqqqqqqqqqqqpgqg9fa0dmpn8fu3fnleeqn5zt8rl8mdqjkys5s2gtas7";

export const MarketPlaceSC =
  "erd1qqqqqqqqqqqqqpgq6wegs2xkypfpync8mn2sa5cmpqjlvrhwz5nqgepyg8";

export const xoProxy = "https://proxy-api.xoxno.com";

export const network: NetworkType = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.multiversx.com",
  apiAddress: "https://proxy-api.xoxno.com",
  explorerAddress: "http://explorer.multiversx.com",
  walletConnectBridgeAddresses: walletConnectBridgeAddresses,
  decimals: "4",
  gasPerDataByte: "1500",
  walletConnectDeepLink: walletConnectDeepLink,
  apiTimeout: "10000",
  walletConnectV2RelayAddresses: ["wss://relay.walletconnect.com"],
  chainId: getChainID(),
  digits: "18",
};

export const ElrondAPI = "https://api.multiversx.com";

export const GraphQL = "https://graph.maiar.exchange/graphql";
export const tokensMap: any = {
  EGLD: {
    text: "EGLD",
    svg: EGLD,
    priceMap: "WEGLD-bd4d79",
    urlWhite: "/img/tokens/elrond.svg",
    url: `${window.location.host}/elrond_dark.svg`,
  },
  "LKMEX-aab910": {
    text: "LKMEX",
    svg: LKMEX,
    priceMap: "MEX-455c57",
    urlWhite: "/img/tokens/lkmex.svg",
    url: `${window.location.host}/lkmex.svg`,
  },
  "ZPAY-247875": {
    text: "ZPAY",
    svg: ZPAY,
    urlWhite: "/img/tokens/zpay.svg",
    url: `${window.location.host}/zpay.svg`,
  },
  "RIDE-7d18e9": {
    text: "RIDE",
    svg: RIDE,
    urlWhite: "/img/tokens/ride.svg",
    url: `${window.location.host}/ride.svg`,
  },
  "MEX-455c57": {
    text: "MEX",
    svg: MEX,
    urlWhite: "/img/tokens/mex.svg",
    url: `${window.location.host}/mex.svg`,
  },
  "WATER-9ed400": {
    text: "WATER",
    svg: WATER,
    urlWhite: "/img/tokens/water.svg",
    url: `${window.location.host}/water.svg`,
  },
  "WEGLD-bd4d79": {
    text: "WEGLD",
    svg: WEGLD,
    urlWhite: "/img/tokens/wegld.svg",
    url: `${window.location.host}/wegld.svg`,
  },
  "USDC-c76f1f": {
    text: "USDC",
    svg: USDC,
    urlWhite: "/img/tokens/usdc.svg",
    url: `${window.location.host}/usdc.svg`,
  },
  "VITAL-bc0917": {
    text: "VITAL",

    urlWhite: "/img/tokens/vital.svg",
    url: `${window.location.host}/vital.svg`,
  },
  "RARE-99e8b0": {
    text: "RARE",
    svg: RARE,
    urlWhite: "/img/tokens/vital.svg",
    url: `${window.location.host}/vital.svg`,
  },
};
