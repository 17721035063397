import React from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";

export const ThePlanII = () => {
  const isMobile = useDisplaySize();
  // const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <div>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Burning Mouse Festival
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            That opportunity came with the upcoming Burning Mouse Festival
            organized by The Twins. Winston & Winnie were all about one-upping
            their creations. And by this stage, The Fashionistas’ parties had
            become known all across the country for their insane showmanship,
            non stop concerts lasting weeks, star studded lineups and attendees
            and avant-garde fashion shows from their own creations.
          </b>
          <br />
          This is how they came up with the Burning Mouse Festival – the music
          festival to end all music festivals. They will have their usual
          non-stop parties along with street races all around the festival
          grounds, fashion shows, 20 music stages with the greatest artists
          working today from every genre, light shows, and the craziness keeps
          going.
          <br />
          <br />
          And the ace up their sleeves would be their main attraction: The
          Flyboys. This rad group of adrenaline junkies were the most eccentric
          group of electro-urban music DJs. And as you all know, eccentricity is
          the Fashionistas’ bread and butter, so it’s no surprise they joined
          the Neon Drive family so fast.
          <br />
          <br />
          You may wonder what the name stands for. After each show, they would
          delight the audience with an insane show of jet-plane races. That’s
          right, JETS!
          <br />
          <br />
          The Twins spared no expense organizing this festival, but that was
          part of the problem. Everything was so extravagant and high
          maintenance, that even the full financial might of The Fashionistas’
          party factory was having trouble keeping up.
          <br />
          <br />
          And this is where Rekko saw his opportunity.
        </span>
      </div>
    </div>
  );
};
