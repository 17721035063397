import React, { useEffect, useState } from "react";
import freddy from "../../../assets/backgrounds/MiceSale-nobackground.png";
import "./salesList.scss";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { useDispatch } from "../../../context";
import { Button } from "../../../components/button";
import { mlaAPi } from "../../../config.devnet";
import { getNetworkConfig } from "@multiversx/sdk-dapp/utils";
import { MiceList } from "../../../components/common/MiceList";

export const SalesList = () => {
  const [ogs, setOGs] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const { apiAddress } = getNetworkConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      try {
        const ogsResponse = await axios.get(`${mlaAPi}/mice-city/superSales`);
        setOGs(ogsResponse.data);
        setPage(page + 1);
      } catch (e) {
        openErrorModal();
      }
      setLoading(false);
    };

    fn();
  }, []);

  const fetchMoreData = async () => {
    try {
      const ogsResponse = await axios.get(`${mlaAPi}/mice-city/superSales`, {
        params: {
          page: page,
        },
      });
      const _ogs = {
        ...ogs,
        resources: ogs.resources.concat(ogsResponse.data.resources),
      };
      setOGs(_ogs);
      setPage(page + 1);
    } catch (e) {
      openErrorModal();
    }
  };

  const openErrorModal = () => {
    dispatch({
      type: "setInfoModal",
      infoModal: {
        openInfoModal: true,
        title: "Error",
        message: "Something went wrong. Try to refresh the page!",
        icon: "⚠️",
        hasIcon: false,
      },
    });
  };

  return (
    <div className="og-page">
      <>
        <div
          className="box-shadow w-100 mx-0"
          style={{ marginBottom: "-0.5rem" }}
        >
          <div
            className={`banner-container mt-2 d-flex align-items-center justify-content-center ${
              isMobile ? "flex-column" : ""
            }`}
          >
            <div
              className="w-100"
              style={{
                maxHeight: "100%",
                maxWidth: isMobile ? "230px" : "430px",
                zIndex: 200,
              }}
            >
              <img className="image-full-size" src={freddy} alt="" />
            </div>
            {/*<div*/}
            {/*  style={{*/}
            {/*    color: "white",*/}
            {/*    padding: isMobile ? "2rem" : "4rem 4rem 1rem 0",*/}
            {/*  }}*/}
            {/*>*/}
            {/*<div className="d-flex">*/}
            {/*  <div style={{ fontSize: "1.2rem" }}>*/}
            {/*    The Mice City last minted*/}
            {/*  </div>*/}
            {/*  <div className="ms-2" style={{ width: "2rem" }}>*/}
            {/*    <img className="image-full-size" src={trophy} alt="" />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<br />*/}
            {/*<div style={{ fontSize: "0.9rem" }}>*/}
            {/*  <span>*/}
            {/*    NFT investments, as any investment, are not without risks.*/}
            {/*    <br />*/}
            {/*    <br />*/}
            {/*    But as an early investor (OG) into our project we want to show*/}
            {/*    our love & gratitude by rewarding you with future perks & NFT*/}
            {/*    drops from our future collections.*/}
            {/*    <br />*/}
            {/*    <br />*/}
            {/*    The first 800 NFTs have OG status, regardless of the method of*/}
            {/*    purchase (mint or secondary market). The holders of these*/}
            {/*    specific NFTs will receive 1x NFT from:*/}
            {/*    <br />*/}
            {/*    ✔️ Sewer Rats NFTs*/}
            {/*    <br />*/}
            {/*    ✔️ Kats NFTs*/}
            {/*    <br />*/}
            {/*    <br />*/}
            {/*    Check out the list below to see what NFTs are eligible & check*/}
            {/*    if you are one of the lucky first OGs!*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>
        </div>
        {/*OGs List*/}
        <div className="box-shadow w-100 mx-0" style={{ marginTop: "2.5rem" }}>
          <div className="list-container mt-2 mt-0 flex-column px-2">
            <div style={{ paddingTop: "1rem", paddingBottom: "7rem" }}>
              {ogs && ogs.resources && (
                <MiceList
                  loading={loading}
                  mice={ogs.resources}
                  fetchMoreData={fetchMoreData}
                  hasMore={false}
                />
              )}
            </div>
          </div>
        </div>
        <Button
          classname="up-button"
          label="↑"
          action={() => window.scrollTo(0, 300)}
          fontSize="16px"
        />
      </>
    </div>
  );
};
