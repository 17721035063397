import React, { useState } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import corpos1 from "../../../assets/images/factions/Clubbing and Fashion/ch3....webp";

export const TheFlyboysII = () => {
  const isMobile = useDisplaySize();
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <div>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        The Flyboys
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            But the savvy Rodent Rekko wouldn’t just walk up to The Twins and
            offer them more money. He knew they would never agree to working
            with him. He needed leverage. And this came from his failed attempt
            to gain influence inside The Agency.
          </b>
          <br />
          <br />
          His spies were still active, and connected enough to learn a valuable
          piece of information: The Flyboys – the Fashionista’s outrageously rad
          stunt pilot DJs, were in fact Agency informants.
          <br />
          <br />
          What the spies learned, and what Rodent loved to hear, was that the
          Flyboys were all deserters from the Oil Wars raging on the other side
          of the globe. Rumor has it that they refused an order to bomb a
          village just because an enemy general was passing through. So they ran
          and started new lives for themselves in the only place where outcasts
          are treated like family: Mice City’s Neon Drive.
          {showMore && (
            <span>
              <div className="faction-shadow">
                <div
                  className={`pt-3 m-0 ${isMobile ? "" : "pe-3"}`}
                  style={{
                    maxWidth: isMobile ? "100%" : "30%",
                    float: isMobile ? "none" : "left",
                  }}
                >
                  <img className="image-full-size" src={corpos1} alt="" />
                </div>
              </div>
              Soon after they joined The Twins’ faction, Axel Mousley’s agents
              noticed them and easily put 2 and 2 together. They offered them a
              simple choice: face court martial or work for The Agency and feed
              them information about any high profile Corpos, Syndicate of NRS
              members seen on the Neon Drive.
              <br />
              <br />
              And this gave Rekko the ammunition he needed for his plan to
              secure both fronts. On the first day of the festival he set up a
              face-to-face with Winnie & Winston. Once the festival was in full
              swing it would be easier for them to accept the financial backing
              of the Corpos.
              <br />
              <br />
              After they would refuse his first offer, which Rodent was sure
              they would, he would reveal what he knew about The Flyboys.
              <br />
              <br />
              The Clubbing & Fashion Scene is famous for being neutral, a place
              where every mouse was free to party in, no matter their
              allegiance. If word got out that they were in fact tracked by The
              Agency it would mean the end for their reputation.
              <br />
              <br />
              <b>
                At the same time he was going to offer The Flyboys diplomatic
                immunity through his many connections in the Presidential Cheese
                House’s inner circle. This meant they would be free from the
                threat of court martial, provided they kept “working” for
                Mousely’s agents, all while feeding any information about
                Mousley Task Force directly to Rekko.
                <br />
                <br />
                His master plan was ready, but first he had a festival to
                attend.
              </b>
              <br />
              <br />
            </span>
          )}
          <div
            className="vote-button m-auto my-4"
            style={{ width: "max-content" }}
            onClick={() => setShowMore(!showMore)}
          >
            <span>{showMore ? "See less" : "Show more"}</span>
          </div>
        </span>
      </div>
    </div>
  );
};
