import React, { FC } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import crime1 from "../../../assets/images/factions/Crime/CH1.webp";
import corpos1 from "../../../assets/images/factions/Agency/ch2 _.webp";
import agency1 from "../../../assets/images/factions/Agency/CH1.webp";
import smuggler from "../../../assets/images/factions/Corpo/ch1.webp";

type TheOutcomeType = {
  ref: any;
};

export const TheOutcomeV: FC<TheOutcomeType> = ({ ref }) => {
  const isMobile = useDisplaySize();

  return (
    <div ref={ref}>
      <span className="d-block pt-3 h3" style={{ fontWeight: 600 }}>
        The Outcome: Option C
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <span className="d-block pt-3 pb-2 h5" style={{ fontWeight: 600 }}>
            A meeting cut short
          </span>
          <b>
            As the Agency Task Force agents moved in on Maustrap Tower, Rekko
            and Ratface were just getting ready to bring their impromptu meeting
            to a close:
          </b>
          <div className="faction-shadow">
            <div
              className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
              style={{
                maxWidth: isMobile ? "80%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={crime1} alt="" />
            </div>
          </div>
          <br />
          <p className="dialog-font m-0">
            Ratface: I’m glad to see we can get a grip on this whole mess before
            it escalates further. I always knew I could rely on your better
            judgment, old friend.
            <br />
            <br />
            Rekko: As long as you understand I won’t tolerate any more surprises
            from your crew or any more “random” attacks on my territory or
            businesses. This isn’t a threat, Manny. It’s simply my terms of
            continuing our partnership.
            <br />
            <br />
            Ratface: Of course, we both did what we had to. A shame we lost a
            few good men in that scramble at the safehouse, but what’s done is
            done. My man Johnny should have secured my foothold into the senate,
            so we’ll have our own political support.
            <br />
            <br />
            Rekko: Good, then we have a deal. You operate independently in the
            political landscape, you keep your senate backing, and we keep the
            local governors and mayoral house.
          </p>
          <br />
          But this all depends if your new senator buddy can get my labs
          authorized. We’ve been struggling to keep that research going with all
          the constant government pressure.
          <br />
          <br />
          <p className="dialog-font m-0">
            Ratface: That won’t be an issue, I’ll have your approvals in the
            next 2 months. What are you trying to get out of that research? I
            never took you for a science wiz…
            <br />
            <br />
            <div className="faction-shadow">
              <div
                className={`m-0 ${isMobile ? "" : "d-inline pe-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "30%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <img className="image-full-size" src={corpos1} alt="" />
              </div>
            </div>
            Rekko: Let’s just say the next generation of soldiers should be
            spectacular.
            <br />
            But I can’t go ahead with testing on live mice until we get those
            approvals.
            <br />
            Although you know me, I can’t help but find a workaround and Mice
            City’s sewers are crawling with the scum of society, homeless mice.
            They’re the perfect volunteers for now.
            <br />
            The researchers are calling them <b>Sewer Rats</b> … hah. A fitting
            description for those degenerates.
          </p>
          <br />
          Ratface was horrified by what he heard, but it wasn’t the time to
          endanger their fresh peace talks.
          <br />
          <br />
          <p className="dialog-font m-0">
            Ratface: Soon you’ll have all you need to run fully backed testing
            and leave those sewer dwellers alone…
            <br />
            Rekko: Let’s just say the next generation of soldiers should be
            spectacular.
            <br />
            Let’s toast to yet another era of keeping Mice City on track, under
            both our visionary leadersh….
          </p>
          <br />
          <br />
          Out of nowhere, gunshots started filling hallways! Seconds after,
          Corpo and Syndicate enforcers barged in the office.
          <br />
          <br />
          <p className="dialog-font m-0">
            Corpo enforcer: We need to leave sir, The Agency’s swarming the
            building as we speak! They’re moving up clearing each level, and a
            chopper dropped a team on the roof. We need to move NOW!!!
          </p>
          <br />
          <span className="d-block pt-2 h5" style={{ fontWeight: 600 }}>
            Cornered serpents
          </span>
          <p className="dialog-font m-0">
            Rekko: How the fuck did this happen? Why weren’t we informed about
            the raid by our undercovers??
            <br />
            <br />
            Corpo enforcer: It’s just Task Force members, it must have been kept
            from others in The Agency.
            <br />
            <br />
            Rekko: Nevermind now… Manny, take your men through the server room.
            There’s an isolated staircase taking you underground. The agents
            won’t know about that route.
            <br />I need to secure the lab, there’s billions of cheddar worth of
            information in there. I can’t allow these animals to seize it!
          </p>
          <br />
          Ratface took his crew straight to the server room. The Task Force
          squads didn’t reach their floor yet so they managed to escape with
          zero pushback. Rekko instead headed to the top floor with his elite
          squad of Corpo enforcers to secure the lab.
          <div className="faction-shadow">
            <div
              className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
              style={{
                maxWidth: isMobile ? "80%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={agency1} alt="" />
            </div>
          </div>
          By this stage, Operation Serpent Heads was in full swing. Axel Mousley
          was coordinating the 2 Task Force squads dropped in on the roof. The
          rest of the agents were raiding the entire building, floor by floor,
          encountering little resistance.
          <br />
          The bulk of the Corpo forces were on the top floors.
          <br />
          <br />
          Rekko couldn’t get any more support from any of his security since
          they were focused on the agents climbing floor by floor, exactly as
          Mousley’s Operation anticipated.
          <br />
          <br />
          They reached the lab on the top floor just as the first elite squad
          breached the roof-access door. Everyone took cover around the hallways
          and offices.
          <br />
          Two battlelines quickly formed; it was like watching World War I
          brutal trench warfare. Flashbangs kept going off, followed by Task
          Force agents switching positions, moving in closer on the Corpo
          enforcers.
          <br />
          <br />
          Rekko was taking cover near the entrance, but he could see the
          situation was looking dire, but he noticed the agents ignored the lab
          entirely. They had no idea about his experiments.
          <br />
          <br />
          Suddenly it hit him: Axel Mousley actually attacked Maustrap to take
          him and the Corpo leadership out. And he walked straight into this
          trap when he could have easily left with Ratface…
          <br />
          Rekko started into the room and started laughing maniacally, thinking
          about what had happened: In his desperate attempt to cover his illegal
          research, he jumped straight into Axel’s grip.
          <br />
          <br />
          His laughter almost covered the constant gunfire. Moments later the
          second Task Force squad dropped repelled down from the roof, on the
          outside of the building, right next to the Corpo lines and opened
          fire.
          <br />
          <br />
          A few seconds later, quiet filled the room. All Corpo enforcers were
          dead. Rekko resigned himself to his fate near the door, awaiting
          capture.
          <br />
          <br />
          <span className="d-block pt-2 h5" style={{ fontWeight: 600 }}>
            Rapid Rescue
          </span>
          One hour after the attack had started, Ratface and his crew reached a
          safehouse. He turned on the TV to see the news.
          <br />
          To his shock, Rekko was captured and the Corpo enforcers inside the
          tower were all taken out by The Agency.
          <br />
          <br />
          This was a disaster, his new deal required Corpo control through Mice
          City’s local political landscape so his Crime Syndicate could worry
          about the bigger picture.
          <br />
          Not to mention the power struggle within the Corpo factions once Rekko
          was out. Johnny was right, this was going to be disastrous for Mice
          City…
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline pe-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={smuggler} alt="" />
            </div>
          </div>
          Then he remembered: Johnny and the deal; was it done or not?
          <br />
          He called Hotshot’s garage hoping Johnny was back.
          <br />
          It was Hotshot who picked up instead:
          <br />
          <br />
          <p className="dialog-font m-0">
            Ratface: Is Johnny back? (the noise in the background was deafening
            – roaring engines and screaming mice)
            <br />
            <br />
            Hotshot: Manny?? There was no news about you, we thought you were
            taken too.
            <br />
            <br />
            Ratface: I’m not such an easy prey for Mice City’s finest. But
            Rekko’s down for the count.
            <br />
            <br />
            Hotshot: We know, the kid has a plan to get him out. We`&lsquo;`ll
            hit their transport en route, while they`&lsquo;`re transporting
            Rekko to The Agency.
            <br />
            <br />
            Ratface: Who’s we? And how do you even know the route?
            <br />
            <br />
            Hotshot: Johnny got the info. He’ll tell you himself. Let’s focus on
            the job at hand. I have the Night Riders ready to go.
            <br />
            The cars are all set up, we’re rolling out in 10 minutes. The
            transport already left Maustrap Tower.
            <br />
            This is going to require precision and a shit-ton of luck, but I
            have faith in the kid’s plan. We’ll meet back at the Night Riders HQ
            if we pull through…
          </p>
        </span>
      </div>
      <div
        className="d-block h4 pt-4"
        style={{ fontWeight: 500, color: "#F6B0E1" }}
      >
        Stay tuned for the thrilling HIGHWAY TAKEDOWN on the next STORY
        CROSSROADS!
      </div>
    </div>
  );
};
