import React, { FC } from "react";
import "./StoryBanner.scss";
import desktopLogo from "../../assets/backgrounds/logoMiceCity.png";
import miceStoryCharacters from "../../assets/containerImages/storybanner/Mouses1.png";
import storyLogo from "../../assets/containerImages/storybanner/storyLogo.png";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import voteLogo from "../../assets/containerImages/vote/vote_logo.png";
import { useNavigate } from "react-router-dom";
import palmTrees from "../../assets/containerImages/vote/palms_grey.png";
import { isSafari } from "react-device-detect";
import { Stories } from "./CrossStories";

type StoryBannerType = {
  setStory: any;
};

export const StoryBanner: FC<StoryBannerType> = ({ setStory }) => {
  const isMobile = useDisplaySize();
  const navigate = useNavigate();

  return (
    <div className="box-shadow w-100 mx-0" style={{ marginBottom: "-0.5rem" }}>
      <div className="story-banner-container mt-2 d-flex justify-content-between">
        {!isMobile && (
          <div
            className="position-absolute"
            style={{ marginLeft: "-19rem", zIndex: "1" }}
          >
            <div style={{ width: "43%", transform: "translateX(2rem)" }}>
              <img
                className="d-block image-full-size"
                style={{ cursor: "pointer" }}
                src={desktopLogo}
                alt=""
                onClick={() => navigate("/")}
              />
            </div>
          </div>
        )}
        <div
          className="position-absolute"
          style={{
            zIndex: 1,
            width: isMobile ? "100%" : "55%",
            left: isMobile ? "1rem" : "33%",
            bottom: 0,
            transform: isSafari ? "translateY(-20em)" : "translateY(0)",
          }}
        >
          <div style={{ width: "90%" }}>
            <img
              className="d-block image-full-size"
              style={{ cursor: "pointer" }}
              src={palmTrees}
              alt=""
            />
          </div>
        </div>
        <div
          className={`d-flex ${!isMobile ? "ps-5" : "ps-4 text-center"}`}
          style={{ zIndex: 1 }}
        >
          <div className="pt-5" style={{ maxWidth: "30rem" }}>
            {isMobile && (
              <div>
                <img className="image-full-size" src={voteLogo} alt="" />
              </div>
            )}
            <div
              className={`${!isMobile ? "ps-5" : ""}`}
              style={{ color: "white" }}
            >
              <span className="d-block">
                In <b>Mice City</b> Stories we expand our story & reward our
                holders.
              </span>
              <br />
              <span className="d-block">
                Actively <b>participate</b> in the story & <b>earn</b> you LKMEX
                Rewards
              </span>
              <br />
              <span className="d-block">
                You can vote once a week. At the end of the week rewards will be
                distributed to those who voted.
              </span>
            </div>
            {!isMobile && (
              <div style={{ maxWidth: "25rem" }}>
                <img
                  className="image-full-size"
                  src={miceStoryCharacters}
                  alt=""
                />
              </div>
            )}
          </div>
          {!isMobile && (
            <>
              <div className="px-3" style={{ maxHeight: "450px" }}>
                <img className="image-full-size" src={storyLogo} alt="" />
              </div>
              <div
                className="my-5 ps-4"
                style={{
                  maxHeight: "450px",
                  color: "white",
                  borderLeft: "1px solid #707070",
                  maxWidth: "15rem",
                }}
              >
                <span className="d-block fw-bold">Stories</span>
                <span
                  className="d-block mb-3"
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => setStory(Stories.HIGHWAY_TAKEDOWN)}
                >
                  ⭐Highway Takedown
                </span>
                <span
                  className="d-block mb-3"
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => setStory(Stories.SERPENT_HEADS)}
                >
                  Operation Serpent Heads
                </span>
                <span
                  className="d-block mb-3"
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => setStory(Stories.FALLOUT)}
                >
                  Fallout
                </span>
                <span
                  className="d-block mb-3"
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => setStory(Stories.CLASH_OF_THE_UNDERWORLDS)}
                >
                  Clash of the Underworlds
                </span>
                <span
                  className="d-block mb-3"
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => setStory(Stories.BURNING_MOUSE_FESTIVAL)}
                >
                  The Burning Mouse Festival
                </span>
                <span
                  className="d-block"
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => setStory(Stories.THE_GREAT_HEIST)}
                >
                  The Great Art Heist (The Agency / Night Riders/Crime)
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
