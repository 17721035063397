import React, { forwardRef, ReactNode, useState } from "react";
import { Collapse, Dropdown } from "react-bootstrap";
import "./CustomDropdown.css";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

interface CustomDropdownProps {
  title: string;
  items: string[];
  onSelect: (item: string | null) => void;
  className?: string;
  style?: React.CSSProperties;
}

interface CustomToggleProps {
  children: ReactNode;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const CustomToggle = forwardRef<HTMLAnchorElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    <a
      href="#"
      ref={ref}
      className="m-0 display-inline"
      style={{ color: "rgba(0,0,0,.55)" }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )
);

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  title,
  items,
  onSelect,
  className,
  style,
}) => {
  const [rotate, setRotate] = useState<boolean>(false);
  const navigate = useNavigate();

  const routes = ["/ogs", "/mintedMadness", "/superSales"];
  const handleSelect = (event: any, index: number) => {
    console.log("select", index, routes[index]);
    setRotate(!rotate);
    navigate(routes[index]);
  };

  const toggleRotate = () => {
    setRotate(!rotate);
  };

  return (
    <Dropdown className={className} style={style}>
      <Dropdown.Toggle
        as={CustomToggle}
        variant="link"
        id="custom-dropdown"
        className="nav-link"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="d-flex align-items-center">
          {title}
          <div
            className="arrow-shadow-custom"
            data-mdb-toggle="collapse"
            onClick={toggleRotate}
          >
            <div className={`arrow-dropdown ${rotate ? "animate" : ""}`} />
          </div>
        </div>
      </Dropdown.Toggle>
      <Collapse in={rotate}>
        <div
          className={`mt-2 ${!isMobile && "position-absolute"}`}
          style={{
            padding: isMobile ? "" : "0.6rem 0",
            backgroundColor: isMobile ? "transparent" : "rgba(0, 0, 0, 0.75)",
            borderRadius: "12px",
            zIndex: 1000,
          }}
        >
          {items.map((item, index) => (
            <Dropdown.Item
              key={item}
              eventKey={item}
              onClick={(event) => handleSelect(event, index)}
              style={{
                backgroundColor: "transparent",
                fontWeight: 600,
                color: isMobile ? "rgba(0,0,0,.55)" : "rgba(255,255,255,0.55)",
              }}
            >
              {item}
            </Dropdown.Item>
          ))}
        </div>
      </Collapse>
    </Dropdown>
  );
};

export default CustomDropdown;
