import React, { useEffect, useRef } from "react";
import { useDisplaySize, useXLDisplaySize } from "../../../hooks/pageSizeHook";
import "./RoadmapBanner.scss";
import cityBg from "../../../assets/containerImages/roadmap/roadmap_bot_bg.webp";
import { MDBIcon } from "mdb-react-ui-kit";

export const RoadmapBanner = (props: any) => {
  const isMobile = useDisplaySize();
  const roadMapRef = useRef<HTMLDivElement>(null);
  const isXLScreen = useXLDisplaySize();

  useEffect(() => {
    if (roadMapRef.current && props.scroll) {
      roadMapRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.scroll]);

  return (
    <div
      ref={roadMapRef}
      className="box-shadow w-100 mx-0"
      style={{ marginTop: "2.5rem" }}
    >
      <div className="roadmap-container mt-2 mt-0 flex-column px-2">
        <div
          className={`title-box-shadow mx-0 ${
            isMobile ? "text-center" : "ps-5"
          }`}
        >
          <div className="title-container d-inline">
            <span>Roadmap</span>
          </div>
        </div>
        <div className="mt-5 d-flex flex-column justify-content-between align-items-center container">
          <div className="d-flex phase-wrapper">
            {!isXLScreen && (
              <div className="status-container checked">
                <div className="position-absolute roadmap-progress-green" />
                <MDBIcon
                  className="scale-animate"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    transform: "translate(60%, 70%)",
                  }}
                  icon="check"
                  size={`${isMobile ? "2x" : "lg"}`}
                />
              </div>
            )}
            <div className="phase-container checked text-start">
              <span
                className="h5 d-flex flex-column align-items-start underline phase1 mt-2"
                style={{ fontWeight: 700 }}
              >
                Phase 1: Story and Lore
              </span>
              <br />
              <span className="d-block text-start">
                We will take you on an epic adventure thru the urban sprawl of
                Mice City. Inspired by ‘80 & ‘90 pop culture mix with current
                ideas & social commentary, we really want you to be invested in
                every NFT that you own.
              </span>
              <br />
              <span className="d-block text-start">
                Mice City is home to a wide variety of characters belonging to
                various factions: The Crime Syndicate, The Night Riders,
                Clubbing & Fashion Scene, The Corpos, Agency
              </span>
              <br />
              <span className="d-block text-start">
                We want you to be part of shaping the story & destiny of these
                factions.
              </span>
              <br />
            </div>
          </div>
          <div
            className="d-flex phase-wrapper"
            style={{ width: "2000px", zIndex: 20 }}
          >
            {!isXLScreen && (
              <div className="status-container checked">
                <MDBIcon
                  className="scale-animate"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    transform: "translate(60%, 70%)",
                  }}
                  icon="check"
                  size={`${isMobile ? "2x" : "lg"}`}
                />
              </div>
            )}
            <div className="phase-container checked container">
              <span
                className="d-block h5 underline phase2 mt-2"
                style={{ fontWeight: 700 }}
              >
                Phase 2: Marketplace listing Live
              </span>
              <br />
              <span className="d-block text-start">
                We will release as a Premium Collection on{" "}
                <a
                  className=""
                  href="https://trust.market/listings"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#F6B0E1" }}
                >
                  <u>XOXNO</u>
                </a>
                .
              </span>
              <br />
            </div>
          </div>
          <div className="d-flex phase-wrapper" style={{ zIndex: 20 }}>
            {!isXLScreen && (
              <div className="status-container checked">
                <MDBIcon
                  className="scale-animate"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    transform: "translate(60%, 70%)",
                  }}
                  icon="check"
                  size={`${isMobile ? "2x" : "lg"}`}
                />
              </div>
            )}
            <div className="phase-container checked container">
              <span
                className="d-block h5 underline phase3"
                style={{ fontWeight: 700 }}
              >
                Phase 3: Budget Allocation and Distribution
              </span>
              <br />
              <span className="d-block text-start">
                The Mice City EGLD earnings will be split evenly between
                community rewards & ensuring project liquidity.
                <br />
                <br />
                50% of all EGLD earned from minting will be compunded into
                Staking farms with the sole purpose of generating community
                rewards which will be distributed on a monthly basis.
                {/*<br />*/}
                {/*<br />*/}
                {/*<a*/}
                {/*  className=""*/}
                {/*  href="https://www.notion.so/Mice-City-Budget-Allocation-b1a7af3eb60f4637bc1b83401644601b"*/}
                {/*  target="_blank"*/}
                {/*  rel="noreferrer"*/}
                {/*  style={{ color: "#F6B0E1" }}*/}
                {/*>*/}
                {/*  <u style={{ cursor: "pointer" }}>Read more</u>*/}
                {/*</a>*/}
              </span>
              <br />
            </div>
          </div>
          <div className="d-flex phase-wrapper" style={{ zIndex: 20 }}>
            {!isXLScreen && (
              <div className="status-container checked">
                <MDBIcon
                  className="scale-animate"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    transform: "translate(60%, 70%)",
                  }}
                  icon="check"
                  size={`${isMobile ? "2x" : "lg"}`}
                />
              </div>
            )}
            <div className="phase-container checked container">
              <span
                className="d-block h5 underline phase4"
                style={{ fontWeight: 700 }}
              >
                Phase 4: Mice City Stories and Gamifiction
              </span>
              <br />
              <span className="d-block text-start">
                As a Mice City NFT holder you decide your story. In the wild
                streets of the city, party, rob, extort, traffic or just try to
                make a living.
                <br />
                <br />
                Actively participate in the story & earn your LKMEX Rewards
                in&nbsp;
                <a
                  className=""
                  href="/stories"
                  rel="noreferrer"
                  style={{ color: "#F6B0E1" }}
                >
                  <u>Mice City Stories</u>
                </a>
              </span>
              <br />
            </div>
          </div>
          <div
            className="d-flex phase-wrapper"
            style={{ zIndex: 20, width: "2000px" }}
          >
            {!isXLScreen && (
              <>
                <div
                  className="position-absolute roadmap-progress-pink"
                  style={{ zIndex: -1 }}
                />
                <div className="status-container checked">
                  <MDBIcon
                    className="scale-animate"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      transform: "translate(60%, 70%)",
                    }}
                    icon="check"
                    size={`${isMobile ? "2x" : "lg"}`}
                  />
                </div>
              </>
            )}
            <div className="phase-container checked container">
              <span
                className="d-block h5 underline phase-default"
                style={{ fontWeight: 700 }}
              >
                Phase 5: NFT Staking
              </span>
              <br />
              <span className="d-block text-start">
                Stake your NFT & Earn. Cheddar factory will be opened for every
                Mice, faction leaders will earn special rewards.
              </span>
              <br />
            </div>
          </div>
          <div className="d-flex phase-wrapper" style={{ width: "2000px" }}>
            {!isXLScreen && (
              <div className="status-container pending">
                <MDBIcon
                  className="scale-animate position-absolute"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    transform: "translate(86%, 86%)",
                    zIndex: 20,
                  }}
                  icon="hourglass"
                  size={`${isMobile ? "2x" : "lg"}`}
                />
              </div>
            )}
            <div className="phase-container container">
              <span
                className="d-block h5 underline phase-default"
                style={{ fontWeight: 700 }}
              >
                Phase 6: The Expansion
              </span>
              <br />
              <span>
                <b>Sewer Rats:</b>
                <br />A toxic, mutated versions of the mice living above. New
                game mechanics will be added to this new species. Mint Pass will
                be also available TBD
              </span>
              <br />
              <br />
              <span>
                <b>Pets added:</b>
                <br />
                What is Life without a feline friend, to cuddle, play or to bust
                drug deals gone bad?
              </span>
              <br />
              <br />
            </div>
          </div>
          <div className="d-flex phase-wrapper" style={{ width: "2000px" }}>
            {!isXLScreen && (
              <div className="status-container pending">
                <MDBIcon
                  className="scale-animate position-absolute"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    transform: "translate(86%, 86%)",
                    zIndex: 20,
                  }}
                  icon="hourglass"
                  size={`${isMobile ? "2x" : "lg"}`}
                />
              </div>
            )}
            <div className="phase-container container">
              <span
                className="d-block h5 underline phase7"
                style={{ fontWeight: 700 }}
              >
                Future Project
              </span>
              <br />
              <span className="d-block text-start">
                Donation to charity & community ideas.
                <br />
                <br />
                We would like to transform Mice City into a living, breathing
                world, exploring ideas and features as gaming and opportunities
                to invest into a growing city.
              </span>
              <br />
            </div>
          </div>
        </div>
        <div className="position-absolute city-bg">
          <img className="image-full-size" src={cityBg} alt="" />
        </div>
      </div>
    </div>
  );
};
