import React from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import corpos1 from "../../../assets/images/factions/Clubbing and Fashion/ch3....webp";

export const TheFlyboysIII = () => {
  const isMobile = useDisplaySize();

  return (
    <div>
      <span className="d-block py-4 h3" style={{ fontWeight: 600 }}>
        Poking the unstoppable juggernaut
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <b>
            Rekko must have read that report 5 times when he finally thought to
            himself:
          </b>
          <p className="dialog-font m-0">
            &nbsp;&nbsp;&nbsp;&nbsp;- If this is the way you want to play it,
            old friend, then let’s see how your mob of common criminals will
            handle the entire might of my political and corporate reach when
            it’s done protecting the likes of you.
          </p>
          <br />
          But even with his temper flying high, he still tried to look at
          everything in his usual pragmatic manner. Could this be a ploy
          orchestrated by The Twins? He was under no illusion that they would
          just let everything go and accept his hostile takeover of their
          Fashionistas Empire. Not to mention their close ties with The Flyboys.
          <span>
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "30%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={corpos1} alt="" />
              </div>
            </div>
            Rekko thought this was the perfect way for Winston & Winnie to make
            a play without any direct confrontation between the Corpos & the
            Fashionistas. Feed him useful information, gain his trust and then
            leak something which would shake up his entire business. Again, he
            found himself admiring the Twins, but he had to focus and decide
            what course of action was needed.
            <br />
            <br />
            Later that day, Rekko gathered a meeting of his most trusted
            enforcers and advisors. He had decided that even if this was a plan
            set in motion by the Twins, it was too big to ignore.
            <br />
            <br />
            His surveillance team pulled every file and report on the Great Art
            Heist and confirmed the information was legit. At least two of the
            rats involved were Crime Syndicate members with active criminal
            records.
            <br />
            <br />
            Rekko was still bothered by the fact that he didn’t know who was the
            undercover agent with Ratface’s inner circle. That would give him
            the edge he needed when he finally confronted the Syndicate’s
            leader.
            <br />
            <br />
            <b>
              But one step at a time. He had to choose the smartest way to play
              this out. One wrong move or one step too far could mean an all-out
              war with the Syndicate, plunging the town into chaos and making
              mincemeat of Rekko’s stable network of control over the financial
              and political underbelly in Mice City.
            </b>
            <br />
            <br />
          </span>
        </span>
      </div>
    </div>
  );
};
