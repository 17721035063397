import React, { useEffect, useRef } from "react";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import charactersPreview from "../../../assets/containerImages/collectionSpecs/charactersOveview.webp";
import topLeft from "../../../assets/containerImages/collectionSpecs/topLeft.webp";
import bottomLeft from "../../../assets/containerImages/collectionSpecs/bottomLeft.webp";
import coinIcon from "../../../assets/containerImages/collectionSpecs/coin.webp";
import "./SpecsBanner.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const SpecsBanner = (props: any) => {
  const isMobile = useDisplaySize();
  const rarityRef = useRef<HTMLDivElement>(null);
  const collectionSpecs = [
    {
      text: "8000 total supply",
    },
    {
      text: "300+ unique hand drawn traits",
    },
    {
      text: "5 distinct factions with specific characters and traits",
    },
    {
      text: "Faction leaders",
    },
    {
      text: "Rare & Iconic outfits based on 80s pop & culture legends",
    },
  ];
  const universeGrowth = [
    {
      text: "Mice City stories",
      info:
        "We will set the stage and create the story & lore for each faction. " +
        "Starting from this base the story will continue with the help of the community",
    },
    {
      text: "Story crossroads - You decide your faction's fate",
      info: "NFT holder will be able to participate in community voting to decide the direction for their faction’s story arc",
    },
    {
      text: "City map with territories under faction control",
      info: "We will reveal the map of Mice City that will showcase each faction's influence and power position",
    },
    {
      text: "New species: Sewer Rats",
    },
    {
      text: "Katz: Your Mouse's feline friend",
    },
  ];
  const holderBenefits = [
    {
      text: "Recurring rewards for holding special NFTs",
      info: "Mice City collection will contain special NFTs that will generate passive income for the holders",
    },
    {
      text: "Faction operations rewarding the NFT holders",
      info:
        "There will be special events organized by each faction. " +
        "The outcome of these events will result in special rewards for the holders of that specific NFT faction",
    },
    {
      text: "Story crossroads rewards",
      info: "NFT holders that participate in the voting process will automatically enter a raffle for a prize in EGLD/LKMEX",
    },
    {
      text: "Future collection drops for OGs",
      info:
        "First 800 NFT owners will be considered Mice City OGs. " +
        "They will be eligible for a free drop from our future collections",
    },
  ];

  useEffect(() => {
    if (rarityRef.current && props.scroll) {
      rarityRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.scroll]);

  const renderTooltip = (args: any) => (
    <Tooltip id="button-tooltip" {...args}>
      {args.text}
    </Tooltip>
  );

  return (
    <div className="box-shadow w-100 mx-0" ref={rarityRef}>
      <div className="specs-banner-container mt-2 mt-0 flex-column px-2">
        <div className="title-box-shadow w-100 mx-0 ps-5">
          <div className="specs-banner-title-container d-inline">
            <span>Collection Specs, Vision & Perks</span>
          </div>
        </div>
        <div
          className={`${isMobile ? "flex-column d-flex " : "spec-wrapper"}`}
          style={{ marginTop: "10rem" }}
        >
          <div className="box-shadow-pink mx-0">
            <div className="specs-container mt-2 mt-0 flex-column px-5 pink">
              <div className="title-box-shadow w-100 mx-0">
                <div className="specs-title-container pink">
                  <span>🐭&#160;&#160;&#160;Collection Specs</span>
                </div>
              </div>
              <div style={{ paddingTop: "1rem" }}>
                {collectionSpecs.map((spec, index) => (
                  <div
                    className="d-flex"
                    style={{ marginBottom: "2.5rem" }}
                    key={index}
                  >
                    <div className="arrow mt-1" />
                    <span>{spec.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="box-shadow-blue mx-0">
            <div className="specs-container mt-2 mt-0 flex-column px-5 blue">
              <div className="title-box-shadow w-100 mx-0">
                <div className="specs-title-container blue">
                  <span>🚀&#160;&#160;&#160;&#160;MC Universe Growth</span>
                </div>
              </div>
              <div style={{ paddingTop: "1rem" }}>
                {universeGrowth.map((spec, index) => (
                  <div
                    className="d-flex"
                    style={{ marginBottom: "2.5rem" }}
                    key={index}
                  >
                    <div className="arrow mt-1" />
                    <span>
                      {spec.text}&#160;
                      {spec.info && (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 50, hide: 400 }}
                          overlay={renderTooltip({ text: spec.info })}
                        >
                          <div className="d-inline">
                            <i
                              className="fa fa-info-circle"
                              style={{
                                color: "#5FD3FE",
                                width: "10px",
                                height: "10px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </OverlayTrigger>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="box-shadow-yellow mx-0">
            <div className="specs-container mt-2 mt-0 flex-column px-5 yellow">
              <div className="title-box-shadow w-100 mx-0">
                <div className="specs-title-container yellow">
                  <div
                    className="d-inline-block pe-2"
                    style={{ maxWidth: "1.9rem" }}
                  >
                    <img className="image-full-size" src={coinIcon} alt="" />
                  </div>
                  <span>Holder Benefits</span>
                </div>
              </div>
              <div style={{ paddingTop: "1rem" }}>
                {holderBenefits.map((spec, index) => (
                  <div
                    className="d-flex"
                    style={{ marginBottom: "2.5rem" }}
                    key={index}
                  >
                    <div className="arrow mt-1" />
                    <span>
                      {spec.text}&#160;
                      {spec.info && (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 50, hide: 400 }}
                          overlay={renderTooltip({ text: spec.info })}
                        >
                          <div className="d-inline">
                            <i
                              className="fa fa-info-circle"
                              style={{
                                color: "#5FD3FE",
                                width: "10px",
                                height: "10px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </OverlayTrigger>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <RarityBanner />
      </div>
    </div>
  );
};

const RarityBanner = () => {
  const isMobile = useDisplaySize();

  return (
    <div className="box-shadow w-100">
      <div className="rarity-container mt-2 mt-0 flex-column mx-5">
        <div className="title-box-shadow w-100 mx-0 ps-5">
          <div className="title-container d-inline">
            <span>Rarity</span>
          </div>
        </div>
        <div className="top-left">
          <img className="image-full-size" src={topLeft} alt="" />
        </div>
        <div className="bottom-left">
          <img className="image-full-size" src={bottomLeft} alt="" />
        </div>
        <div
          className={`${
            isMobile
              ? "flex-column mt-5"
              : "mt-3 d-flex flex-row-reverse justify-content-between align-items-center"
          }`}
        >
          <div className="mt-3" style={{ width: isMobile ? "25rem" : "29rem" }}>
            <img className="image-full-size" src={charactersPreview} alt="" />
          </div>

          <span className="mt-4 d-block px-5">
            8.000 unique algorithmically generated mice from over 300+ possible
            hand drawn traits, including expression, headwear, clothing,
            accessories and more. <br />
            <br />
            Some are rarer than others but all are radically cool!
            <br /> <br />
            The collection is split into 5 distinct factions, each with their
            own unique style, clothing and traits.
          </span>
        </div>
      </div>
    </div>
  );
};
