import React, { useEffect, useRef, useState } from "react";
import desktopLogo from "../../assets/backgrounds/logoMiceCity.png";
import mobileLogo from "../../assets/backgrounds/logoMiceCity.png";
import syndicateBg from "../../assets/containerImages/factionPageBg/syndicate_banner_bg.webp";
import nightRidersBg from "../../assets/containerImages/factionPageBg/night_riders_bg@2x.webp";
import agencyBg from "../../assets/containerImages/factionPageBg/agency_bg@2x.png";
import clubbingBg from "../../assets/containerImages/factionPageBg/clubbing_bg@2x.png";
import corposBg from "../../assets/containerImages/factionPageBg/corpos_bg2x.webp";
import syndicateLogo from "../../assets/containerImages/factions/crime.webp";
import nightRidersLogo from "../../assets/containerImages/factions/night-riders.webp";
import corposLogo from "../../assets/containerImages/factions/corpos.webp";
import agencyLogo from "../../assets/containerImages/factions/agency.webp";
import clubbingLogo from "../../assets/containerImages/factions/clubbing.webp";
import { socialMediaIcons } from "../landingPage/NavigationBar";
import "./factionPage.scss";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import { SyndicateChapter1 } from "./factionStories/crimeSyndicate/SyndicateChapter1";
import { useSearchParams } from "react-router-dom";
import { Factions } from "../../model/Main";
import { SyndicateChapter2 } from "./factionStories/crimeSyndicate/SyndicateChapter2";
import { isSafari } from "react-device-detect";
import { SyndicateChapter3 } from "./factionStories/crimeSyndicate/SyndicateChapter3";
import { SyndicateChapter4 } from "./factionStories/crimeSyndicate/SyndicateChapter4";
import { CorposChapter1 } from "./factionStories/corpos/CorposChapter1";
import { CorposChapter2 } from "./factionStories/corpos/CorposChapter2";
import { CorposChapter3 } from "./factionStories/corpos/CorposChapter3";
import { CorposChapter4 } from "./factionStories/corpos/CorposChapter4";
import { NightRidersChapter1 } from "./factionStories/nightRiders/NightRidersChapter1";
import { NightRidersChapter2 } from "./factionStories/nightRiders/NightRidersChapter2";
import { NightRidersChapter3 } from "./factionStories/nightRiders/NightRidersChapter3";
import { NightRidersChapter4 } from "./factionStories/nightRiders/NightRidersChapter4";
import { AgencyChapter1 } from "./factionStories/theAgency/AgencyChapter1";
import { AgencyChapter2 } from "./factionStories/theAgency/AgencyChapter2";
import { AgencyChapter3 } from "./factionStories/theAgency/AgencyChapter3";
import { ClubbingChapter1 } from "./factionStories/clubbing/ClubbingChapter1";
import { ClubbingChapter3 } from "./factionStories/clubbing/ClubbingChapter3";
import { ClubbingChapter2 } from "./factionStories/clubbing/ClubbingChapter2";

export const FactionPage = () => {
  const isMobile = useDisplaySize();
  const [searchParams] = useSearchParams();
  const [chapter, setChapter] = useState(0);
  const factionName: any = searchParams.get("faction") || 0;
  const titleRef = useRef<HTMLDivElement>(null);
  const factions = [
    {
      id: Factions.CrimeSyndicate,
      name: "The Crime Syndicate",
      logo: syndicateLogo,
      banner: syndicateBg,
      storyComponents: [
        {
          name: "Welcome to the jungle",
          component: <SyndicateChapter1 />,
        },
        {
          name: "The Rise of Ratface",
          component: <SyndicateChapter2 />,
        },
        {
          name: "Misumoto’s Mistake",
          component: <SyndicateChapter3 />,
        },
        {
          name: "The Syndicate is born",
          component: <SyndicateChapter4 />,
        },
      ],
    },
    {
      id: Factions.NighRiders,
      name: "The Night Riders",
      logo: nightRidersLogo,
      banner: nightRidersBg,
      storyComponents: [
        {
          name: "New horizons",
          component: <NightRidersChapter1 />,
        },
        {
          name: "A racing icon falls, an underground legend is born",
          component: <NightRidersChapter2 />,
        },
        {
          name: "The Road to Freedom",
          component: <NightRidersChapter3 />,
        },
        {
          name: "Undercover in the Underground",
          component: <NightRidersChapter4 />,
        },
      ],
    },
    {
      id: Factions.Corpos,
      name: "Corpos",
      logo: corposLogo,
      banner: corposBg,
      storyComponents: [
        {
          name: "Corporate Control",
          component: <CorposChapter1 />,
        },
        {
          name: "Corporate rat-race",
          component: <CorposChapter2 />,
        },
        {
          name: "Deal with the Devil",
          component: <CorposChapter3 />,
        },
        {
          name: "Unholy Alliance",
          component: <CorposChapter4 />,
        },
      ],
    },
    {
      id: Factions.Agency,
      name: "The Agency",
      logo: agencyLogo,
      banner: agencyBg,
      storyComponents: [
        {
          name: "The ”Real” Law",
          component: <AgencyChapter1 />,
        },
        {
          name: "Witnessing Criminal History",
          component: <AgencyChapter2 />,
        },
        {
          name: "Adapting to Unforeseen Setbacks",
          component: <AgencyChapter3 />,
        },
      ],
    },
    {
      id: Factions.Clubbing,
      name: "Clubbing & Fashion",
      logo: clubbingLogo,
      banner: clubbingBg,
      storyComponents: [
        {
          name: "Mice City Nightlife",
          component: <ClubbingChapter1 />,
        },
        {
          name: "Party Agenda",
          component: <ClubbingChapter2 />,
        },
        {
          name: "Outcasts & Empires",
          component: <ClubbingChapter3 />,
        },
      ],
    },
  ];
  let factionIndex = factions.findIndex(
    (faction) => faction.id === factionName
  );
  factionIndex = factionIndex > -1 ? factionIndex : 0;

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const handleNext = () => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView();
    }
    if (chapter < factions[factionIndex].storyComponents.length) {
      setChapter(chapter + 1);
    }
  };

  const handlePrevious = () => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView();
    }
    if (chapter > 0) {
      setChapter(chapter - 1);
    }
  };
  return (
    <div className="faction-banner-container mt-2 d-flex flex-column">
      <div className="w-100">
        <img
          className="image-full-size"
          src={factions[factionIndex].banner}
          alt=""
        />
      </div>
      {isMobile ? (
        <div
          className={`position-absolute ${isSafari ? "" : "text-center w-100"}`}
          style={{
            marginTop: "-3.6rem",
            zIndex: "1",
            transform: isSafari ? "translateX(11em)" : "translateX(10%)",
          }}
        >
          <img style={{ width: "150px" }} src={mobileLogo} alt="" />
        </div>
      ) : (
        <div className="logo-container">
          <div style={{ maxWidth: "23rem" }}>
            <img className="d-block image-full-size" src={desktopLogo} alt="" />
            <div className="d-flex" style={{ marginLeft: "20%" }}>
              {socialMediaIcons.map((media, index) => (
                <div
                  onClick={() => window.open(media.url, "_blank")}
                  className="mx-2"
                  style={{
                    width: "1.8rem",
                    height: "1.5rem",
                    cursor: "pointer",
                  }}
                  key={index}
                >
                  <img className="w-100 h-100" src={media.icon} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="story-container mt-0 position-relative px-5">
        <div className="faction-logo w-100" ref={titleRef}>
          <div className="faction-title-shadow">
            <div
              className="pb-5"
              style={{ maxWidth: isMobile ? "17rem" : "20rem" }}
            >
              <img
                className="image-full-size"
                src={factions[factionIndex].logo}
                alt=""
              />
            </div>
          </div>
        </div>
        {factions[factionIndex].storyComponents[chapter].component}
        <div className="pt-5">
          {isMobile ? (
            <>
              {chapter !== 0 && (
                <span
                  className="navigation-link"
                  style={{ float: "left" }}
                  onClick={handlePrevious}
                >
                  Previous Chapter
                  <br />
                  {factions[factionIndex].storyComponents[chapter - 1].name}
                </span>
              )}

              {chapter !==
                factions[factionIndex].storyComponents.length - 1 && (
                <span
                  className="navigation-link text-end"
                  style={{ float: "right" }}
                  onClick={handleNext}
                >
                  Next Chapter
                  <br />
                  {factions[factionIndex].storyComponents[chapter + 1].name}
                </span>
              )}
            </>
          ) : (
            <>
              {chapter !== 0 && (
                <span
                  className="navigation-link"
                  style={{ float: "left" }}
                  onClick={handlePrevious}
                >
                  Previous Chapter&#160;-&#160;
                  {factions[factionIndex].storyComponents[chapter - 1].name}
                </span>
              )}
              {chapter !==
                factions[factionIndex].storyComponents.length - 1 && (
                <span
                  className="navigation-link"
                  style={{ float: "right" }}
                  onClick={handleNext}
                >
                  Next Chapter&#160;-&#160;
                  {factions[factionIndex].storyComponents[chapter + 1].name}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
