import React, { FC } from "react";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import { getRemainingTime } from "./CrossStories";
import titleIcon from "../../assets/storyCrossRoads/highwayTakedown/title_icon.png";

// import ConfettiExplosion from "@reonomy/react-confetti-explosion";

type StoryTitleType = {
  confetti: boolean;
  scrollToOutcome: any;
};

export const StoryTitle: FC<StoryTitleType> = ({
  confetti,
  scrollToOutcome,
}) => {
  const isMobile = useDisplaySize();

  return (
    <div
      className={`d-flex w-100 justify-content-between ${
        isMobile ? "flex-column align-items-center" : "align-items-end"
      } `}
    >
      <div className="pb-5" style={{ zIndex: 2 }}>
        <span
          style={{ fontWeight: 600, margin: "0" }}
          className="d-block h2 pb-3"
        >
          VI. Highway Takedown
        </span>
        <div
          className="d-block h4"
          style={{ fontWeight: 500, color: "#F6B0E1", cursor: "pointer" }}
          onClick={scrollToOutcome}
        >
          {getRemainingTime()}
          {confetti && (
            <div className="d-flex justify-content-center">
              {/*<ConfettiExplosion duration={3000} />*/}
            </div>
          )}
        </div>
      </div>
      <div style={{ width: isMobile ? "50%" : "25%" }}>
        <img className="image-full-size" src={titleIcon} alt="" />
      </div>
    </div>
  );
};
