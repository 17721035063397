import React from "react";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";
import corpos3 from "../../../../assets/images/factions/Corpo/ch4.webp";

export const CorposChapter4 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">Chapter.4 - Unholy Alliance</h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          Gone were the days of the Cartel, Mafia, Yakuza and Russians waging
          war on the streets of Mice City. Gone were the days where Maustrap was
          limiting its business to areas untouched by these gangs. Now they were
          fully in bed with them, like two parasites living off of eachother.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3 d-inline"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={corpos3} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          The Crime Syndicate was laundering its profits through one of the
          biggest corporations in the country and no one was batting an eye.
          They had the full might of Rodent Rekko’s Corpos (as they were now
          known) behind them. And with that came his army of corrupt officials
          willing to look the other way for any shady deal involving Rekko.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={corpos3} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          And in exchange for that invaluable service, The Syndicate would
          “invest” a percentage of that laundered money in Maustrap. All through
          Rekko’s network of offshore accounts of course.
          <br />
          <br />
          The dream of constant yearly growth was guaranteed. And Rekko’s
          position as The Corpos’ leader was cemented.
          <br />
          <br />
          With the cheddar flowing like waterfalls, the corporations owned by
          Maustrap started making more and more acquisitions, growing in scope
          and size, all being overlooked by corporate mice working for the
          company.
          <br />
          <br />
          The promise of obscene cheddar started to draw in politicians,
          governors, even some high ranking Agency operatives.
          <br />
          <br />
          The Corpos are now the ruling white collar elite of Mice City. They
          are above everyone else, literally, living in the high-rises of the
          most luxurious neighborhoods, with Rekko on top of all of it.
          <br />
          <br />
          But this house of cards is more brittle than it looks on the outside.
          Time will tell if and who can bring it crashing down.
          <br />
          <br />
        </span>
      </div>
      <span></span>
    </div>
  );
};
