import React, { FC } from "react";
import building from "../../../assets/storyCrossRoads/building.png";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";

type StoryTitleType = {
  confetti: boolean;
  scrollToOutcome: any;
};

export const StoryTitleI: FC<StoryTitleType> = ({
  confetti,
  scrollToOutcome,
}) => {
  const isMobile = useDisplaySize();

  return (
    <div
      className={`d-flex w-100 justify-content-between align-items-center ${
        isMobile ? "flex-column" : ""
      } `}
    >
      {/*<div*/}
      {/*  className="position-absolute"*/}
      {/*  style={{*/}
      {/*    zIndex: 1,*/}
      {/*    left: isMobile ? "1rem" : "10px",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div style={{ width: "50%" }}>*/}
      {/*    <img*/}
      {/*      className="d-block image-full-size"*/}
      {/*      style={{ cursor: "pointer" }}*/}
      {/*      src={lkmexLeft}*/}
      {/*      alt=""*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div*/}
      {/*  className="position-absolute"*/}
      {/*  style={{*/}
      {/*    marginTop: "-1rem",*/}
      {/*    zIndex: 1,*/}
      {/*    right: 0,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div style={{ width: "11rem" }}>*/}
      {/*    <img*/}
      {/*      className="d-block image-full-size"*/}
      {/*      style={{ cursor: "pointer" }}*/}
      {/*      src={lkmexRight}*/}
      {/*      alt=""*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div style={{ zIndex: 2 }}>
        <span
          style={{ fontWeight: 600, margin: "0" }}
          className="d-block h1 pb-3"
        >
          I. The Great Art Heist
        </span>
        <div
          className="d-block h4"
          style={{ fontWeight: 500, color: "#F6B0E1", cursor: "pointer" }}
          onClick={scrollToOutcome}
        >
          {confetti && (
            <div className="d-flex justify-content-center">
              <ConfettiExplosion duration={3000} />
            </div>
          )}
        </div>
      </div>
      <div className={isMobile ? "w-100" : "w-50"}>
        <img className="image-full-size" src={building} alt="" />
      </div>
    </div>
  );
};
