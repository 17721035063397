import {
  AbiRegistry,
  Address,
  SmartContract,
  SmartContractAbi,
} from "@elrondnetwork/erdjs/out";

export const getSmartContract = async (
  abiRegistry: AbiRegistry,
  address: Address,
  implementedInterface: string
) => {
  return new SmartContract({
    address: address,
    abi: new SmartContractAbi(abiRegistry, [implementedInterface]),
  });
};

export const getSmartContractResult = async (interactionWithSCMethod: any) => {
  // const smartContractQuery = interactionWithSCMethod.buildQuery();
  //TODO uncomment when finding a solution, double check if working properly

  // const queryResult = await getNetworkProxy().queryContract(smartContractQuery);
  const queryResult = {
    isSuccess: () => true,
  };
  if (queryResult.isSuccess()) {
    try {
      return interactionWithSCMethod.interpretQueryResponse(queryResult);
    } catch (error) {
      console.log(interactionWithSCMethod);
      console.log(error);
      return undefined;
    }
  }

  return undefined;
};
