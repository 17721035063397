import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LandingPage.scss";
import { NavigationBar, socialMediaIcons } from "./NavigationBar";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import footerLogo from "../../assets/backgrounds/logoMiceCity.png";
import { Menu } from "../../model/Main";
import footerLandscape from "../../assets/backgrounds/landscape-footer.webp";
import { ReactComponent as HeartIcon } from "../../assets/images/heart.svg";
import { Home } from "./Home";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { FactionPage } from "../faction/FactionPage";
import GlobalModal from "../../components/globalModal/GlobalModal";
import { useContext } from "../../context";
import { InfoModal } from "../../components/globalModal/InfoModal";
import { CrossStories } from "../crossStories/CrossStories";
import { OGList } from "../MiceLists/OGList/ogList";
import { SalesList } from "../MiceLists/salesList/salesList";
import { MintedList } from "../MiceLists/mintedMadness/mintedList";
import { Customize } from "../customize/Customize";

export const Root = () => {
  const isMobile = useDisplaySize();
  const [selectedMenu, setSelectedMenu] = useState<Menu>(-1);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { globalModal, infoModal } = useContext();

  useEffect(() => {
    window.addEventListener("scroll", () => setSelectedMenu(Menu.About), {
      passive: true,
    });
  }, []);

  return (
    <div className="m-auto flex-column landing-page">
      <div className="pt-3 flex-column m-auto page-container">
        <BrowserRouter>
          <NavigationBar setSelectedMenu={setSelectedMenu} />
          {globalModal.openGlobalModal && (
            <GlobalModal
              isOpened={isModalOpened}
              setIsOpened={setIsModalOpened}
            />
          )}
          {infoModal.openInfoModal && <InfoModal />}
          <Routes>
            <Route path="/" element={<Home selectedMenu={selectedMenu} />} />
            <Route path="/factions" element={<FactionPage />} />
            <Route path="/stories" element={<CrossStories />} />
            <Route path="/ogs" element={<OGList />} />
            <Route path="/superSales" element={<SalesList />} />
            <Route path="/mintedMadness" element={<MintedList />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/customize" element={<Customize />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div className="footer">
        <div className="d-flex pt-5">
          {socialMediaIcons.map((media, index) => (
            <div
              className="mx-2"
              style={{ width: "1.8rem", height: "1.8rem", cursor: "pointer" }}
              key={index}
              onClick={() => window.open(media.url, "_blank")}
            >
              <img className="w-100 h-100" src={media.icon} alt="" />
            </div>
          ))}
        </div>
        <div className="pt-4">
          <a className="text-dark" style={{ textDecoration: "none" }} href="/">
            Made with <HeartIcon className="m-auto" /> for MultiversX Comunity.
          </a>
        </div>
        <div style={{ maxWidth: "9rem" }}>
          <img className="image-full-size" src={footerLogo} alt="" />
        </div>
        <span className="d-block pt-1 text-center" style={{ fontSize: "20px" }}>
          From the creator of&#160;
          <a
            className="fw-bold"
            href="https://instagram.com/bucharest.wasted.youth?utm_medium=copy_link"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#F6B0E1", fontWeight: "600" }}
          >
            BWY
          </a>
          <br />
        </span>
        <span className="d-block text-center">
          Powered by{" "}
          <a
            className="fw-bold"
            href="https://trust.market/listings"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#F6B0E1", fontWeight: "600" }}
          >
            XOXNO Market
          </a>{" "}
          &&#160;
          <a
            className="fw-bold"
            href="https://elrond.com/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#F6B0E1", fontWeight: "600" }}
          >
            MultiversX blockchain
          </a>
        </span>
        <div className="pt-4">
          <span>© 2021 Mice City</span>
        </div>
        <div
          className="footer-landscape"
          style={{ marginTop: isMobile ? "-4rem" : "" }}
        >
          <img className="image-full-size" src={footerLandscape} alt="" />
        </div>
      </div>
    </div>
  );
};
