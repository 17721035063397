import React from "react";
import welcomeBanner from "../../../assets/images/mice_city_welcome@2x.webp";
import "./Welcome.scss";
import { useDisplaySize } from "../../../hooks/pageSizeHook";
import { isSafari } from "react-device-detect";

export const WelcomeBanner = () => {
  const isMobile = useDisplaySize();

  return (
    <>
      <div
        className="box-shadow w-100 mx-0"
        style={{ marginTop: isMobile ? "2.5rem" : "12.5rem" }}
      >
        <div className="welcome-container mt-2 mt-0 flex-column">
          <div className="welcome-sign d-flex justify-content-center">
            <img className="image-full-size " src={welcomeBanner} alt="" />
            <span className={`welcome-text ${isSafari ? "safari-margin" : ""}`}>
              Mice City is a huge urban sprawl separated into various
              <br /> regions, giving access to resources & Cheese.
              <br /> Factions war over these regions for control, resources or
              freedom. <br />
              <br /> Every NFT has a trait corresponding to a faction
              <br />
              <br /> You enter the game with your corresponding faction.
              <br />
              <br /> More to be revealed...
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
