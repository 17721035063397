import React, { FC } from "react";
import { useDisplaySize } from "../../hooks/pageSizeHook";
import crime1 from "../../assets/images/factions/Crime/CH1.webp";
import agency1 from "../../assets/images/factions/Agency/CH1.webp";

type TheOutcomeType = {
  ref: any;
};

export const TheOutcome: FC<TheOutcomeType> = ({ ref }) => {
  const isMobile = useDisplaySize();

  return (
    <div ref={ref}>
      <span className="d-block pt-3 h3" style={{ fontWeight: 600 }}>
        The Outcome: Option C
      </span>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span
          className="me-3"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <span className="d-block pt-3 pb-2 h5" style={{ fontWeight: 600 }}>
            A meeting cut short
          </span>
          <b>
            Hotshot moved his car in front of the big transport truck as soon as
            Johnny got into the prisoner hold.
            <br />
            AThe remainder of their team finally took out the other armored
            truck harassing their rescue efforts with a well-placed shot at the
            front wheels. The titanium cable shot from the crossbow wrapped
            itself around the front axle and the truck screeched to a halt after
            it veered off course and hit a barrier along the highway.
          </b>
          <br />
          <br />
          The agent guarding Rekko in the prisoner&lsquo;s hold soon surrendered
          after seeing all of his colleagues taken out one by one. He was now
          alone, surrounded by the fearsome Night Riders crew led by Hotshot
          Locatelli. The Crime Syndicate members riding with them also helped
          him make his decision quicker than usual.
          <div className="faction-shadow">
            <div
              className={`d-inline m-0 ${isMobile ? "" : "ps-3"}`}
              style={{
                maxWidth: isMobile ? "80%" : "30%",
                float: isMobile ? "none" : "right",
              }}
            >
              <img className="image-full-size" src={crime1} alt="" />
            </div>
          </div>
          <br />
          Johnny took his gun and handcuffed him to one of the chairs. He then
          instructed the driver to keep a steady pace and follow Hotshot’s car.
          They drove to an empty abandoned parking lot 5 miles off the highway.
          <br />
          <br />
          After he handcuffed the transport truck driver as well, Johnny got off
          the truck with Rekko.
          <br />
          <br />
          <p className="dialog-font m-0" style={{ lineHeight: "1rem" }}>
            Rekko: Now that’s what I call a driving kid! I need to fire my
            personal driver and hire you!
            <br />
            <br />
            Johnny: High praise coming from you, but I’m still indebted to the
            Syndicate.
            <br />
            <br />
            Rekko: Of course… Ratface’s a lucky mouse to have your skills on his
            side, and your connections.
            <br />
            <br />
            Johnny: Connections? (Johnny was taken aback by Rekko’s comment. It
            was almost like he knew about his Agency connection)
            <br />
            <br />
            Rekko: Nothing kid, let’s just get out of here so I can thank your
            boss in person.
          </p>
          <br />
          The crew all left to the Night Riders safehouse with Rekko, while
          Johnny shocked them all and insisted on taking Hotshot’s car and
          leading the incoming Agency pursuit force away from the team. Hotshot
          protested, but eventually gave into Johnny’s plan.
          <br />
          <br />
          Johnny blasted down the highway to intercept Axel Mousley’s forces.
          When he finally glimpsed them coming up the highway, he stopped the
          car in the middle of the street and waited calmly in Hotshot’s car.
          <br />
          <br />
          The agents swiftly surrounded him, dragged him out of the car and
          brought him to Axel.
          <br />
          <div className="faction-shadow">
            <div
              className={`m-0 ${isMobile ? "" : "d-inline pe-3"}`}
              style={{
                maxWidth: isMobile ? "100%" : "30%",
                float: isMobile ? "none" : "left",
              }}
            >
              <img className="image-full-size" src={agency1} alt="" />
            </div>
          </div>
          <br />
          <p className="dialog-font m-0" style={{ lineHeight: "1rem" }}>
            Axel: You fucking treasonous rat! You realize that was our best
            chance to bring down one of the most powerful criminal factions in
            Mice City, right? Not to mention having the most corrupt man on the
            planet in charge again… all thanks to you!
            <br />
            <br />
            Johnny: Sir, I understand how it may look, but trust me when I tell
            you, having Rekko in prison at this moment would bring nothing but
            chaos! We need to act when we can take control of every part of the
            Corpo conglomer….
            <br />
            <br />
            Axel: Shut the fuck up already! I’m done with your hunches and
            theories!
            <br />
            <br />
            Johnny: Would just listen for once! I’m deeper in the Syndicate than
            anyone in the Agency’s history. Ratface has knowledge on everything
            in Mice City. And the Corpos have something big cooking,
            <br />
            <br />
            Axel: What the hell are you talking about?
            <br />
            <br />
            Johnny: Rekko shifted a quarter of the Corpo profits into building
            and researching some sort of serum for a few paramilitary companies.
            And there’s rumors he’s in talks with a few government generals as
            well.
            <br />
            <br />
            Axel: In talks for what? What serum?
            <br />
            <br />
            Johnny: He’s trying to sell them a super-soldier serum. He’s been
            testing it on the thousands of homeless mice living in the sewers.
            So far, they’ve only failed and produced nothing but freaky
            mutations. They’re calling those poor bastards “sewer mice”.
            <br />
            <br />
            Axel: Holy shit… where’s his lab? Or the test subjects?
            <br />
            <br />
            Johnny: I don’t know. Not yet. But I heard they’re still kept in a
            secure area of the sewer system, like a bunch of cattle. But the
            scariest part is that they’re making progress. And going through
            more and more test subjects with horrifying results. That’s why you
            need to keep me on the inside. Ratface doesn’t like this too much.
            <br />
            <br />
            Axel: Ratface?
            <br />
            <br />
            Johnny: Not because of any moral compass… but he’s scared of an
            imbalance of power which would result if Rekko gets military
            backing.
            <br />
            <br />
            Axel: You’re not off the hook for this Johnny! Be sure that you’ll
            answer for your insubordination when this is all over. But for now,
            you’re right… Get back to Ratface, I don’t care how you keep in his
            good graces but bring us more on Rekko and his <b>
              Sewer Rats
            </b>{" "}
            project.
          </p>
          <br />
        </span>
      </div>
      <div
        className="d-block h4 pt-4"
        style={{ fontWeight: 500, color: "#F6B0E1" }}
      >
        TO BE CONTINUED in future Story Crossroads events
      </div>
    </div>
  );
};
