import React from "react";
import riders2 from "../../../../assets/images/factions/Night Riders/ch2 & ch4.webp";
import riders3 from "../../../../assets/images/factions/Night Riders/ch2_.webp";
import { useDisplaySize } from "../../../../hooks/pageSizeHook";

export const NightRidersChapter2 = () => {
  const isMobile = useDisplaySize();
  return (
    <div>
      <h2 className="title text-center pb-5">
        Chapter.2 - A racing icon falls, an underground legend is born
      </h2>
      <div
        className={`d-flex align-items-center ${isMobile ? "flex-column" : ""}`}
      >
        <span className="me-3" style={{ textAlign: "justify" }}>
          You see, Mickey was a legend overseas, ruthless on track, never giving
          an inch, and often preferring to crash his Prancing Puppies racecar
          trying to win rather than to come up second. And his obsession for
          pushing the boundaries didn’t stop on track, as he would always make
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3 d-inline"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <img className="image-full-size" src={riders2} alt="" />
              </div>
            </div>
          )}
          the first page of every newspaper in the country with his insane
          dangerous driving in the Italian hills around the Prancing Puppies
          test track.
          <br />
          <br />
          He cast a huge shadow over the racing community, fans loved him for
          his pure all-in racing approach in all aspects of his life, but the
          officials hated him for promoting thrill seeking rather than
          motor-racing. In the end it was his own daredevil attitude that would
          cost him his racing career.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pb-3 pt-3 m-0 ${isMobile ? "" : "ps-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "right",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={riders2} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          During his home race of the Italian Grand Prix, he tried to make a
          dangerous move in the rain on the last laps of the race to secure his
          championship early. His rival was defending masterfully, but he kept
          pressing. His team radio was alight with screaming engineers - “Mickey
          back off! It’s too dangerous, there’s still 4 races to go, don’t risk
          it! For God’s sake back off now!!!”
          <br />
          <br />
          But the response they got was pure Mickey: “I’m here to race, not
          strategize”
          <br />
          <br />
          SSo he went for an attack on the fastest corner of the track, but he
          lost control and slammed his car into the wall at full speed. The gas
          tank exploded into a huge fireball engulfing his car in flames.
          <br />
          <br />
          Fire marshals rushed to his aid, but the inferno was already melting
          his racing suit and burning his hands and face. By some miracle they
          managed to pull him out in time and he survived the scorching heat.
          But his racing career was done, his burns too severe to allow him to
          compete on the pro level. So he retired with a legend of immortality
          around his persona and a new nickname:{" "}
          <b>Mickey “Hotshot” Locatelli.</b>
          <br />
          He wanted a fresh start, so he went overseas to his family in Mice
          City.
          {!isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "pe-3 d-inline"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <img className="image-full-size" src={riders3} alt="" />
              </div>
            </div>
          )}
          And what perfect timing it was. You see, the Locatellis were the most
          powerful Italian Mafia Family in the city, and Mickey arrived just
          after the Crime Syndicate was formed.
          {isMobile && (
            <div className="faction-shadow">
              <div
                className={`pt-3 m-0 ${isMobile ? "" : "pe-3"}`}
                style={{
                  maxWidth: isMobile ? "100%" : "37%",
                  float: isMobile ? "none" : "left",
                }}
              >
                <br />
                <br />
                <img className="image-full-size" src={riders3} alt="" />
              </div>
            </div>
          )}
          <br />
          <br />
          He didn’t want to get involved in the Syndicate’s business, but he did
          hear that new affiliates of theirs were taking to the streets for more
          and more racing. That’s exactly what he needed, and he started joining
          them each night, with a new more mature mindset, always wearing his
          burned racing suit while driving, to remind him of the unnecessary
          risks he took.
          <br />
          <br />
          Of course having the Syndicate’s backing and resources didn’t hurt
          either, especially with the police crashing the races uninvited every
          night.
          <br />
          <br />
          Every racer started to hear of Hotshot Locatelli winning race after
          race in the underground scene.
          <br />
          <br />
          They started to ride with him, ask for advice, look to him for
          inspiration, many even wearing burned out clothing as well, to show
          support for their new icon.
          <br />
          <br />
          Naturally, this increased their reputation. They started to feel
          untouchable, riders in the night with nothing to slow them down. They
          found a second family in their fellow speed demons, and so the&#160;
          <b>Night Rider Society</b>&#160;was born.
          <br />
          <br />
        </span>
      </div>
    </div>
  );
};
